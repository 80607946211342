import React, { Component } from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import Container from 'react-bootstrap/Container';
import Card from 'react-bootstrap/Card';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import OffcanvasExample from "../sideBar/SideBar";
import HeaderBar from "../sideBar/HeaderBar";
import Principal from "../contenedores/Principal";
import CreaNuevaSolicitud from "../contenedores/CreaNuevaSolicitud";
import ConsultaSolicitudesMasNomina from "../contenedores/ConsultaSolicitudesMasNomina";
import "../sideBar/Dashboard.css"
import SideBar2 from "../SideBar2.0/SideBr2";
export default class Dashboard extends Component {

    constructor() {
        super();
        this.muestraComponente = this.muestraComponente.bind(this)
        this.state = {
            elementoPresionado: '',

        }

    }


    muestraComponente(elementoPresionado) {
        this.setState({ elementoPresionado: elementoPresionado })
    }


    UNSAFE_componentWillMount() {

    }

    render() {
        return (

            <OffcanvasExample muestraComponente={this.muestraComponente} />

        )
    }
}
