import React, { useRef, useEffect, useState } from 'react';
import RecordRTC from 'recordrtc';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import conexiones from '../urls/conexiones.json'
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';



const VideoIMSSios = (props) => {
  let videoRef = useRef(null);
  const recordingRef = useRef(false);
  let recordRTC;
  let colorError="#F83C3C"
  let colorSucces="#27F45E"
  const [nombreSolicitante, setNombreSolicitante] = React.useState("");
  const [montoSolicitado, setMontoSolicitado] = React.useState("");
  const [idDXN, setIdDxn] = React.useState("");
  const [textoDXN, setTextoDXN] = React.useState("");
  const [plazo, setPlazo] = React.useState(0);
  const [frecuencia, setFrecuencia] = React.useState("");
  const [iniciaGrabacion, setIniciaGrabacion] = React.useState(false);
  const [verVideo, setVerVideo] = React.useState(false);
  const [mostrarCamara, setMostrarCamara] = React.useState(true);
  const [iniciaGrabacion2, setIniciaGrabacion2] = React.useState("visible");
  const [videoURL, setVideoURL] = React.useState("");
  const [videoBlob, setVideoBlob] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [colorModal, setColorModal] = useState("");
  const [tituloModal, setTituloModal] = useState("");
  const [mensajeError, setMensajeError] = useState("");
    

  const [stream, setStream] = useState(null); // Guardar el stream de la cámara



  async function getSolicitud(idSolicitud) {
    try {
      const response = await fetch(conexiones.urlServicios + "wedo_solicitud/" + idSolicitud);
      if (response.ok) {
        const json = await response.json();
        console.log("respuesta de la solicitud ", json[0])
        setIdDxn(json[0].appid)

      } else {
        console.error('Error:', response.status);
        // this.setState({ colorModal: this.state.colorErrorModal, tituloModal: 'Ocurrio un error!', mensajeError: 'Error:' + response.status + "en getSolicitud  respuesta 500", showModal: true })
      }
    } catch (error) {
      //this.setState({ colorModal: this.state.colorErrorModal, tituloModal: 'Ocurrio un error!', mensajeError: error.toString() + " en getSolicitud servicio interrumpido", showModal: true })
    }
  }

  async function getTexto(idSolicitud) {
    try {
      const response = await fetch(conexiones.urlPytonParametros + "catalogo_video_acuerdo/" + idSolicitud);
      if (response.ok) {
        const json = await response.json();
        console.log("respuesta de la solicitud ", json)
        setTextoDXN(json.msg)

      } else {
        console.error('Error:', response.status);
        // this.setState({ colorModal: this.state.colorErrorModal, tituloModal: 'Ocurrio un error!', mensajeError: 'Error:' + response.status + "en getSolicitud  respuesta 500", showModal: true })
      }
    } catch (error) {
      //this.setState({ colorModal: this.state.colorErrorModal, tituloModal: 'Ocurrio un error!', mensajeError: error.toString() + " en getSolicitud servicio interrumpido", showModal: true })
    }
  }

  useEffect(() => {
    console.log("entrando al componente ", props)
    const startButton = document.getElementById('startButton');
    const stopButton = document.getElementById('stopButton');
    const playVideoButton = document.getElementById('playVideoButton');
    const meGustaVideo = document.getElementById('meGustaVideo');
    const reiniciarCamara = document.getElementById('reiniciarCamara');
    startButton.style.display = 'none';
    stopButton.style.display = 'none';
    playVideoButton.style.display = 'none';
    meGustaVideo.style.display = 'none';
    reiniciarCamara.style.display = 'none';

    if (props.idSolicitud != null) {
      getSolicitud(props.idSolicitud)
      getTexto(props.idSolicitud)
      if (props.existenDatosPersonales && props.existenDatosDomicilio &&
        props.existenDatosContacto &&
        props.existenDatosBancarios &&
        props.existenDatosOcupacion && props.existenDatosReferencias) {


        startCamera()
        startButton.style.display = 'block';

        let primerNombre =
          props.datosPersonalesServicio != null && props.datosPersonalesServicio != undefined ?
            props.datosPersonalesServicio instanceof Array ? props.datosPersonalesServicio[0].primer_nombre + " " : props.datosPersonalesServicio.primer_nombre + " " : ""
        let segundoNombre =
          props.datosPersonalesServicio != null && props.datosPersonalesServicio != undefined ? props.datosPersonalesServicio instanceof Array ? props.datosPersonalesServicio[0].segundo_nombre + " " : props.datosPersonalesServicio.segundo_nombre : ""
        let primerApp =
          props.datosPersonalesServicio != null && props.datosPersonalesServicio != undefined ? props.datosPersonalesServicio instanceof Array ? props.datosPersonalesServicio[0].primer_apellido + " " : props.datosPersonalesServicio.primer_apellido + " " : ""
        let segundoApp =
          props.datosPersonalesServicio != null && props.datosPersonalesServicio != undefined ? props.datosPersonalesServicio instanceof Array ? props.datosPersonalesServicio[0].segundo_apellido + " " : props.datosPersonalesServicio.segundo_apellido : " "
        setNombreSolicitante(primerNombre + segundoNombre + primerApp + segundoApp)
        setMontoSolicitado("$ " + props.nuevaSolicitudServicio.monto_solicitado)
        getPlazo()
        getFrecuencia()
      }
    }

    return () => {

    };


  }, [])

  async function getPlazo() {
    const settings = {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        "producto": props.nuevaSolicitudServicio.producto_id,
        "tipo_producto": props.nuevaSolicitudServicio.tipo_producto_id
      })
    };
    try {
      const fetchResponse = await fetch(conexiones.urlPytonParametros + "catalogo_producto_plazo/0", settings);
      const data = await fetchResponse.json();
      console.log("respesta de los plazos  ", data)
      if (data.length > 0) {
        for (var i = 0; i < data.length; i++) {
          if (data[i].id == props.nuevaSolicitudServicio.plazo_id) {
            setPlazo(data[i].plazo)
            break;
          }
        }
      }
    } catch (e) {
      return e;
    }
  }

  async function getFrecuencia() {
    const settings = {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      }
    };
    try {
      const fetchResponse = await fetch(conexiones.urlPytonParametros + "catalogo_periodo/" + props.nuevaSolicitudServicio.producto_id, settings);
      const data = await fetchResponse.json();
      if (data.filas.length > 0) {
        for (var i = 0; i < data.filas.length; i++) {
          let fila = data.filas[i].fila
          console.log("respesta de la frecuencia   ", fila[0])
          if (fila[0].value == props.nuevaSolicitudServicio.periodo_id) {
            setFrecuencia(fila[1].value)
            break
          }

        }
      }


    } catch (e) {
      return e;
    }
  }


  const startCamera = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ video: true, audio: true, echoCancellation: true });
      setStream(stream);

      videoRef.current.srcObject = stream;
      recordRTC = new RecordRTC(stream, {
        type: 'video',
        mimeType: 'video/mp4', // Puedes ajustar este mimeType según necesidad y compatibilidad
        recorderType: RecordRTC.MediaStreamRecorder,
      });
      //recordRTC.startRecording();
    } catch (error) {
      alert("Error accessing media devices.", error);
    }
  };



  const enviarVideo = async () => {
    const formData = new FormData();
    const videoFile = new File([videoBlob], 'video.mp4', { type: 'video/mp4' });
    formData.append('solicitud', props.idSolicitud);
    formData.append('cargado', 1);
    formData.append('documento', 138);
    formData.append('regla', 0);
    formData.append('total_img', 1);
    formData.append('imagen1', videoFile);
    formData.append('actualizar', 0);
    try {
      const response = await fetch('https://wedoplus.mx:8099/wedo/wedo_solicitudconcatpdf/' + props.idSolicitud, {
        method: 'POST',
        body: formData,
      });

      if (response.ok) {
      //  console.log('Video enviado con éxito');
        setColorModal(colorSucces)
        setTituloModal("Exito al enviar")
        setMensajeError("Video enviado con éxito")
        handleShow()
      } else {
        setColorModal(colorError)
        setTituloModal("Error al enviar")
        setMensajeError("Video no ha sido enviado")
        handleShow()
        console.error('Error al enviar el video');
      }
    } catch (error) {
      setColorModal(colorError)
      setTituloModal("Error al enviar")
      setMensajeError("Video no ha sido enviado ")
      handleShow()
      console.error('Error en la solicitud:', error);
    }

  }




  const startRecording = async () => {
    try {
      // setVerVideo(true)
      const stream = await navigator.mediaDevices.getUserMedia({ video: true, audio: true, echoCancellation: true });
      videoRef.current.srcObject = stream;
      recordRTC = new RecordRTC(stream, {
        type: 'video',
        mimeType: 'video/mp4', // Puedes ajustar este mimeType según necesidad y compatibilidad
        recorderType: RecordRTC.MediaStreamRecorder,
      });
      recordRTC.startRecording();
      recordingRef.current = true;
      updateButtons(false); // Actualiza la visibilidad de los botones
    } catch (error) {
      alert("Error accessing media devices.", error);
      console.log("error  ", error)
    }
  };

  const stopRecording = () => {
    recordRTC.stopRecording(() => {
      const blob = recordRTC.getBlob();
      setVideoBlob(blob); // Guardar el blob para enviarlo más tarde
      downloadVideo(blob);
      const tracks = videoRef.current.srcObject.getTracks();
      tracks.forEach(track => track.stop());
      videoRef.current.srcObject = null;
      recordingRef.current = false;

      updateButtons(true); // Actualiza la visibilidad de los botones
      //startCamera()
    });
  };

  const downloadVideo = (blob) => {
    const url = URL.createObjectURL(blob);
    setVideoURL(url)


  };


  const updateButtons = (isVerVideo) => {
    const startButton = document.getElementById('startButton');
    const stopButton = document.getElementById('stopButton'); //
    const playVideoButton = document.getElementById('playVideoButton');

    if (recordingRef.current) {
      startButton.style.display = 'none';
      stopButton.style.display = 'block';
    } else {
      startButton.style.display = 'block';
      stopButton.style.display = 'none';
    }


    if (isVerVideo) {
      startButton.style.display = 'none';
      playVideoButton.style.display = 'block'

    } else {
      playVideoButton.style.display = 'none'

    }
  };


  const handleClose = () => setShowModal(false);
  const handleShow = () => setShowModal(true);


  


  return (
    <>
      <Modal show={showModal} onHide={handleClose} centered size="lg">
        <Modal.Header style={{ backgroundColor: colorModal, textAlign: 'center' }} closeButton>
          <Modal.Title style={{ textAlign: 'center' }}>{tituloModal}</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ textAlign: 'center' }}>{mensajeError}</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cerrar
          </Button>

        </Modal.Footer>
      </Modal>


      < Row>
        <Col xs={12}>
          <span style={{ font: "bold", color: "#FF4000" }}>Sol: {props.idSolicitud == null ? "0" : props.idSolicitud}  DXN:{idDXN} </span>

        </Col>
      </Row>
      <Row>
        <Col xs={0} md={2} lg={2}>&nbsp;</Col>
        <Col xs={12} md={8} lg={8}>
          <span style={{ fontSize: "15px", fontWeight: "bold", color: "red" }}>{"Una vez que estés listo presiona en comenzar para iniciar la grabación, debes leer la leyenda a la derecha recuerda que en todo momento debe estar enfocado tu rostro​"}</span>
        </Col>
        <Col xs={0} md={2} lg={2}>&nbsp;</Col>

      </Row >
      <br />

      <Row>
        <Col xs={12} md={6} lg={6}>

          <div>

            {
              mostrarCamara ?

                verVideo ?
                  <video style={{ width: "100%" }} src={videoURL} autoPlay playsInline></video>
                  :
                  <video style={{ width: "100%" }} ref={videoRef} autoPlay playsInline></video>

                : ''

            }




            {

            }


            {

              <Button id="startButton" style={{ backgroundColor: "#FF6B00", borderColor: "#FF6B00", color: "white", width: '100%', height: "60px", borderRadius: '40px', cursor: "pointer" }}
                onClick={startRecording}>Iniciar grabación</Button>
            }

            <br />
            {

              <Button onClick={stopRecording} id="stopButton"
                style={{ backgroundColor: "red", borderColor: "red", color: "white", width: '100%', height: "60px", borderRadius: '40px', cursor: "pointer" }}>Detener grabación</Button>
            }

            {

              <Button id="playVideoButton" style={{ backgroundColor: "#FF6B00", borderColor: "#FF6B00", color: "white", width: '100%', height: "60px", borderRadius: '40px', cursor: "pointer" }}
                onClick={() => {
                  const meGustaVideo = document.getElementById('meGustaVideo');
                  const reiniciarCamara = document.getElementById('reiniciarCamara');
                  if (verVideo) {
                    setVerVideo(false)
                    setTimeout(() => {
                      setVerVideo(true)
                      meGustaVideo.style.display = 'block'
                      reiniciarCamara.style.display = 'block'
                    }, 100)
                  } else {
                    setVerVideo(true)
                    meGustaVideo.style.display = 'block'
                    reiniciarCamara.style.display = 'block'
                  }

                }}>Ver video</Button>
            }

            <br />

            <Row>
              <Col xs={6} md={6} lg={6}>
                {
                  <Button id="meGustaVideo" style={{ backgroundColor: "green", borderColor: "green", color: "white", width: '100%', height: "60px", borderRadius: '40px', cursor: "pointer" }}
                    onClick={enviarVideo}>Este video me gusta</Button>

                }
              </Col>
              <Col xs={6} md={6} lg={6}>
                {
                  <Button id="reiniciarCamara" style={{ backgroundColor: "#FF6B00", borderColor: "#FF6B00", color: "white", width: '100%', height: "60px", borderRadius: '40px', cursor: "pointer" }}
                    onClick={() => {
                      setVerVideo(false)
                      const meGustaVideo = document.getElementById('meGustaVideo');
                      const reiniciarCamara = document.getElementById('reiniciarCamara');
                      setTimeout(() => {
                        startCamera()
                        updateButtons(false)
                        meGustaVideo.style.display = 'none'
                        reiniciarCamara.style.display = 'none'
                      }, 100)
                    }}>Reiniciar camara !</Button>

                }
              </Col>
            </Row>





          </div>

        </Col>

        <Col xs={12} md={6} lg={6}>
          <span style={{ fontSize: "25px", fontWeight: "bold" }}>{textoDXN}</span>

        </Col>

      </Row >




    </>
  );
};

export default VideoIMSSios;