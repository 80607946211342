import React, { Component } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

class PreventBackNavigationClass extends Component {
  componentDidMount() {
    // Add an extra entry to the history stack
    window.history.pushState(null, null, window.location.href);
    window.addEventListener('popstate', this.handlePopState);
    window.addEventListener('beforeunload', this.handleBeforeUnload);
  }

  componentWillUnmount() {
    window.removeEventListener('popstate', this.handlePopState);
    window.removeEventListener('beforeunload', this.handleBeforeUnload);
  }

  handlePopState = (event) => {
    event.preventDefault();
    window.history.pushState(null, null, window.location.href);
    this.props.navigate(1); // Esto evita que el usuario vaya hacia atrás
  };

  handleBeforeUnload = (event) => {
    event.preventDefault();
    event.returnValue = ''; // Mostrar el diálogo de confirmación del navegador
  };

  render() {
    return null;
  }
}

const PreventBackNavigation = (props) => {
  const navigate = useNavigate();
  const location = useLocation();

  React.useEffect(() => {
    window.history.pushState(null, null, location.pathname);
  }, [location]);

  return <PreventBackNavigationClass {...props} navigate={navigate} />;
};

export default PreventBackNavigation;
