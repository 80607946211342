import React, { Component } from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import Container from 'react-bootstrap/Container';
import Card from 'react-bootstrap/Card';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Nav from 'react-bootstrap/Nav';
import Button from 'react-bootstrap/Button';
import Accordion from 'react-bootstrap/Accordion';
import Modal from 'react-bootstrap/Modal';
import conexiones from '../urls/conexiones.json'
import FastForwardIcon from '@mui/icons-material/FastForward';
import FastRewindIcon from '@mui/icons-material/FastRewind';
import Table from 'react-bootstrap/Table';
import botonAvanzar from '../imagenes/avanzar_boton.png'
import botonRegresar from '../imagenes/Boton_regresar.png'
import botonAgregar from '../imagenes/botonAgregar.png'

export default class ReferenciasSolicitanteForm extends Component {

    constructor() {
        super();
        this.onChangeValues = this.onChangeValues.bind(this)
        this.agregarNueva = this.agregarNueva.bind(this)
        this.eliminarReferencia = this.eliminarReferencia.bind(this)
        this.pintaItemsReferencias = this.pintaItemsReferencias.bind(this)
        this.creaReferencias = this.creaReferencias.bind(this)
        this.getReferencias = this.getReferencias.bind(this)
        this.armaReferencia1 = this.armaReferencia1.bind(this)
        this.armaReferencias = this.armaReferencias.bind(this)
        this.creaReferenciaNueva = this.creaReferenciaNueva.bind(this)
        this.actualizaReferencia = this.actualizaReferencia.bind(this)
        this.enviaReferencias = this.enviaReferencias.bind(this)
        this.eliminaReferenciaService = this.eliminaReferenciaService.bind(this)
        this.formatoLog = this.formatoLog.bind(this)
        this.quitaEspecialesYNumeros = this.quitaEspecialesYNumeros.bind(this)
        this.comparaTelefonos = this.comparaTelefonos.bind(this)
        this.revisaTelefonos = this.revisaTelefonos.bind(this)
        this.getDatosContacto = this.getDatosContacto.bind(this)
        this.quitaEspeciales = this.quitaEspeciales.bind(this)
        this.revisaApellidos = this.revisaApellidos.bind(this)

        this.state = {
            idDXN:"",
            /*modal */
            showModalBorrar: false,
            idDocumentoAEliminar: null,
            colorErrorModal: '#F83C3C',
            colorSuccesModal: '#27F45E',
            colorModal: '',
            tituloModal: '',
            mensajeError: '',
            showModal: false,
            /*modal */
            items: [],
            referencias: [],
            contador: 1,
            defaultActiveKey: "0",
            celularSolicitante: "",
            validaciones: {
                validaPrimerNombre: false,
                validaSegundoNombre: false,
                validaPrimerApellido: false,
                validaSegundoApellido: false,
                validaTelefono: false,
                validaCelular: false
            },
            selectParentescoOptions: [],
            referencia1: {
                primerNombre: null,
                segundoNombre: null,
                primerApellido: null,
                segundoApellido: null,
                telefono: null,
                celular: null,
                id: null
            },

        }
    }







    quitaEspecialesYNumeros(valor) {
        let finalString = valor.replace(/[^0-9a-zA-ZñÑ ]/g, '')
        //console.log("Final String sin especiales: "+ finalString)
        let digitsOnlyString = finalString.replace(/[^A-Za-zñÑ ]/g, '');
        //  console.log("STRING WITHOUT NON_NUMERIC CHARACTERS -> " + digitsOnlyString)

        const expRegular = /(\s{2,})/g;

        let remplazar = digitsOnlyString.replace(expRegular, ' ');
        //return digitsOnlyString
        return remplazar
    }


    quitaEspeciales(valor) {
        let finalString = valor.replace(/[^0-9]/g, '')
        return finalString
    }

    onChangeValues = e => {

        let validaciones = this.state.validaciones
        let referencia1 = this.state.referencia1


        if (e.target.name == "primerNombre") {
            let fechaIngreso = e.target.value;
            if (fechaIngreso == null || fechaIngreso == "") {
                validaciones.validaPrimerNombre = false
                this.setState({ validaciones: validaciones })
            } else {
                validaciones.validaPrimerNombre = true
                this.setState({ validaciones: validaciones })
            }
            referencia1["primerNombre"] = this.quitaEspecialesYNumeros(e.target.value)
        }

        if (e.target.name == "segundoNombre") {
            referencia1["segundoNombre"] = this.quitaEspecialesYNumeros(e.target.value)
        }


        if (e.target.name == "primerApellido") {
            let fechaIngreso = e.target.value;
            if (fechaIngreso == null || fechaIngreso == "") {
                validaciones.validaPrimerApellido = false
                this.setState({ validaciones: validaciones })
            } else {
                validaciones.validaPrimerApellido = true
                this.setState({ validaciones: validaciones })
            }
            referencia1["primerApellido"] = this.quitaEspecialesYNumeros(e.target.value)
        }


        if (e.target.name == "segundoApellido") {
            let fechaIngreso = e.target.value;
            if (fechaIngreso == null || fechaIngreso == "") {
                validaciones.validaSegundoApellido = false
                this.setState({ validaciones: validaciones })
            } else {
                validaciones.validaSegundoApellido = true
                this.setState({ validaciones: validaciones })
            }
            referencia1["segundoApellido"] = this.quitaEspecialesYNumeros(e.target.value)
        }


        if (e.target.name == "telefono") {
            let fechaIngreso = e.target.value;
            if (fechaIngreso == null || fechaIngreso == "") {
                validaciones.validaTelefono = false
                this.setState({ validaciones: validaciones })
            } else {
                let longitud = fechaIngreso + "";
                if (longitud.length < 10) {
                    validaciones.validaTelefono = false
                    this.setState({ validaciones: validaciones })
                } else {
                    validaciones.validaTelefono = true
                    this.setState({ validaciones: validaciones })
                }
            }
            let longitud = fechaIngreso + "";
            if (longitud.length <= 10) {
                referencia1["telefono"] = e.target.value
            }
        }

        if (e.target.name == "celular") {
            let fechaIngreso = e.target.value;
            if (fechaIngreso == null || fechaIngreso == "") {
                validaciones.validaCelular = false
                this.setState({ validaciones: validaciones })
            } else {
                let longitud = fechaIngreso + "";
                if (longitud.length < 10) {
                    validaciones.validaCelular = false
                    this.setState({ validaciones: validaciones })
                } else {
                    validaciones.validaCelular = true
                    this.setState({ validaciones: validaciones })
                }
            }
            let longitud = fechaIngreso + "";
            if (longitud.length <= 10) {
                referencia1["celular"] = e.target.value
            }
        }





        this.setState({ referencia1: referencia1 })

    }


    onChangeValuesRefenrencias = e => {
        console.log(e.target.name, e.target.value, this.state.referencias)
        let referencias = this.state.referencias
        let nombreCampo = e.target.name
        let idReferenciaString = nombreCampo.split("_")[1]
        nombreCampo = nombreCampo.split("_")[0]
        let idReferencia = parseInt(idReferenciaString)

        if (nombreCampo == "selectorParentesco") {
            referencias[idReferencia].parentesco_id = e.target.value
        }
        if (nombreCampo == "primerNombre") {
            referencias[idReferencia].primerNombre = this.quitaEspecialesYNumeros(e.target.value).toUpperCase()
        }
        if (nombreCampo == "segundoNombre") {
            referencias[idReferencia].segundoNombre = this.quitaEspecialesYNumeros(e.target.value).toUpperCase()
        }
        if (nombreCampo == "primerApellido") {
            referencias[idReferencia].primerApellido = this.quitaEspecialesYNumeros(e.target.value).toUpperCase()
        }
        if (nombreCampo == "segundoApellido") {
            referencias[idReferencia].segundoApellido = this.quitaEspecialesYNumeros(e.target.value).toUpperCase()
        }
        if (nombreCampo == "telefono") {
            let longitud = e.target.value + "";
            if (longitud.length <= 10) {
                referencias[idReferencia].telefono = this.quitaEspeciales(e.target.value)
            }
        }
        if (nombreCampo == "celular") {
            let longitud = e.target.value + "";
            if (longitud.length <= 10) {
                referencias[idReferencia].celular = this.quitaEspeciales(e.target.value)
            }

        }




        this.setState({ referencias: referencias })
        this.pintaItemsReferencias(referencias)
    }

    async getDatosContacto(idSolicitud) {
        try {
            const response = await fetch(conexiones.urlServicios + "wedo_contacto/" + idSolicitud);
            if (response.ok) {
                const json = await response.json();
                console.log("respuesta de la solicitud datos personales ", json[0])
                if (json.length > 0) {
                    this.setState({ celularSolicitante: json[0].celular })
                }

            } else {
                console.error('Error response.status :', response.status);
                this.setState({ colorModal: this.state.colorErrorModal, tituloModal: 'Ocurrio un error!', mensajeError: 'Error :' + response.status + " en getDatosDomicilio", showModal: true })

            }
        } catch (error) {
            console.error('Error: ', error);
            this.setState({ colorModal: this.state.colorErrorModal, tituloModal: 'Ocurrio un error!', mensajeError: error.toString() + " en getDatosDomicilio", showModal: true })

        }
    }

    async getReferencias(idSolicitud) {
        console.log("la solicitud ", idSolicitud)
        if (idSolicitud != null) {
            try {
                const response = await fetch(conexiones.urlServicios + "wedo_referencias/" + idSolicitud);
                if (response.ok) {
                    const json = await response.json();
                    console.log("referencias ", json)
                    if (json.length > 0) {
                        //  this.armaReferencia1(json[0])
                        this.props.setDatosReferenciasServicio(json)
                        this.props.setBanderaExistenDatosReferencias(true)
                        this.armaReferencias(json)
                    } else {
                        this.props.setDatosReferenciasServicio([])
                        this.props.setBanderaExistenDatosReferencias(false)
                    }


                } else {
                    console.error('Error:', response.status);
                    this.setState({ colorModal: this.state.colorErrorModal, tituloModal: 'Ocurrio un error!', mensajeError: response.status, showModal: true })

                }
            } catch (error) {
                console.error('Error:', error);
                this.setState({ colorModal: this.state.colorErrorModal, tituloModal: 'Ocurrio un error!', mensajeError: error.toString(), showModal: true })
            }
        }
    }

    async getCatalogo(url, selector, conta) {
        try {
            const response = await fetch(url);
            if (response.ok) {
                const json = await response.json();
                console.log(json)
                this.armaSelect(json.filas, selector, conta)
            } else {
                console.error('Error:', response.status);
            }
        } catch (error) {
            console.error('Error:', error);
        }
    }

    armaSelect(filas, selector, conta) {
        let select = []
        let options = []
        for (var i = 0; i < filas.length; i++) {
            let fila = filas[i].fila
            if (selector == "selectParentesco") {
                if (i == 0) {
                    options.push(<option selected value={fila[0].value}>{fila[1].value}</option>)
                } else {
                    options.push(<option value={fila[0].value}>{fila[1].value}</option>)
                }
            }
        }
        this.setState({ selectParentescoOptions: options })
        /* if (selector == "selectParentesco") {
           select.push(<Form.Select onChange={this.onChangeValues} name={selector+"_"+conta}   >{options}</Form.Select>)
         }
         this.setState({ [selector]: select })*/
    }

    async getSolicitud(idSolicitud) {
        try {
          const response = await fetch(conexiones.urlServicios + "wedo_solicitud/" + idSolicitud);
          if (response.ok) {
            const json = await response.json();
            console.log("respojsjahsahsuhuiah  ", json)
            if (json.length > 0) {
              this.setState({idBDSolicitud:json[0].id, idDXN:json[0].appid})
            }
          }
        } catch (error) {
          console.error('Error:', error);
        }
      }

    UNSAFE_componentWillMount() {
        this.getCatalogo(conexiones.urlPytonParametros + "catalogo_parentesco/0", "selectParentesco")
        console.log("props de referencias  ", this.props)
        setTimeout(() => {

            if(this.props.idSolicitud != null){
                this.getSolicitud(this.props.idSolicitud)
                if (this.props.existenDatosReferencias) {
                    this.getReferencias(this.props.idSolicitud)
                    this.getDatosContacto(this.props.idSolicitud)
                } else {
                    this.setState({ referencias: this.props.arrayReferencias })
    
                    setTimeout(() => {
                        this.pintaItemsReferencias(this.props.arrayReferencias)
                    }, 1000)
    
                }
            }else{
                this.setState({ colorModal: "#FF4000", tituloModal: 'Avisó!', mensajeError:"Debe generar el id de la solicitud antes de guardar una referencia "  , showModal: true })

            }
 


        }, 500)


    }

    agregarNueva() {
        console.log("agregando nueva")
        if (this.state.items.length < 4) {
            let items = this.state.items
            let referencias = this.state.referencias
            let conta = 0;

            let eventKeyValue = 0
            let contadorReferencia = 1
            contadorReferencia = contadorReferencia + this.state.items.length
            conta = conta + this.state.items.length
            //

            eventKeyValue = eventKeyValue + this.state.items.length
            let jsonReferencia = {
                parentesco_id: 0,
                primerNombre: null,
                segundoNombre: null,
                primerApellido: null,
                segundoApellido: null,
                telefono: null,
                celular: null,
                id: null
            }
            referencias.push(jsonReferencia)
            items.push(
                <Accordion.Item eventKey={eventKeyValue.toString()}>
                    <Accordion.Header>

                        <Col lg={10} md={10} xs={8}>
                            {"Referencia no." + (contadorReferencia)}
                        </Col>
                        <Col lg={1} md={1} xs={2}>
                            <Button variant="danger" onClick={() => {
                                this.props.banderaSolicitud ? console.log("mkk") : this.setState({
                                    idDocumentoAEliminar: contadorReferencia - 1, colorModal: this.state.colorErrorModal,
                                    tituloModal: 'Atención!', mensajeError: "Estas seguro que deseas eliminar este registro " + "Referencia no." + (contadorReferencia) + "?", showModalBorrar: true
                                })
                            }} style={{ borderRadius: '40px' }} type="button"> Eliminar </Button>
                        </Col>
                    </Accordion.Header>
                    <Accordion.Body>
                        {/* */}
                        <Row>
                            <Col lg={3} md={3} xs={12}>
                                <Form.Group className="mb-6" controlId="">
                                    <Form.Label>Parentesco : <span style={{ color: 'red' }}>*</span></Form.Label>
                                    <Form.Select disabled={this.props.banderaSolicitud} onChange={this.onChangeValuesRefenrencias} name={"selectorParentesco" + "_" + conta} value={this.state.referencias[this.state.referencias.length - 1].parentesco_id}  >{this.state.selectParentescoOptions}</Form.Select>

                                </Form.Group>
                            </Col>

                            <Col lg={3} md={3} xs={12}>
                                <Form.Group className="mb-6" controlId="">
                                    <Form.Label>Primer nombre sin abreviaturas: <span style={{ color: 'red' }}>*</span></Form.Label>
                                    <Form.Control disabled={this.props.banderaSolicitud} type="text" placeholder="" name={"primerNombre_" + conta} onChange={this.onChangeValuesRefenrencias} value={this.state.referencias[this.state.referencias.length - 1].primerNombre} />
                                </Form.Group>
                            </Col>

                            <Col lg={3} md={3} xs={12}>
                                <Form.Group className="mb-6" controlId="">
                                    <Form.Label>Segundo nombre sin abreviaturas:</Form.Label>
                                    <Form.Control disabled={this.props.banderaSolicitud} type="text" placeholder="" name={"segundoNombre_" + conta} onChange={this.onChangeValuesRefenrencias} value={this.state.referencias[this.state.referencias.length - 1].segundoNombre} />
                                </Form.Group>
                            </Col>

                            <Col lg={3} md={3} xs={12}>
                                <Form.Group className="mb-6" controlId="">
                                    <Form.Label>Primer apellido: <span style={{ color: 'red' }}>*</span></Form.Label>
                                    <Form.Control disabled={this.props.banderaSolicitud} type="text" placeholder="" name={"primerApellido_" + conta} onChange={this.onChangeValuesRefenrencias} value={this.state.referencias[this.state.referencias.length - 1].primerApellido} />
                                </Form.Group>
                            </Col>


                        </Row>
                        <br />
                        {/* */}
                        <Row>
                            <Col lg={3} md={3} xs={12}>
                                <Form.Group className="mb-6" controlId="">
                                    <Form.Label>Segundo apellido: <span style={{ color: 'red' }}>*</span></Form.Label>
                                    <Form.Control disabled={this.props.banderaSolicitud} type="text" placeholder="" name={"segundoApellido_" + conta} onChange={this.onChangeValuesRefenrencias} value={this.state.referencias[this.state.referencias.length - 1].segundoApellido} />
                                </Form.Group>
                            </Col>
                            <Col lg={3} md={3} xs={12}>
                                <Form.Group className="mb-6" controlId="">
                                    <Form.Label>Teléfono: <span style={{ color: 'red' }}>*</span></Form.Label>
                                    <Form.Control disabled={this.props.banderaSolicitud} type="text" placeholder="" name={"telefono_" + conta} onChange={this.onChangeValuesRefenrencias} value={this.state.referencias[this.state.referencias.length - 1].telefono} />
                                </Form.Group>
                            </Col>

                            <Col lg={3} md={3} xs={12}>
                                <Form.Group className="mb-6" controlId="">
                                    <Form.Label>Celular: <span style={{ color: 'red' }}>*</span></Form.Label>
                                    <Form.Control disabled={this.props.banderaSolicitud} type="text" placeholder="" name={"celular_" + conta} onChange={this.onChangeValuesRefenrencias} value={this.state.referencias[this.state.referencias.length - 1].celular} />
                                </Form.Group>
                            </Col>
                        </Row>
                    </Accordion.Body>
                </Accordion.Item>
            )
            this.setState({ items: items, contador: (this.state.contador + 1), referencias: referencias, defaultActiveKey: eventKeyValue.toString() })


        }

    }


    pintaItemsReferencias(referencias) {
        let items = []
        let eventKeyValue = 0
        let contadorReferencia = 1
        for (let i = 0; i < referencias.length; i++) {
            items.push(
                <Accordion.Item eventKey={eventKeyValue.toString()}>
                    <Accordion.Header>

                        <Col lg={10} md={10} xs={8}>
                            {"Referencia no." + (contadorReferencia)}
                        </Col>
                        <Col lg={1} md={1} xs={2}>
                            <Button variant="danger" onClick={() => {
                                this.props.banderaSolicitud ? console.log("mkk") :
                                    this.setState({
                                        idDocumentoAEliminar: i, colorModal: this.state.colorErrorModal,
                                        tituloModal: 'Atención!', mensajeError: "Estas seguro que deseas eliminar este registro" + "Referencia no." + (i + 1) + " ?", showModalBorrar: true
                                    })
                                /* this.props.banderaSolicitud? console.log("mkk"):this.eliminarReferencia(i)*/
}} style={{ borderRadius: '40px' }} type="button"> Eliminar </Button>
                        </Col>
                    </Accordion.Header>
                    <Accordion.Body>
                        {/* */}
                        <Row>

                            <Col lg={3} md={3} xs={12}>
                                <Form.Group className="mb-6" controlId="">
                                    <Form.Label>Parentesco : <span style={{ color: 'red' }}>*</span></Form.Label>
                                    <Form.Select disabled={this.props.banderaSolicitud} onChange={this.onChangeValuesRefenrencias} name={"selectorParentesco" + "_" + i} value={this.state.referencias[i].parentesco_id}  >{this.state.selectParentescoOptions}</Form.Select>


                                </Form.Group>
                            </Col>
                            <Col lg={3} md={3} xs={12}>
                                <Form.Group className="mb-6" controlId="">
                                    <Form.Label>Primer nombre sin abreviaturas: <span style={{ color: 'red' }}>*</span></Form.Label>
                                    <Form.Control disabled={this.props.banderaSolicitud} type="text" placeholder="" name={"primerNombre_" + i} onChange={this.onChangeValuesRefenrencias} value={this.state.referencias[i].primerNombre} />
                                </Form.Group>
                            </Col>

                            <Col lg={3} md={3} xs={12}>
                                <Form.Group className="mb-6" controlId="">
                                    <Form.Label>Segundo nombre sin abreviaturas:</Form.Label>
                                    <Form.Control disabled={this.props.banderaSolicitud} type="text" placeholder="" name={"segundoNombre_" + i} onChange={this.onChangeValuesRefenrencias} value={this.state.referencias[i].segundoNombre} />
                                </Form.Group>
                            </Col>

                            <Col lg={3} md={3} xs={12}>
                                <Form.Group className="mb-6" controlId="">
                                    <Form.Label>Primer apellido: <span style={{ color: 'red' }}>*</span></Form.Label>
                                    <Form.Control disabled={this.props.banderaSolicitud} type="text" placeholder="" name={"primerApellido_" + i} onChange={this.onChangeValuesRefenrencias} value={this.state.referencias[i].primerApellido} />
                                </Form.Group>
                            </Col>


                        </Row>
                        <br />
                        {/* */}
                        <Row>
                            <Col lg={3} md={3} xs={12}>
                                <Form.Group className="mb-6" controlId="">
                                    <Form.Label>Segundo apellido: <span style={{ color: 'red' }}>*</span></Form.Label>
                                    <Form.Control disabled={this.props.banderaSolicitud} type="text" placeholder="" name={"segundoApellido_" + i} onChange={this.onChangeValuesRefenrencias} value={this.state.referencias[i].segundoApellido} />
                                </Form.Group>
                            </Col>
                            <Col lg={3} md={3} xs={12}>
                                <Form.Group className="mb-6" controlId="">
                                    <Form.Label>Teléfono: <span style={{ color: 'red' }}>*</span></Form.Label>
                                    <Form.Control disabled={this.props.banderaSolicitud} type="text" placeholder="" name={"telefono_" + i} onChange={this.onChangeValuesRefenrencias} value={this.state.referencias[i].telefono} />
                                </Form.Group>
                            </Col>

                            <Col lg={3} md={3} xs={12}>
                                <Form.Group className="mb-6" controlId="">
                                    <Form.Label>Celular: <span style={{ color: 'red' }}>*</span></Form.Label>
                                    <Form.Control disabled={this.props.banderaSolicitud} type="text" placeholder="" name={"celular_" + i} onChange={this.onChangeValuesRefenrencias} value={this.state.referencias[i].celular} />
                                </Form.Group>
                            </Col>
                        </Row>
                    </Accordion.Body>
                </Accordion.Item>
            )
            eventKeyValue++
            contadorReferencia++
        }

        this.setState({ referencias: referencias, items: items })
    }
    eliminarReferencia(idReferencia) {
        console.log("idPresionado", idReferencia)
        let referencias = this.state.referencias
        let referenciaAEliminar = referencias[idReferencia]
        console.log("ref a leminiar", referenciaAEliminar)
        if (referenciaAEliminar != null || referenciaAEliminar != undefined) {
            if (referenciaAEliminar.id == null) {
                // solo e elina del dom
                referencias.splice(idReferencia, 1)
            } else {
                this.eliminaReferenciaService(referenciaAEliminar.id)
                referencias.splice(idReferencia, 1)
            }
        }

        console.log("las refrerancias ", referencias)
        this.pintaItemsReferencias(referencias)

    }

    armaReferencia1(referencia1) {
        let referencia = {
            primerNombre: referencia1.primer_nombre,
            segundoNombre: referencia1.segundo_nombre,
            primerApellido: referencia1.primer_apellido,
            segundoApellido: referencia1.segundo_apellido,
            telefono: referencia1.telefono,
            celular: referencia1.celular,
            id: referencia1.id
        }
        let validaciones = {
            validaPrimerNombre: true,
            validaSegundoNombre: true,
            validaPrimerApellido: true,
            validaSegundoApellido: true,
            validaTelefono: true,
            validaCelular: true
        }

        this.setState({ referencia1: referencia, validaciones: validaciones })
    }


    armaReferencias(referencias) {
        console.log("armando referencias  ", referencias)
        let referenciasedit = []
        for (let i = 0; i < referencias.length; i++) {
            let referencia = {
                parentesco_id: referencias[i].parentesco_id,
                primerNombre: referencias[i].primer_nombre,
                segundoNombre: referencias[i].segundo_nombre,
                primerApellido: referencias[i].primer_apellido,
                segundoApellido: referencias[i].segundo_apellido,
                telefono: referencias[i].telefono,
                celular: referencias[i].celular,
                id: referencias[i].id
            }
            referenciasedit.push(referencia)
        }
        this.setState({ referencias: referenciasedit })
        this.pintaItemsReferencias(referenciasedit)
    }


    creaReferencias() {
        let json =
        {
            "solicitud": this.props.idSolicitud,
            "primer_nombre": this.state.referencia1.primerNombre,
            "segundo_nombre": this.state.referencia1.segundoNombre,
            "primer_apellido": this.state.referencia1.primerApellido,
            "segundo_apellido": this.state.referencia1.segundoApellido,
            "telefono": this.state.referencia1.telefono,
            "celular": this.state.referencia1.celular
        }

        console.log("el json ", json)
        const requestReferidoOptions = {
            method: "POST",
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(json)
        }
        fetch(conexiones.urlServicios + "wedo_referencias/0", requestReferidoOptions)
            .then(response => response.json())
            .then(data => {

                if (data["id"] != undefined) {

                } else {
                    this.setState({ colorModal: this.state.colorErrorModal, tituloModal: 'Ocurrio un error al guardar la referencia 1!', mensajeError: JSON.stringify(data), showModal: true })
                }

            }).catch(error => {
                this.setState({ colorModal: this.state.colorErrorModal, tituloModal: 'Ocurrio un error al guardar la referencia 1!', mensajeError: error.toString(), showModal: true })
            })





    }

    creaReferenciaNueva = async (refe, numeroReferencia) => {
        let json =
        {
            "solicitud": this.props.idSolicitud,
            "primer_nombre": refe.primerNombre,
            "parentesco_id": refe.parentesco_id,
            "segundo_nombre": refe.segundoNombre,
            "primer_apellido": refe.primerApellido,
            "segundo_apellido": refe.segundoApellido,
            "telefono": refe.telefono,
            "celular": refe.celular
        }

        console.log("el json ", json)
        const requestReferidoOptions = {
            method: "POST",
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(json)
        }

        try {
            const fetchResponse = await fetch(conexiones.urlServicios + "wedo_referencias/0", requestReferidoOptions)
            const data = await fetchResponse.json();
            return data
        } catch (e) {
            return e
        }

        /*fetch(conexiones.urlServicios + "wedo_referencias/0", requestReferidoOptions)
           .then(response => response.json())
           .then(data => {

               if (data["id"] != undefined) {
                   return true
               } else {
                   
                   this.setState({ colorModal: this.state.colorErrorModal, tituloModal: 'Ocurrio un error al guardar la referencia ' + numeroReferencia + " !", mensajeError: this.formatoLog(data), showModal: true })
                   return false
               }

           }).catch(error => {
               
               this.setState({ colorModal: this.state.colorErrorModal, tituloModal: 'Ocurrio un error al guardar la referencia ' + numeroReferencia + " !", mensajeError: error.toString(), showModal: true })
               return false
           })*/

    }

    formatoLog(data) {
        let encabezados = Object.keys(data)
        let contenido = Object.values(data)
        let salida = ''

        let salidaEncabezados = []

        console.log("encabzados ", encabezados)
        for (var i = 0; i < encabezados.length; i++) {
            salidaEncabezados.push(
                <tr>
                    <td>{encabezados[i]}</td>
                    <td>{contenido[i]}</td>
                </tr>

            );
        }


        salida =
            <Table striped bordered hover>
                <thead>
                    <tr>
                        <th>Campo</th>
                        <th>Mensaje</th>
                    </tr>
                </thead>
                <tbody>
                    {salidaEncabezados}
                </tbody>
            </Table>



        return salida
    }

    eliminaReferenciaService(idReferencia) {
        const requestReferidoOptions = {
            method: "DELETE",
            headers: { 'Content-Type': 'application/json' },
        }
        fetch(conexiones.urlServiciosDetalle + "wedo_referencias/" + idReferencia, requestReferidoOptions)
            .then(response => response.json())
            .then(data => {

                this.setState({ colorModal: this.state.colorSuccesModal, tituloModal: 'Eliminada ', mensajeError: "Se ha eliminado la referenca", showModal: true })
                this.getReferencias(this.props.idSolicitud)

            }).catch(error => {
                this.setState({ colorModal: this.state.colorErrorModal, tituloModal: 'Ocurrio un error al eliminar la referencia ', mensajeError: error.toString(), showModal: true })
            })

    }

    actualizaReferencia = async (refe, numeroReferencia) => {
        let json =
        {
            "solicitud": this.props.idSolicitud,
            "parentesco_id": refe.parentesco_id,
            "primer_nombre": refe.primerNombre,
            "segundo_nombre": refe.segundoNombre,
            "primer_apellido": refe.primerApellido,
            "segundo_apellido": refe.segundoApellido,
            "telefono": refe.telefono,
            "celular": refe.celular
        }

        console.log("el json ", json)
        const requestReferidoOptions = {
            method: "PUT",
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(json)
        }

        try {
            const fetchResponse = await fetch(conexiones.urlServiciosDetalle + "wedo_referencias/" + refe.id, requestReferidoOptions)
            const data = await fetchResponse.json();
            return data
        } catch (e) {
            return e
        }
        /* fetch(conexiones.urlServiciosDetalle + "wedo_referencias/" + refe.id, requestReferidoOptions)
             .then(response => response.json())
             .then(data => {
 
                 if (data["id"] != undefined) {
 
                 } else {
                     this.setState({ colorModal: this.state.colorErrorModal, tituloModal: 'Ocurrio un error al guardar la referencia ' + numeroReferencia + " !", mensajeError: this.formatoLog(data), showModal: true })
                 }
 
             }).catch(error => {
                 this.setState({ colorModal: this.state.colorErrorModal, tituloModal: 'Ocurrio un error al guardar la referencia ' + numeroReferencia + " !", mensajeError: error.toString(), showModal: true })
             })*/

    }


    comparaTelefonos(referencias) {
        let banderaContinuar = true
        for (let i = 0; i < referencias.length; i++) {
            let referencia = referencias[i]
            if (this.revisaTelefonos(referencias, referencia, i) == false) {
                banderaContinuar = false
                break;
            }
        }
        return banderaContinuar
    }

    revisaTelefonos(referencias, referencia, posicion) {
        let banderaContinuar = true
        for (let i = 0; i < referencias.length; i++) {
            if (posicion != i) {
                console.log("coparando ", referencia.celular + " == " + referencias[i].celular, referencia.telefono + " == " + referencias[i].telefono)
                if (referencia.celular == referencias[i].celular || referencia.telefono == referencias[i].telefono /*|| referencia.celular == this.state.celularSolicitante || referencia.telefono == this.state.celularSolicitante*/) {
                    banderaContinuar = false
                    break;
                }
            }
        }
        return banderaContinuar
    }



    armaArrayTelefonos(referencias) {
        let arrayTelefonos = []
        arrayTelefonos.push(this.state.celularSolicitante)
        for (let i = 0; i < referencias.length; i++) {
            let referencia = referencias[i]
            arrayTelefonos.push(referencia.celular)
            arrayTelefonos.push(referencia.telefono)
        }

        console.log("salida del numero ", arrayTelefonos)
        return arrayTelefonos

    }


    armaArrayTelefonosJSON(referencias) {
        let arrayTelefonos = []
        // arrayTelefonos.push(this.state.celularSolicitante)
        for (let i = 0; i < referencias.length; i++) {
            let referencia = referencias[i]
            arrayTelefonos.push({ ref: i + 1, celular: referencia.celular, telefono: referencia.telefono })
            //   arrayTelefonos.push(referencia.telefono)
        }

        console.log("salida del numero json  ", arrayTelefonos)
        return arrayTelefonos

    }


    tieneStringsRepetidos = (arr) => {
        console.log("el array de los numeros ", arr)
        const cadenasVistas = {};

        for (let cadena of arr) {
            if (cadena != null) {
                if (cadena.length > 0) {
                    if (cadenasVistas[cadena]) {
                        return false; // Se encontró una cadena repetida, devuelve false
                    }
                    cadenasVistas[cadena] = true;
                }

            }
        }

        return true; // No se encontraron cadenas repetidas, devuelve true


    }


    revisaApellidos(referencias) {
let salida= true;
        let banderaContinuarApellidos = true
        let banderaContinuarApellidos2= true
        for (let i = 0; i < referencias.length; i++) {
            let referencia = referencias[i]
            if (
                !((referencia.primerApellido != null && referencia.primerApellido != "") 
                /*|| 
                (referencia.segundoApellido != null && referencia.segundoApellido != "")*/)) {
                console.log(" primer apellido ", referencia.primerApellido, " segundo apellido ", referencia.segundoApellido)
                banderaContinuarApellidos = false
                //break;
            }
            /*if (
                !(
                  (referencia.segundoApellido != null && referencia.segundoApellido != ""))) {
                console.log(" primer apellido ", referencia.primerApellido, " segundo apellido ", referencia.segundoApellido)
                banderaContinuarApellidos2 = false
                //break;
            }*/

            if(banderaContinuarApellidos ==false /*|| banderaContinuarApellidos2 == false*/){
                salida=false
                break;
            }


        }
        return salida
    }

    revisaTelefonos(referencias) {
        let banderaContinuarCelulares = true
        for (let i = 0; i < referencias.length; i++) {
            let referencia = referencias[i]
            if (!((referencia.telefono != null && referencia.telefono != "" && referencia.telefono.length != 10) || (referencia.celular != null && referencia.celular != "" && referencia.celular.length != 10))) {
                console.log(" primer apellido ", referencia.primerApellido, " segundo apellido ", referencia.segundoApellido)

                banderaContinuarCelulares = false
                break;
            }
        }
        return banderaContinuarCelulares
    }

    revisaLongitudTelefonos(arrayTelefonosJSON) {
        let banderaLongitudTelefonos = true;
        for (var i = 0; i < arrayTelefonosJSON.length; i++) {
            if (arrayTelefonosJSON[i].celular != null && arrayTelefonosJSON[i].celular.length > 0) {
                if (arrayTelefonosJSON[i].celular.length < 10) {
                    banderaLongitudTelefonos = false;
                    break;
                }
            }
            if (arrayTelefonosJSON[i].telefono != null && arrayTelefonosJSON[i].telefono.length > 0) {
                if (arrayTelefonosJSON[i].telefono.length < 10) {
                    banderaLongitudTelefonos = false;
                    break;
                }
            }
        }


        return banderaLongitudTelefonos
    }

    async enviaReferencias() {

        if(this.props.idSolicitud != null){
        let contador = 0;
        if (this.state.referencias.length > 0) {
            console.log("entrando a las refs validando ", this.state.referencias)
            //validar los numeros de las referencias 

            let banderaContinuarApellidos = this.revisaApellidos(this.state.referencias)
            let arrayTelefonos = this.armaArrayTelefonos(this.state.referencias)
            let arrayTelefonosJSON = this.armaArrayTelefonosJSON(this.state.referencias)

            let banderaNumerosRepetidos = this.tieneStringsRepetidos(arrayTelefonos)
            let banderaLongitudTelefonos = this.revisaLongitudTelefonos(arrayTelefonosJSON)
            //  console.log("banderaContinuarTelefonos ",this.tieneStringsRepetidos(this.armaArrayTelefonos(this.state.referencias)))
           console.log("banderaNumerosRepetidos" ,banderaNumerosRepetidos )
           console.log("banderaLongitudTelefonos" ,banderaLongitudTelefonos )
           console.log("banderaContinuarApellidos" ,banderaContinuarApellidos )
            if (banderaNumerosRepetidos == true) {
                if (banderaLongitudTelefonos == true) {
                    if (banderaContinuarApellidos) {
                        for (let i = 0; i < this.state.referencias.length; i++) {
                            let referencia = this.state.referencias[i]
                            if (referencia.id != null) {
                                let data = await this.actualizaReferencia(referencia, (i + 2) + "")
                                if (data["id"] == undefined) {
                                    this.setState({ colorModal: this.state.colorErrorModal, tituloModal: 'Ocurrio un error al actualizar la referencia  !', mensajeError: this.formatoLog(data), showModal: true })
                                    break;
                                } else {
                                    this.props.setBanderaExistenDatosReferencias(true)
                                    contador++
                                }
                            } else {
                                let data = await this.creaReferenciaNueva(referencia, (i + 2) + "")
                                if (data["id"] == undefined) {
                                    this.setState({ colorModal: this.state.colorErrorModal, tituloModal: 'Ocurrio un error al guardar la referencia  !', mensajeError: this.formatoLog(data), showModal: true })
                                    break;
                                } else {
                                    this.props.setBanderaExistenDatosReferencias(true)
                                    referencia.id = data["id"]
                                    contador++;
                                }
                            }
                        }
                        this.getReferencias(this.props.idSolicitud)
                        setTimeout(() => {
                            if (this.props.compraDeuda) {
                                this.props.clickAvanzar("compraDeuda")

                            } else {
                                if (contador == this.state.referencias.length) {
                                    this.props.clickAvanzar("documentos")
                                }
                            }


                        }, 500)


                    } else {
                        this.setState({ colorModal: this.state.colorErrorModal, tituloModal: '¡Atención!', mensajeError: "Debes ingresar al menos el primer apellido en cada referencia", showModal: true })
                    }
                } else {
                    this.setState({ colorModal: this.state.colorErrorModal, tituloModal: '¡Atención!', mensajeError: "Verifica que los teléfonos de las referencias tengan al menos 10 digitos .", showModal: true })
                }

            } else {
                this.setState({ colorModal: this.state.colorErrorModal, tituloModal: '¡Atención!', mensajeError: "Verifica que los teléfonos de las referencias no sean iguales entre sí ni con el teléfono del cliente.", showModal: true })

            }
        } else {
            this.setState({ colorModal: this.state.colorErrorModal, tituloModal: '¡Atención!', mensajeError: "Debes ingresar al menos dos referencias.", showModal: true })
        }


    }else{
        this.setState({ colorModal: "#FF4000", tituloModal: 'Avisó!', mensajeError:"Debe generar el id de la solicitud antes de guardar una referencia "  , showModal: true })

    }



        /* envio de referencia N */

    }

    render() {
        return (
            <div className="container-fluid">
                <div className="row">
                    <div className="col-lg-12 col-md-12 col-xs-12">
                    < Row>
                    <Col xs={12}>
                        <span style={{ font: "bold", color: "#FF4000" }}>Sol: {this.props.idSolicitud == null ? "0" : this.props.idSolicitud}  DXN:{this.state.idDXN} </span>

                    </Col>
                </Row>
                        <Row>
                            <div style={{ textAlign: "right" }}>
                                <img src={botonAgregar} style={{ maxWidth: '90%', height: '60px' }} onClick={this.agregarNueva} type="button" />
                            </div>


                        </Row>

                        <br></br>
                        <Accordion defaultActiveKey={[this.state.defaultActiveKey]} >
                            {/*
                            <Accordion.Item eventKey="0">
                                <Accordion.Header>Referencia no. 1</Accordion.Header>
                                <Accordion.Body>
                                    
                                    <Row>
                                        <Col lg={3} md={3} xs={12}>
                                            <Form.Group className="mb-6" controlId="">
                                                <Form.Label>Primer nombre sin abreviaturas: <span style={{ color: 'red' }}>*</span></Form.Label>
                                                <Form.Control type="text" placeholder="" name="primerNombre" onChange={this.onChangeValues} value={this.state.referencia1.primerNombre} />
                                                {this.state.validaciones.validaPrimerNombre == false || this.state.referencia1.primerNombre == null ? (
                                                    <Form.Label><span style={{ color: 'red' }}>Se debe ingresar un nombre</span></Form.Label>
                                                ) : null}
                                            </Form.Group>
                                        </Col>

                                        <Col lg={3} md={3} xs={12}>
                                            <Form.Group className="mb-6" controlId="">
                                                <Form.Label>Segundo nombre sin abreviaturas:</Form.Label>
                                                <Form.Control type="text" placeholder="" name="segundoNombre" onChange={this.onChangeValues} value={this.state.referencia1.segundoNombre} />

                                            </Form.Group>
                                        </Col>

                                        <Col lg={3} md={3} xs={12}>
                                            <Form.Group className="mb-6" controlId="">
                                                <Form.Label>Primer apellido: <span style={{ color: 'red' }}>*</span></Form.Label>
                                                <Form.Control type="text" placeholder="" name="primerApellido" onChange={this.onChangeValues} value={this.state.referencia1.primerApellido} />
                                                {this.state.validaciones.validaPrimerApellido == false || this.state.referencia1.primerApellido == null ? (
                                                    <Form.Label><span style={{ color: 'red' }}>Se debe ingresar un nombre</span></Form.Label>
                                                ) : null}
                                            </Form.Group>
                                        </Col>

                                        <Col lg={3} md={3} xs={12}>
                                            <Form.Group className="mb-6" controlId="">
                                                <Form.Label>Segundo apellido: <span style={{ color: 'red' }}>*</span></Form.Label>
                                                <Form.Control type="text" placeholder="" name="segundoApellido" onChange={this.onChangeValues} value={this.state.referencia1.segundoApellido} />
                                                {this.state.validaciones.validaSegundoNombre == false || this.state.referencia1.segundoApellido == null ? (
                                                    <Form.Label><span style={{ color: 'red' }}>Se debe ingresar un nombre</span></Form.Label>
                                                ) : null}
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <br />
                                   
                                    <Row>
                                        <Col lg={3} md={3} xs={12}>
                                            <Form.Group className="mb-6" controlId="">
                                                <Form.Label>Telefono: <span style={{ color: 'red' }}>*</span></Form.Label>
                                                <Form.Control type="number" placeholder="" name="telefono" onChange={this.onChangeValues} value={this.state.referencia1.telefono} />
                                                {this.state.validaciones.validaTelefono == false || this.state.referencia1.telefono == null ? (
                                                    <Form.Label><span style={{ color: 'red' }}>Se debe ingresar un nombre</span></Form.Label>
                                                ) : null}
                                            </Form.Group>
                                        </Col>

                                        <Col lg={3} md={3} xs={12}>
                                            <Form.Group className="mb-6" controlId="">
                                                <Form.Label>Celular: <span style={{ color: 'red' }}>*</span></Form.Label>
                                                <Form.Control type="number" placeholder="" name="celular" onChange={this.onChangeValues} value={this.state.referencia1.celular} />
                                                {this.state.validaciones.validaCelular == false || this.state.referencia1.celular == null ? (
                                                    <Form.Label><span style={{ color: 'red' }}>Se debe ingresar un nombre</span></Form.Label>
                                                ) : null}
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                </Accordion.Body>
                            </Accordion.Item>*/}
                            {
                                this.state.items.map(function (x) {
                                    return x;
                                })
                            }



                        </Accordion>

                        <br></br>
                        <br></br>

                        <Row>
                            <div style={{ display: "flex" }}>
                                <img src={botonRegresar}
                                    style={{ width: '150px', height: "60px", borderRadius: '40px', marginRight: "10px" }} onClick={() => { this.props.clickAvanzar("ocupacion") }} />
                                <img src={botonAvanzar} disabled={this.props.banderaSolicitud}
                                    style={{ width: '150px', height: "60px", borderRadius: '40px', marginRight: "10px" }} onClick={this.props.banderaSolicitud ? () => { console.log("mkk") } : this.enviaReferencias} />
                            </div>
                        </Row>



                    </div>
                </div>

                <Modal show={this.state.showModalBorrar} onHide={() => { this.setState({ showModalBorrar: false }) }} centered size="lg">
                    <Modal.Header style={{ backgroundColor: this.state.colorModal, textAlign: 'center' }} closeButton>
                        <Modal.Title style={{ textAlign: 'center' }}>{this.state.tituloModal}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body style={{ textAlign: 'center' }}>{this.state.mensajeError}</Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => { this.setState({ showModalBorrar: false }) }}>
                            Cerrar
                        </Button>

                        <Button variant="primary" onClick={() => {
                            this.eliminarReferencia(this.state.idDocumentoAEliminar)
                            this.setState({ showModalBorrar: false })
                            console.log("el id del dox  ", this.state.idDocumentoAEliminar)
                        }}>
                            Aceptar
                        </Button>
                    </Modal.Footer>
                </Modal>

                <Modal show={this.state.showModal} onHide={() => { this.setState({ showModal: false }) }} centered size="lg">
                    <Modal.Header style={{ backgroundColor: this.state.colorModal, textAlign: 'center' }} closeButton>
                        <Modal.Title style={{ textAlign: 'center' }}>{this.state.tituloModal}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body style={{ textAlign: 'center' }}>{this.state.mensajeError}</Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => { this.setState({ showModal: false }) }}>
                            Cerrar
                        </Button>

                    </Modal.Footer>
                </Modal>
            </div>
        )
    }
}