import React, { useEffect } from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import Webcam from "react-webcam";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import conexiones from '../urls/conexiones.json'
import imgChavo from '../imagenes/imgChavo.png'
const VideoIMSS = (props) => {
  const webcamRef = React.useRef(null);
  const mediaRecorderRef = React.useRef(null);
  const [capturing, setCapturing] = React.useState(false);
  const [recordedChunks, setRecordedChunks] = React.useState([]);
  const [nombreSolicitante,setNombreSolicitante]=React.useState("");
  const [montoSolicitado,setMontoSolicitado]=React.useState("");
  const [plazo,setPlazo]=React.useState(0);
  const [frecuencia,setFrecuencia]=React.useState("");
  const [muestraCamara,setMuestraCama]=React.useState(true);
  const videoMimeType = "video/webm;codecs=vp9" || 
  "video/webm;codecs=vp8" ||
   "video/mp4;codecs=av01.0.05M.08" || 
   "video/mp4; codecs=avc1.424028, mp4a.40.2" || 
    "video/mp4;codecs=avc1.42E01E" || "video/x-msvideo" || "video/mp4; codecs=avc1" || 
    "video/webm" || "video/h264";

 /* const handleStartCaptureClick = React.useCallback(() => {  
    setCapturing(true);
    mediaRecorderRef.current = new MediaRecorder(webcamRef.current.stream, {
      mimeType: "video/webm"
    });
    mediaRecorderRef.current.addEventListener(
      "dataavailable",
      handleDataAvailable
    );
    mediaRecorderRef.current.start();
  }, [webcamRef, setCapturing, mediaRecorderRef]);

  const handleDataAvailable = React.useCallback(
    ({ data }) => {
      if (data.size > 0) {
        setRecordedChunks((prev) => prev.concat(data));
      }
    },
    [setRecordedChunks]
  );*/

  const handleStartCaptureClick = () => {
    if (MediaRecorder.isTypeSupported(videoMimeType)) {
        setCapturing(true);
        mediaRecorderRef.current = new MediaRecorder(webcamRef.current.stream, {
            mimeType: videoMimeType
        });
        mediaRecorderRef.current.addEventListener("dataavailable", handleDataAvailable);
        mediaRecorderRef.current.start();
    } else {
        alert("Video MIME type not supported");
    }
};

const handleDataAvailable = ({ data }) => {
    if (data.size > 0) {
        setRecordedChunks(prev => [...prev, data]);
    }
};

  const handleStopCaptureClick = React.useCallback(() => {
    setMuestraCama(false)
    mediaRecorderRef.current.stop();
    setCapturing(false);
  }, [mediaRecorderRef, webcamRef, setCapturing]);

  const handleDownload = React.useCallback(() => {
    if (recordedChunks.length) {
      const blob = new Blob(recordedChunks, {
        type: "video/webm"
      });
      const url = URL.createObjectURL(blob);
      const a = document.createElement("a");
      document.body.appendChild(a);
      a.style = "display: none";
      a.href = url;  
      a.download = "miVideo.mp4";
      a.click();
      window.URL.revokeObjectURL(url);
      setRecordedChunks([]);
    }
  }, [recordedChunks]);


  useEffect(() => {
    console.log("entrando al componente ", props)


    if (props.idSolicitud != null) {
      if (props.existenDatosPersonales && props.existenDatosDomicilio && 
        props.existenDatosContacto &&
        props.existenDatosBancarios &&
        props.existenDatosOcupacion && props.existenDatosReferencias) {
          let primerNombre=
           props.datosPersonalesServicio != null &&  props.datosPersonalesServicio != undefined ? 
           props.datosPersonalesServicio instanceof Array ?  props.datosPersonalesServicio[0].primer_nombre+" ": props.datosPersonalesServicio.primer_nombre +" ":""
          let segundoNombre=
           props.datosPersonalesServicio != null &&  props.datosPersonalesServicio != undefined ?  props.datosPersonalesServicio instanceof Array ?  props.datosPersonalesServicio[0].segundo_nombre+" ": props.datosPersonalesServicio.segundo_nombre:"" 
          let primerApp=
           props.datosPersonalesServicio != null &&  props.datosPersonalesServicio != undefined ?  props.datosPersonalesServicio instanceof Array ?  props.datosPersonalesServicio[0].primer_apellido+" ": props.datosPersonalesServicio.primer_apellido +" ":""
         let segundoApp=
           props.datosPersonalesServicio != null &&  props.datosPersonalesServicio != undefined ?  props.datosPersonalesServicio instanceof Array ?  props.datosPersonalesServicio[0].segundo_apellido+" ": props.datosPersonalesServicio.segundo_apellido:" "
           setNombreSolicitante( primerNombre + segundoNombre + primerApp + segundoApp)
           setMontoSolicitado("$ "+props.nuevaSolicitudServicio.monto_solicitado)
           getPlazo()
           getFrecuencia()
      }
    }






  }, [])

  async function  getPlazo () {
    const settings = {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        "producto": props.nuevaSolicitudServicio.producto_id,
        "tipo_producto": props.nuevaSolicitudServicio.tipo_producto_id
      })
    };
    try {
      const fetchResponse = await fetch(conexiones.urlPytonParametros + "catalogo_producto_plazo/0", settings);
      const data = await fetchResponse.json();
      console.log("respesta de los plazos  ", data)
      if(data.length > 0){
        for(var i=0; i<data.length; i++){
          if(data[i].id == props.nuevaSolicitudServicio.plazo_id){
            setPlazo(data[i].plazo)
            break;
          }
        }  
      }
    } catch (e) {
      return e;
    }
  }

  async function  getFrecuencia () {
    const settings = {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      }
    };
    try {
      const fetchResponse = await fetch(conexiones.urlPytonParametros + "catalogo_periodo/"+props.nuevaSolicitudServicio.producto_id, settings);
      const data = await fetchResponse.json();
      if(data.filas.length > 0){
        for(var i=0; i<data.filas.length; i++){
          let fila =data.filas[i].fila
          console.log("respesta de la frecuencia   ", fila[0])
          if(fila[0].value ==props.nuevaSolicitudServicio.periodo_id){
            setFrecuencia(fila[1].value)
            break
          }

        }
      }


    } catch (e) {
      return e;
    }
  }



  return (
    <>
      <Row>
        <Col xs={0} md={2} lg={2}>&nbsp;</Col>
        <Col xs={12} md={8} lg={8}>
          <span style={{ fontSize: "15px", fontWeight: "bold", color: "red" }}>{"Una vez que estés listo presiona en comenzar para iniciar la grabación, debes leer la leyenda a la derecha recuerda que en todo momento debe estar enfocado tu rostro​"}</span>
        </Col>
        <Col xs={0} md={2} lg={2}>&nbsp;</Col>

      </Row >
      <br />
      <Row>
        <Col xs={12} md={6} lg={6}>
        {/* img style={{ width: "100%" }} src={imgChavo}/>*/}


        {muestraCamara == true ?  <Webcam audio={true} ref={webcamRef} style={{ width: "100%" }} />:""}
          {capturing ? (
            muestraCamara == true ? <button style={{ width: '100%' }} onClick={handleStopCaptureClick}>Detener video</button>:""
          ) : (
            muestraCamara == true ? <button variant="primary" style={{ width: '100%' }}  onClick={handleStartCaptureClick}>Iniciar video </button>:""
          )}
          {recordedChunks.length > 0 && (
            <button style={{ width: '100%' }}  onClick={handleDownload}>Descargar video</button>
          )}
  

        </Col>

        <Col xs={12} md={6} lg={6}>
          <span style={{ fontSize: "25px", fontWeight: "bold" }}>{"Yo "+nombreSolicitante + 
          ",acepto de DXN EXPRESS SA DE CV SOFOM ENR el otorgamiento de un crédito por un monto de "+ montoSolicitado +",mismo que, debe ser retenido en "+plazo+"  "+frecuencia+" Asimismo, autorizo al IMSS a que retenga de mi <Tipo de pensión>, el importe correspondiente para dar cumplimiento a las obligaciones pactadas en el contrato de crédito y me comprometo a dejar en paz y a salvo al IMSS de cualquier controversia que pudiera derivarse del otorgamiento del crédito."}</span>

        </Col>

      </Row >
    </>
  );
};


export default VideoIMSS;