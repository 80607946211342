import React, { Component } from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import Card from 'react-bootstrap/Card';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Container from 'react-bootstrap/Container';
const logo = require("../imagenes/logo_wedo.png")
const solicitud = require("../imagenes/SolicitudCaptura.PNG")
const analizada = require("../imagenes/Analizada.PNG")

export default class Principal extends Component {

    constructor() {
        super();
        this.handleMouseEnter=this.handleMouseEnter.bind(this)
        this.handleMouseLeave=this.handleMouseLeave.bind(this)
        this.state = {
          colorEntrando:"#FF6B00",
          banderaSolicitud:false,
          banderaAnalizadas:false,
          banderaDevueltas:false,
          banderaDenegadas:false,
          banderaYaDinero:false,
          banderaContinuaAnalizis:false,
          banderaAutorizada:false,
          banderaCementerio:false,
        }
    }


    UNSAFE_componentWillMount() {

    }

    handleMouseEnter(nombreTarjeta) {
      console.log("entrando con el mouse ")
      
      if(nombreTarjeta == "solicitudes"){
        this.setState({banderaSolicitud:true})
      }
      if(nombreTarjeta == "analizadas"){
        this.setState({banderaAnalizadas:true})
      }

      if(nombreTarjeta == "denegadas"){
        this.setState({banderaDenegadas:true})
      }
      if(nombreTarjeta == "devueltas"){
        this.setState({banderaDevueltas:true})
      }



      if(nombreTarjeta == "yaDinero"){
        this.setState({banderaYaDinero:true})
      }
      if(nombreTarjeta == "continuaAnalizada"){
        this.setState({banderaContinuaAnalizis:true})
      }

      if(nombreTarjeta == "autorizada"){
        this.setState({banderaAutorizada:true})
      }
      if(nombreTarjeta == "cementerio"){
        this.setState({banderaCementerio:true})
      }


    }

    handleMouseLeave(nombreTarjeta){
      if(nombreTarjeta == "solicitudes"){
        this.setState({banderaSolicitud:false})
      }
      if(nombreTarjeta == "analizadas"){
        this.setState({banderaAnalizadas:false})
      }   

      if(nombreTarjeta == "denegadas"){
        this.setState({banderaDenegadas:false})
      }
      if(nombreTarjeta == "devueltas"){
        this.setState({banderaDevueltas:false})
      }

      if(nombreTarjeta == "yaDinero"){
        this.setState({banderaYaDinero:false})
      }
      if(nombreTarjeta == "continuaAnalizada"){
        this.setState({banderaContinuaAnalizis:false})
      }

      if(nombreTarjeta == "autorizada"){
        this.setState({banderaAutorizada:false})
      }
      if(nombreTarjeta == "cementerio"){
        this.setState({banderaCementerio:false})
      }

   }

    render() {
        return (
            <div className="page-content" style={{ backgroundColor: 'white', marginTop: '25px', height: '100%', width: '100%' }}>
            <div className="content-wrapper">
              <div className="content-inner">
                <br />
                <br />
                <Container>
                     
                  <Row>
                    <Col lg={3} md={3} xs={12}>
                      <Card style={{ width: '100%', borderColor:this.state.banderaSolicitud == false ?'white':this.state.colorEntrando }} onMouseLeave={() =>{this.handleMouseLeave("solicitudes")}}  onMouseEnter={() => {this.handleMouseEnter("solicitudes")}}>
                        <Card.Header className="text-right">
                        <span style={{color:this.state.colorEntrando}} >26</span>
                        </Card.Header>
                        <Card.Body>
                        <Card.Img variant="top" style={{backgroundColor:this.state.banderaSolicitud == false ?'white':this.state.colorEntrando}}  src={solicitud} />
                        <Card.Title className="text-center">Mi solicitud en captura.</Card.Title>
                        </Card.Body>
                      </Card>
                    </Col>

                    <Col lg={3} md={3} xs={12}>
                      <Card style={{ width: '100%', borderColor:this.state.banderaAnalizadas == false ?'white':this.state.colorEntrando }} onMouseLeave={() =>{this.handleMouseLeave("analizadas")}}  onMouseEnter={() => {this.handleMouseEnter("analizadas")}}>
                        <Card.Header className="text-right">
                        <span style={{color:this.state.colorEntrando}} >26</span>
                        </Card.Header>
                        <Card.Body>
                        <Card.Img variant="top" style={{ backgroundColor:this.state.banderaAnalizadas == false ?'white':this.state.colorEntrando}}  src={analizada} />
                        <Card.Title className="text-center">Mi solicitud en captura.</Card.Title>
                        </Card.Body>
                      </Card>
                    </Col>


                    <Col lg={3} md={3} xs={12}>
                      <Card style={{ width: '100%', borderColor:this.state.banderaDenegadas == false ?'white':this.state.colorEntrando }} onMouseLeave={() =>{this.handleMouseLeave("denegadas")}}  onMouseEnter={() => {this.handleMouseEnter("denegadas")}}>
                        <Card.Header className="text-right">
                        <span style={{color:this.state.colorEntrando}} >26</span>
                        </Card.Header>
                        <Card.Body>
                        <Card.Img variant="top" style={{backgroundColor:this.state.banderaDenegadas == false ?'white':this.state.colorEntrando}}  src={solicitud} />
                        <Card.Title className="text-center">Mi solicitud en captura.</Card.Title>
                        </Card.Body>
                      </Card>
                    </Col>


                    <Col lg={3} md={3} xs={12}>
                      <Card style={{ width: '100%', borderColor:this.state.banderaDevueltas == false ?'white':this.state.colorEntrando }} onMouseLeave={() =>{this.handleMouseLeave("devueltas")}}  onMouseEnter={() => {this.handleMouseEnter("devueltas")}}>
                        <Card.Header className="text-right">
                        <span style={{color:this.state.colorEntrando}} >26</span>
                        </Card.Header>
                        <Card.Body>
                        <Card.Img variant="top" style={{backgroundColor:this.state.banderaDevueltas == false ?'white':this.state.colorEntrando}}  src={solicitud} />
                        <Card.Title className="text-center">Mi solicitud en captura.</Card.Title>
                        </Card.Body>
                      </Card>
                    </Col>
                  </Row>
                  <br />
                  <br />

                  <Row>
                    <Col lg={3} md={3} xs={12}>
                      <Card style={{ width: '100%', borderColor:this.state.banderaYaDinero == false ?'white':this.state.colorEntrando }} onMouseLeave={() =>{this.handleMouseLeave("yaDinero")}}  onMouseEnter={() => {this.handleMouseEnter("yaDinero")}}>
                        <Card.Header className="text-right">
                        <span style={{color:this.state.colorEntrando}} >26</span>
                        </Card.Header>
                        <Card.Body>
                        <Card.Img variant="top" style={{backgroundColor:this.state.banderaYaDinero == false ?'white':this.state.colorEntrando}}  src={solicitud} />
                        <Card.Title className="text-center">Mi solicitud en captura.</Card.Title>
                        </Card.Body>
                      </Card>
                    </Col>

                    <Col lg={3} md={3} xs={12}>
                      <Card style={{ width: '100%', borderColor:this.state.banderaContinuaAnalizis == false ?'white':this.state.colorEntrando }} onMouseLeave={() =>{this.handleMouseLeave("continuaAnalizada")}}  onMouseEnter={() => {this.handleMouseEnter("continuaAnalizada")}}>
                        <Card.Header className="text-right">
                        <span style={{color:this.state.colorEntrando}} >26</span>
                        </Card.Header>
                        <Card.Body>
                        <Card.Img variant="top" style={{ backgroundColor:this.state.banderaContinuaAnalizis == false ?'white':this.state.colorEntrando}}  src={analizada} />
                        <Card.Title className="text-center">Mi solicitud en captura.</Card.Title>
                        </Card.Body>
                      </Card>
                    </Col>


                    <Col lg={3} md={3} xs={12}>
                      <Card style={{ width: '100%', borderColor:this.state.banderaAutorizada == false ?'white':this.state.colorEntrando }} onMouseLeave={() =>{this.handleMouseLeave("autorizada")}}  onMouseEnter={() => {this.handleMouseEnter("autorizada")}}>
                        <Card.Header className="text-right">
                        <span style={{color:this.state.colorEntrando}} >26</span>
                        </Card.Header>
                        <Card.Body>
                        <Card.Img variant="top" style={{backgroundColor:this.state.banderaAutorizada == false ?'white':this.state.colorEntrando}}  src={solicitud} />
                        <Card.Title className="text-center">Mi solicitud en captura.</Card.Title>
                        </Card.Body>
                      </Card>
                    </Col>


                    <Col lg={3} md={3} xs={12}>
                      <Card style={{ width: '100%', borderColor:this.state.banderaCementerio == false ?'white':this.state.colorEntrando }} onMouseLeave={() =>{this.handleMouseLeave("cementerio")}}  onMouseEnter={() => {this.handleMouseEnter("cementerio")}}>
                        <Card.Header className="text-right">
                        <span style={{color:this.state.colorEntrando}} >26</span>
                        </Card.Header>
                        <Card.Body>
                        <Card.Img variant="top" style={{backgroundColor:this.state.banderaCementerio == false ?'white':this.state.colorEntrando}}  src={solicitud} />
                        <Card.Title className="text-center">Mi solicitud en captura.</Card.Title>
                        </Card.Body>
                      </Card>
                    </Col>
                  </Row>
                  
                </Container>
              </div>
            </div>
          </div>
          
        )
    }
}
