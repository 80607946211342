import React, { Component } from "react";
import 'bootstrap/dist/css/bootstrap.min.css';

import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
export default class ModalGenerica extends Component {

    constructor() {
        super();
        this.cerrar=this.cerrar.bind(this)
        this.state = {
        show:false
        }
    }

    cerrar(){
        this.setState({show:false})
        
    }
   

    UNSAFE_componentWillMount() {
        this.setState({show:this.props.show})
    }

    render() {
        return (
            <Modal show={this.props.show} onHide={this.cerrar} centered size="lg"            >
                <Modal.Header closeButton>
                    <Modal.Title>Modal heading</Modal.Title>
                </Modal.Header>
                <Modal.Body>Woohoo, you are reading this text in a modal!</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" >
                        Close
                    </Button>
                    <Button variant="primary" >
                        Save Changes
                    </Button>
                </Modal.Footer>
            </Modal>
        )


    }

}