// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.topnav {
    background-color: hwb(15 15% 37%);
    height: 100;
    padding: 13px;
    margin-top: -10px;
    padding-right:0;
    margin-left: -10px;
    margin-right: -10px;
    text-align: center;
    font-style: Georgia;
    font-size: 20px;
  }
  `, "",{"version":3,"sources":["webpack://./src/componentes/sideBar/Dashboard.css"],"names":[],"mappings":"AAAA;IACI,iCAAiC;IACjC,WAAW;IACX,aAAa;IACb,iBAAiB;IACjB,eAAe;IACf,kBAAkB;IAClB,mBAAmB;IACnB,kBAAkB;IAClB,mBAAmB;IACnB,eAAe;EACjB","sourcesContent":[".topnav {\n    background-color: hwb(15 15% 37%);\n    height: 100;\n    padding: 13px;\n    margin-top: -10px;\n    padding-right:0;\n    margin-left: -10px;\n    margin-right: -10px;\n    text-align: center;\n    font-style: Georgia;\n    font-size: 20px;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
