import React, { Component } from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import Container from 'react-bootstrap/Container';
import Card from 'react-bootstrap/Card';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Nav from 'react-bootstrap/Nav';

import Button from 'react-bootstrap/Button';

export default class CreaNuevaSolicitudPestana extends Component {

    constructor() {
        super();
        this.state = {
            datosSolicitante: ""
        }
    }


    UNSAFE_componentWillMount() {
        switch (this.props.pantalla) {
            case 'nuevaSolicitud':
                this.setState({ datosSolicitante: "Datos solicitante" })
                break;
            case 'datosGenerales':
                this.setState({ datosSolicitante: "Domicilio" })
                break;
            default:
                this.setState({ datosSolicitante: "Datos solicitante" })
        }
    }

    render() {
        return (

            <Nav variant="tabs" defaultActiveKey="/home">
                <Nav.Item>
                    {this.props.componenteActivo === 'solicitud' ? (
                        <Nav.Link href="#" style={{ backgroundColor: '#FF4000', color: 'white' }}>
                            Solicitud
                        </Nav.Link>
                    ) : (
                        <Nav.Link href="#" onClick={() => { this.props.clickAvanzar("solicitud") }} style={{ color: 'black' }}>
                            Solicitud
                        </Nav.Link>
                    )}
                </Nav.Item>
                <Nav.Item>
                    {this.props.componenteActivo === 'datosSol' ? (
                        <Nav.Link href="#" style={{ backgroundColor: '#FF4000', color: 'white' }}>
                            {this.state.datosSolicitante}
                        </Nav.Link>
                    ) : (
                        <Nav.Link eventKey="link-1" onClick={() => { this.props.clickAvanzar("datosSol") }} style={{ color: 'black' }}>
                            {this.state.datosSolicitante}
                        </Nav.Link>
                    )}
                </Nav.Item>
                <Nav.Item>
                    {this.props.componenteActivo === 'ocupacion' ? (
                        <Nav.Link href="#" style={{ backgroundColor: '#FF4000', color: 'white' }}>
                            Ocupación
                        </Nav.Link>
                    ) : (
                        <Nav.Link eventKey="link-1" onClick={() => { this.props.clickAvanzar("ocupacion") }} style={{ color: 'black' }}>
                            Ocupación
                        </Nav.Link>
                    )}
                </Nav.Item>
                <Nav.Item>
                    {this.props.componenteActivo === 'referencias' ? (
                        <Nav.Link href="#" style={{ backgroundColor: '#FF4000', color: 'white' }}>
                            Referencias
                        </Nav.Link>
                    ) : (
                        <Nav.Link eventKey="link-1" onClick={() => { this.props.clickAvanzar("referencias") }} style={{ color: 'black' }}>
                            Referencias
                        </Nav.Link>
                    )}
                </Nav.Item>
                
                
                {
                    this.props.compraDeuda == true ?
                    <Nav.Item>
                    {this.props.componenteActivo === 'compraDeuda' ? (
                        <Nav.Link href="#" style={{ backgroundColor: '#FF4000', color: 'white' }}>
                            Compra Deuda/Refinanciamiento
                        </Nav.Link>
                    ) : (
                        <Nav.Link eventKey="link-1" onClick={() => { this.props.clickAvanzar("compraDeuda") }} style={{ color: 'black' }}>
                            Compra Deuda/Refinanciamiento
                        </Nav.Link>
                    )}
                </Nav.Item>
                    :''

                }




                <Nav.Item>
                    {this.props.componenteActivo === 'documentos' ? (
                        <Nav.Link href="#" style={{ backgroundColor: '#FF4000', color: 'white' }}>
                            Documentos
                        </Nav.Link>
                    ) : (
                        <Nav.Link eventKey="link-1" onClick={() => { this.props.clickAvanzar("documentos") }} style={{ color: 'black' }}>
                            Documentos
                        </Nav.Link>
                    )}
                </Nav.Item>


                {
           /*         this.props.activar_IMSS == true ?
                    <Nav.Item>
                    {this.props.componenteActivo === 'videoIMSS' ? (
                        <Nav.Link href="#" style={{ backgroundColor: '#FF4000', color: 'white' }}>
                            Video acuerdo
                        </Nav.Link>
                    ) : (
                        <Nav.Link eventKey="link-1" onClick={() => { this.props.clickAvanzar("videoIMSS") }} style={{ color: 'black' }}>
                            Video acuerdo
                        </Nav.Link>
                    )}
                </Nav.Item>
                    :''
*/
                    }

{
                    this.props.activar_IMSS == true ?
                    <Nav.Item>
                    {this.props.componenteActivo === 'videoIMSSioos' ? (
                        <Nav.Link href="#" style={{ backgroundColor: '#FF4000', color: 'white' }}>
                            Video acuerdo 
                        </Nav.Link>
                    ) : (
                        <Nav.Link eventKey="link-1" onClick={() => { this.props.clickAvanzar("videoIMSSioos") }} style={{ color: 'black' }}>
                            Video acuerdo 
                        </Nav.Link>
                    )}
                </Nav.Item>
                    :''

                    }


{
                     this.props.idUsuario ==323?
                    <Nav.Item>
                    {this.props.componenteActivo === 'EscaneoIne' ? (
                        <Nav.Link href="#" style={{ backgroundColor: '#FF4000', color: 'white' }}>
                            EscaneoIne 
                        </Nav.Link>
                    ) : (
                        <Nav.Link eventKey="link-1" onClick={() => { this.props.clickAvanzar("EscaneoIne") }} style={{ color: 'black' }}>
                            EscaneoIne  
                        </Nav.Link>
                    )}
                </Nav.Item>
                    :''

                }


{/*
                <Nav.Item>    
                        <Nav.Link eventKey="link-1" onClick={() => { this.props.resetFormularios() }} style={{ color: 'red' }}>
                            Limpiar Formularios
                        </Nav.Link>
                    </Nav.Item>*/}


            </Nav>


        )
    }

}