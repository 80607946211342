import React, { useState } from 'react';
import './side.css';
import 'bootstrap/dist/css/bootstrap.min.css';



const SideBar2 = () => {
    const [isSidebarOpen, setSidebarOpen] = useState(true);

    const toggleSidebar = () => {
      setSidebarOpen(!isSidebarOpen);
    };
  
    return (
      <div className="App custom-scrollbars">
        <nav className="navbar navbar-expand-lg navbar-dark bg-dark">
          <button
            className="navbar-toggler"
            type="button"
            onClick={toggleSidebar}
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <span onClick={toggleSidebar} className="navbar-brand">Plantilla con Menú Sidebar</span>
        </nav>
        <div className={`sidebar   ${isSidebarOpen ? 'open' : '' } `}>
          <ul className="nav flex-column">
            <li className="nav-item">
              <a className="nav-link" href="#">Inicio</a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="#">Acerca de</a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="#">Servicios</a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="#">Contacto</a>
            </li>
          </ul>
        </div>
        <div >
          <h2>Bienvenido</h2>
          <p>Contenido principal de la página.</p>
        </div>
      </div>
    );
  };
  
  export default SideBar2;