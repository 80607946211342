import React from "react";
import { Navbar, Nav } from "react-bootstrap";
import "./Dashboard.css";

class HeaderBar extends React.Component {
  render() {
    return (
      <div class="card" style={{ backgroundColor: "#f1f3fa", height:'50px' }}>
        <div class="row mb-2 mt-2 ml-1">

          <div class="col-9 d-flex align-items-center">
          </div>


          <div class="col-3">
            <div class="media-body d-none d-md-block">
              <span ><>&nbsp;&nbsp;&nbsp;&nbsp;</></span>
            </div>

          </div>
        </div>
      </div>
    );
  }
}

export default HeaderBar;
