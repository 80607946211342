import React, { Component } from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import Container from 'react-bootstrap/Container';
import Card from 'react-bootstrap/Card';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Nav from 'react-bootstrap/Nav';
import Table from 'react-bootstrap/Table';
import FastForwardIcon from '@mui/icons-material/FastForward';
import FastRewindIcon from '@mui/icons-material/FastRewind';
import conexiones from '../urls/conexiones.json'
import Modal from 'react-bootstrap/Modal';
import botonAvanzar from '../imagenes/avanzar_boton.png'
import botonRegresar from '../imagenes/Boton_regresar.png'
import botonAgregar from '../imagenes/botonAgregar.png'
import DeleteIcon from '@mui/icons-material/Delete';
import Button from 'react-bootstrap/Button';

export default class CompraDeudaForm extends Component {

    constructor() {
        super();
        this.agregarFila = this.agregarFila.bind(this)
        this.eliminarFila = this.eliminarFila.bind(this)
        this.pintaItems = this.pintaItems.bind(this)
        this.enviaReferencias = this.enviaReferencias.bind(this)
        this.getReferencias = this.getReferencias.bind(this)
        this.actualizaReferencia = this.actualizaReferencia.bind(this)
        this.eliminaReferenciaService = this.eliminaReferenciaService.bind(this)
        this.state = {
            
            idDXN:"",
            showModalBorrar:false,
            idDocumentoAEliminar:null,
            /*modal */
            colorErrorModal: '#F83C3C',
            colorSuccesModal: '#27F45E',
            colorModal: '',
            tituloModal: '',
            mensajeError: '',
            showModal: false,
            /*modal */
            datosSolicitante: "",
            items: [],
            referencias: [],
        }
    }

    async getSolicitud(idSolicitud) {
        try {
          const response = await fetch(conexiones.urlServicios + "wedo_solicitud/" + idSolicitud);
          if (response.ok) {
            const json = await response.json();
            console.log("respojsjahsahsuhuiah  ", json)
            if (json.length > 0) {
              this.setState({idBDSolicitud:json[0].id, idDXN:json[0].appid})
            }
          }
        } catch (error) {
          console.error('Error:', error);
        }
      }

    UNSAFE_componentWillMount() {
        console.log("props de compra deuda ",this.props)
        if (this.props.idSolicitud != undefined) {
            this.getSolicitud(this.props.idSolicitud) 
            this.getReferencias(this.props.idSolicitud) 

        }
    }

    async getReferencias(idSolicitud) {
        try {
            const response = await fetch(conexiones.urlServicios + "wedo_refinanciamiento/" + idSolicitud);
            if (response.ok) {
                const json = await response.json();
                console.log("referencias ", json)
                if (json.length > 0) {
                    //  this.armaReferencia1(json[0])
                    //this.armaReferencias(json)
                    this.setState({ referencias: json })
                    this.pintaItems(json)
                }


            } else {
                console.error('Error:', response.status);
                this.setState({ colorModal: this.state.colorErrorModal, tituloModal: 'Ocurrio un error!', mensajeError: response.status, showModal: true })

            }
        } catch (error) {
            console.error('Error:', error);
            this.setState({ colorModal: this.state.colorErrorModal, tituloModal: 'Ocurrio un error!', mensajeError: error.toString(), showModal: true })
        }
    }


    mascaraDecimales(valor) {
        return valor.replace(/\D/g, "")
            .replace(/([0-9])([0-9]{2})$/, '$1.$2')
            .replace(/\B(?=(\d{3})+(?!\d)\.?)/g, ",");
    }

    onChangeValuesRefenrencias = e => {
        console.log(e.target.name, e.target.value, this.state.referencias)
        let referencias = this.state.referencias
        let nombreCampo = e.target.name
        let idReferenciaString = nombreCampo.split("/")[1]
        nombreCampo = nombreCampo.split("/")[0]
        let idReferencia = parseInt(idReferenciaString)


        if (nombreCampo == "numero_contrato") {
            referencias[idReferencia].numero_contrato = e.target.value
        }
        if (nombreCampo == "casa_financiera") {
            referencias[idReferencia].casa_financiera = e.target.value
        }
        if (nombreCampo == "referencia_bancaria") {
            referencias[idReferencia].referencia_bancaria = e.target.value
        }
        
        if (nombreCampo == "descuento") {
            referencias[idReferencia].descuento = this.mascaraDecimales(e.target.value)
        }
        if (nombreCampo == "liquidar") {
            referencias[idReferencia].liquidar = this.mascaraDecimales(e.target.value)
        }

        //this.mascaraDecimales(e.target.value)

        this.setState({ referencias: referencias })
        this.pintaItems(referencias)
    }


    agregarFila() {
        let items = this.state.items
        let referencias = this.state.referencias
        let conta = 0;
        conta = conta + this.state.items.length
        let jsonReferencia = {
            id: null,
            numero_contrato: null,
            casa_financiera: null,
            referencia_bancaria: null,
            descuento: null,
            liquidar: null,
            solicitud: this.props.idSolicitud
        }
        referencias.push(jsonReferencia)
        this.setState({ referencias: referencias })
        items.push(
            <tr>
                <td style={{ color: 'blue' }} colSpan={1}>
                    <Form.Group className="mb-6" controlId="">
                        <Form.Control  disabled={this.props.banderaSolicitud} type="text" placeholder="" maxLength={30} onChange={this.onChangeValuesRefenrencias} value={this.state.referencias[this.state.referencias.length - 1].numero_contrato} name={"numero_contrato/" + conta} />
                    </Form.Group>
                </td>
                <td style={{ color: 'blue' }} colSpan={1}>
                    <Form.Group className="mb-6" controlId="">
                        <Form.Control disabled={this.props.banderaSolicitud} type="text" placeholder=""  maxLength={60}  onChange={this.onChangeValuesRefenrencias} value={this.state.referencias[this.state.referencias.length - 1].casa_financiera} name={"casa_financiera/" + conta} />
                    </Form.Group>
                </td>

                <td style={{ color: 'blue' }} colSpan={1}>
                    <Form.Group className="mb-6" controlId="">
                        <Form.Control disabled={this.props.banderaSolicitud} type="text" placeholder=""  maxLength={50}  onChange={this.onChangeValuesRefenrencias} value={this.state.referencias[this.state.referencias.length - 1].referencia_bancaria} name={"referencia_bancaria/" + conta} />
                    </Form.Group>
                </td>
                <td style={{ color: 'blue' }} colSpan={1}>
                    <Form.Group className="mb-6" controlId="">
                        <Form.Control  disabled={this.props.banderaSolicitud} type="text" placeholder="" onChange={this.onChangeValuesRefenrencias} value={this.state.referencias[this.state.referencias.length - 1].descuento} name={"descuento/" + conta} />
                    </Form.Group>
                </td>
                <td style={{ color: 'blue' }} colSpan={1}>
                    <Form.Group className="mb-6" controlId="">
                        <Form.Control disabled={this.props.banderaSolicitud} type="text" placeholder="" onChange={this.onChangeValuesRefenrencias} value={this.state.referencias[this.state.referencias.length - 1].liquidar} name={"liquidar/" + conta} />
                    </Form.Group>
                </td>
                <td style={{ color: 'blue' }} colSpan={1}>
                    <Button variant="danger" onClick={() => { 
                         this.props.banderaSolicitud ? console.log("mkk") :
                        this.setState({idDocumentoAEliminar:conta,colorModal: this.state.colorErrorModal,
                            tituloModal: 'Atención!',mensajeError:"Estas seguro que deseas eliminar este registro "+"Referencia no." + (conta+1)+"?",showModalBorrar:true})
                     }} style={{ borderRadius: '40px', width: '100%' }} type="button"> <DeleteIcon /> </Button>
                </td>
            </tr>
        )

        this.setState({ items: items })
    }


    eliminarFila(id) {
        //    let items=this.state.referencias
        let referencias = this.state.referencias
        let referenciaAEliminar = referencias[id]
        console.log("ref a leminiar", referenciaAEliminar)
        if (referenciaAEliminar != null || referenciaAEliminar != undefined) {
            if (referenciaAEliminar.id == null) {
                // solo e elina del dom
                referencias.splice(id, 1)
            } else {
                this.eliminaReferenciaService(referenciaAEliminar.id)
                referencias.splice(id, 1)
            }
        }
        this.pintaItems(referencias)
    }

    pintaItems(items) {
        let nuevosItems = []
        for (let i = 0; i < items.length; i++) {
            nuevosItems.push(
                <tr>
                    <td style={{ color: 'blue' }} colSpan={1}>
                        <Form.Group className="mb-6" controlId="">
                            <Form.Control  disabled={this.props.banderaSolicitud} type="text" placeholder="" maxLength={30} onChange={this.onChangeValuesRefenrencias} value={this.state.referencias[i].numero_contrato} name={"numero_contrato/" + i} />
                        </Form.Group>
                    </td>
                    <td style={{ color: 'blue' }} colSpan={1}>
                        <Form.Group className="mb-6" controlId="">
                            <Form.Control disabled={this.props.banderaSolicitud} type="text" placeholder=""  maxLength={60}  onChange={this.onChangeValuesRefenrencias} value={this.state.referencias[i].casa_financiera} name={"casa_financiera/" + i} />
                        </Form.Group>
                    </td>
                    <td style={{ color: 'blue' }} colSpan={1}>
                        <Form.Group className="mb-6" controlId="">
                            <Form.Control disabled={this.props.banderaSolicitud} type="text" placeholder=""  maxLength={50}  onChange={this.onChangeValuesRefenrencias} value={this.state.referencias[i].referencia_bancaria} name={"referencia_bancaria/" + i} />
                        </Form.Group>
                    </td>
                    <td style={{ color: 'blue' }} colSpan={1}>
                        <Form.Group className="mb-6" controlId="">
                            <Form.Control disabled={this.props.banderaSolicitud} type="text" placeholder="" onChange={this.onChangeValuesRefenrencias} value={this.state.referencias[i].descuento} name={"descuento/" + i} />
                        </Form.Group>
                    </td>
                    <td style={{ color: 'blue' }} colSpan={1}>
                        <Form.Group className="mb-6" controlId="">
                            <Form.Control disabled={this.props.banderaSolicitud} type="text" placeholder="" onChange={this.onChangeValuesRefenrencias} value={this.state.referencias[i].liquidar} name={"liquidar/" + i} />
                        </Form.Group>
                    </td>
                    <td style={{ color: 'blue' }} colSpan={1}>
                        <Button variant="danger" onClick={() => {  this.props.banderaSolicitud ? console.log("mkk") :this.setState({idDocumentoAEliminar:i,colorModal: this.state.colorErrorModal,
                                    tituloModal: 'Atención!',mensajeError:"Estas seguro que deseas eliminar este registro "+"Referencia no." + (i+1)+"?",showModalBorrar:true})/*this.eliminarFila(i)*/ }} style={{ borderRadius: '40px', width: '100%' }} type="button"> <DeleteIcon /> </Button>
                    </td>
                </tr>
            )
        }
        this.setState({ items: nuevosItems })
    }



    creaReferenciaNueva(json, numeroReferencia) {

        console.log("el json ", json)
        const requestReferidoOptions = {
            method: "POST",
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(json)
        }
        fetch(conexiones.urlServicios + "wedo_refinanciamiento/0", requestReferidoOptions)
            .then(response => response.json())
            .then(data => {

                if (data["id"] != undefined) {

                } else {
                    this.setState({ colorModal: this.state.colorErrorModal, tituloModal: 'Ocurrio un error al guardar la referencia ' + numeroReferencia + " !", mensajeError: JSON.stringify(data), showModal: true })
                }

            }).catch(error => {
                this.setState({ colorModal: this.state.colorErrorModal, tituloModal: 'Ocurrio un error al guardar la referencia ' + numeroReferencia + " !", mensajeError: error.toString(), showModal: true })
            })

    }


    eliminaReferenciaService(idReferencia) {
        const requestReferidoOptions = {
            method: "DELETE",
            headers: { 'Content-Type': 'application/json' },
        }
        fetch(conexiones.urlServiciosDetalle + "wedo_refinanciamiento/" + idReferencia, requestReferidoOptions)
            .then(response => response.json())
            .then(data => {

                //  this.setState({ colorModal: this.state.colorSuccesModal, tituloModal: 'Eliminada ', mensajeError: "Se ha eliminado la referenca", showModal: true })


            }).catch(error => {
                this.setState({ colorModal: this.state.colorErrorModal, tituloModal: 'Ocurrio un error al eliminar la referencia ', mensajeError: error.toString(), showModal: true })
            })

    }

    actualizaReferencia(json, numeroReferencia) {

        console.log("el json ", json)
        const requestReferidoOptions = {
            method: "PUT",
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(json)
        }
        fetch(conexiones.urlServiciosDetalle + "wedo_refinanciamiento/" + json.id, requestReferidoOptions)
            .then(response => response.json())
            .then(data => {

                if (data["id"] != undefined) {

                } else {
                    this.setState({ colorModal: this.state.colorErrorModal, tituloModal: 'Ocurrio un error al guardar la referencia ' + numeroReferencia + " !", mensajeError: JSON.stringify(data), showModal: true })
                }

            }).catch(error => {
                this.setState({ colorModal: this.state.colorErrorModal, tituloModal: 'Ocurrio un error al guardar la referencia ' + numeroReferencia + " !", mensajeError: error.toString(), showModal: true })
            })

    }



    enviaReferencias() {
        let banderaContinuar = true
        if(this.state.referencias.length == 0 && this.props.nuevaSolicitudServicio.tipo_producto_id == 3 ){
            this.setState({ colorModal: this.state.colorErrorModal, tituloModal: 'Revisa la información' + " !", mensajeError: "Debe capturar por lo menos una compra de deuda ", showModal: true })
        }else{
            for (let i = 0; i < this.state.referencias.length; i++) {
                console.log(this.state.referencias[i], "referencia")
                if (this.state.referencias[i].casa_financiera == null || this.state.referencias[i].descuento == null || this.state.referencias[i].liquidar == null 
                    || this.state.referencias[i].numero_contrato == null
                    || this.state.referencias[i].referencia_bancaria == null
                    ) {
                    
                        banderaContinuar = false
                    break;
                } else {
                    if (this.state.referencias[i].casa_financiera.length == 0 || this.state.referencias[i].descuento.length == 0 || this.state.referencias[i].liquidar.length == 0 ||
                        this.state.referencias[i].numero_contrato.length == 0 || this.state.referencias[i].referencia_bancaria.length == 0) {
                        banderaContinuar = false
                    }
                }
            }
            console.log(banderaContinuar)
            if (banderaContinuar) {
                for (let i = 0; i < this.state.referencias.length; i++) {
                    let referencia = this.state.referencias[i]
                    if (referencia.id != null) {
                        this.actualizaReferencia(referencia, (i) + "")
                    } else {
                        this.creaReferenciaNueva(referencia, (i) + "")
    
                    }
                }
                setTimeout(() => {
                    this.props.clickAvanzar("documentos")
                }, 1000)
            } else {
                this.setState({ colorModal: this.state.colorErrorModal, tituloModal: 'Revisa la información' + " !", mensajeError: "Revisa que no tengas campos vacios ", showModal: true })
            }

        }





    }




    render() {
        return (
            <div className="container-fluid">
< Row>
                    <Col xs={6}>
                        <span style={{ font: "bold", color: "#FF4000" }}>Sol: {this.props.idSolicitud == null ? "0" : this.props.idSolicitud}<br />DXN:{this.state.idDXN} </span>

                    </Col>
                </Row>
                <Table striped bordered >
                    <thead >
                        <tr>
                            <th>No. Contrato</th>
                            <th>Casa financiera</th>
                            <th>{this.props.nuevaSolicitudServicio.tipo_producto_id == "2" ?"Importe o Línea de Crédito":"Referencia bancaria"}</th>
                            <th>Descuento</th>
                            <th>Monto a Liquidar</th>
                            <th>Opciones</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            this.state.items.map(function (x) {
                                return x;
                            })
                        }
                    </tbody>
                </Table>
                <div className="row">
                    <div className="col-lg-12 col-md-12 col-xs-12">
                        <Row>
                            <Col xs={5} lg={2}>
                                <img src={botonAgregar} onClick={this.agregarFila} style={{ width: '150px', height: "60px", borderRadius: '40px', marginRight: "10px" }} ></img>
                            </Col>
                        </Row>
                        <br></br>
                    </div>
                </div>
                <div className="row">





                    <div style={{ display: "flex" }}>
                        <img src={botonRegresar}
                            style={{ width: '150px', height: "60px", borderRadius: '40px', marginRight: "10px" }}
                            onClick={() => { this.props.clickAvanzar("referencias") }}
                            type="button"
                        >

                        </img>

                        <img src={botonAvanzar}
                            style={{ width: '150px', height: "60px", borderRadius: '40px', marginRight: "10px" }} disabled={this.props.banderaSolicitud}
                            onClick={ this.props.banderaSolicitud? ()=>{console.log("mkk")}:this.enviaReferencias}
                            type="button"
                        >

                        </img>
                    </div>
                </div>
                <Modal show={this.state.showModal} onHide={() => { this.setState({ showModal: false }) }} centered size="lg">
                    <Modal.Header style={{ backgroundColor: this.state.colorModal, textAlign: 'center' }} closeButton>
                        <Modal.Title style={{ textAlign: 'center' }}>{this.state.tituloModal}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body style={{ textAlign: 'center' }}>{this.state.mensajeError}</Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => { this.setState({ showModal: false }) }}>
                            Cerrar
                        </Button>

                    </Modal.Footer>
                </Modal>

                <Modal show={this.state.showModalBorrar} onHide={() => { this.setState({ showModalBorrar: false }) }} centered size="lg">
                    <Modal.Header style={{ backgroundColor: this.state.colorModal, textAlign: 'center' }} closeButton>
                        <Modal.Title style={{ textAlign: 'center' }}>{this.state.tituloModal}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body style={{ textAlign: 'center' }}>{this.state.mensajeError}</Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => { this.setState({ showModalBorrar: false }) }}>
                            Cerrar
                        </Button>

                        <Button variant="primary" onClick={() => { 
                            this.eliminarFila(this.state.idDocumentoAEliminar) 
                            this.setState({ showModalBorrar: false })
                            console.log("el id del dox  ",this.state.idDocumentoAEliminar)
                             }}>
                            Aceptar
                        </Button>
                    </Modal.Footer>
                </Modal>
            </div>




        )
    }



}
