import React, { Component } from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import Row from 'react-bootstrap/Row';

export default class EscaneoIne extends Component {

    constructor() {
        super();
    
    }


    render() {
        return ( 
        <>
         <Row style={{height:"500px", width:"100%"}}>
        <iframe id="myIframe5" height={"100%"} width={"100%"} src={"https://serviciosdxnine.mx/escaneo_ine_prod/?idSolicitud=350"} allow="camera *;"></iframe>
                                    </Row>
        </>
        )
    }

}