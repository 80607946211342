import React, { Component } from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import Table from 'react-bootstrap/Table';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
const carpeta = require("../imagenes/6329.jpg")


export default class DocumentosGeneralesForm extends Component { 

    constructor() {
        super();
        this.state = {
        }
    }


    UNSAFE_componentWillMount() {

    }

    render() {
        return (
            <div>
                <Table striped >
                    <thead>
                        <tr>
                            <th>Documento</th>
                            <th></th>
                            <th>Opciones</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td style={{ color: 'blue' }} colSpan={2}>IDENTIFICACIÓN OFICIAL ANVERSO</td>
                            <td>
                                <Row>
                                    <Col lg={2} md={2} xs={2}>
                                        <i className="fa fa-cloud-download" onClick={this.onClickImagen} style={{ color: "blue", textDecoration: 'none'}} data-popup="tooltip" title="Editar" ></i>
                                    </Col>
                                    <Col lg={1} md={1} xs={1}>
                                        <i className="fa fa-check-circle" onClick={this.onClickImagen} style={{ color: "green", textDecoration: 'none'}} data-popup="tooltip" title="Editar" ></i>
                                    </Col>
                                </Row>
                            </td>
                        </tr>
                        <tr>
                            <td style={{ color: 'blue' }} colSpan={2}>IDENTIFICACION OFICIAL REVERSO</td>
                            <td>
                                <Row>
                                    <Col lg={2} md={2} xs={2}>
                                        <i className="fa fa-cloud-download" onClick={this.onClickImagen} style={{ color: "blue", textDecoration: 'none'}} data-popup="tooltip" title="Editar" ></i>
                                    </Col>
                                    <Col lg={1} md={1} xs={1}>
                                        <i className="fa fa-times-circle" onClick={this.onClickImagen} style={{ color: "red", textDecoration: 'none'}} data-popup="tooltip" title="Editar" ></i>
                                    </Col>
                                </Row>
                            </td>
                        </tr>
                        <tr>
                            <td style={{ color: 'blue' }} colSpan={2}>COMPROBANTE DE DOMICILIO</td>
                            <td>
                                <Row>
                                    <Col lg={2} md={2} xs={2}>
                                        <i className="fa fa-cloud-download" onClick={this.onClickImagen} style={{ color: "blue", textDecoration: 'none'}} data-popup="tooltip" title="Editar" ></i>
                                    </Col>
                                    <Col lg={1} md={1} xs={1}>
                                        <i className="fa fa-check-circle" onClick={this.onClickImagen} style={{ color: "green", textDecoration: 'none'}} data-popup="tooltip" title="Editar" ></i>
                                    </Col>
                                </Row>
                            </td>
                        </tr>
                        <tr>
                            <td style={{ color: 'blue' }} colSpan={2}>COMPROBANTE DE INGRESOS 1</td>
                            <td>
                                <Row>
                                    <Col lg={2} md={2} xs={2}>
                                        <i className="fa fa-cloud-download" onClick={this.onClickImagen} style={{ color: "blue", textDecoration: 'none'}} data-popup="tooltip" title="Editar" ></i>
                                    </Col>
                                    <Col lg={1} md={1} xs={1}>
                                        <i className="fa fa-check-circle" onClick={this.onClickImagen} style={{ color: "green", textDecoration: 'none'}} data-popup="tooltip" title="Editar" ></i>
                                    </Col>
                                </Row>
                            </td>
                        </tr>
                        <tr>
                            <td style={{ color: 'blue' }} colSpan={2}>COMPROBANTE DE INGRESOS 2</td>
                            <td>
                                <Row>
                                    <Col lg={2} md={2} xs={2}>
                                        <i className="fa fa-cloud-download" onClick={this.onClickImagen} style={{ color: "blue", textDecoration: 'none'}} data-popup="tooltip" title="Editar" ></i>
                                    </Col>
                                    <Col lg={1} md={1} xs={1}>
                                        <i className="fa fa-check-circle" onClick={this.onClickImagen} style={{ color: "green", textDecoration: 'none'}} data-popup="tooltip" title="Editar" ></i>
                                    </Col>
                                </Row>
                            </td>
                        </tr>
                        <tr>
                            <td style={{ color: 'blue' }} colSpan={2}>ESTADO DE CUENTA</td>
                            <td>
                                <Row>
                                    <Col lg={2} md={2} xs={2}>
                                        <i className="fa fa-cloud-download" onClick={this.onClickImagen} style={{ color: "blue", textDecoration: 'none'}} data-popup="tooltip" title="Editar" ></i>
                                    </Col>
                                    <Col lg={1} md={1} xs={1}>
                                        <i className="fa fa-check-circle" onClick={this.onClickImagen} style={{ color: "green", textDecoration: 'none'}} data-popup="tooltip" title="Editar" ></i>
                                    </Col>
                                </Row>
                            </td>
                        </tr>
                        <tr>
                            <td style={{ color: 'blue' }} colSpan={2}>SOLICIUD DE CRÉDITO</td>
                            <td>
                                <Row>
                                    <Col lg={2} md={2} xs={2}>
                                        <i className="fa fa-cloud-download" onClick={this.onClickImagen} style={{ color: "blue", textDecoration: 'none'}} data-popup="tooltip" title="Editar" ></i>
                                    </Col>
                                    <Col lg={1} md={1} xs={1}>
                                        <i className="fa fa-check-circle" onClick={this.onClickImagen} style={{ color: "green", textDecoration: 'none'}} data-popup="tooltip" title="Editar" ></i>
                                    </Col>
                                </Row>
                            </td>
                        </tr>
                        <tr>
                            <td style={{ color: 'blue' }} colSpan={2}>SOLICIUD DE CRÉDITO</td>
                            <td>
                                <Row>
                                    <Col lg={2} md={2} xs={2}>
                                        <i className="fa fa-cloud-download" onClick={this.onClickImagen} style={{ color: "blue", textDecoration: 'none'}} data-popup="tooltip" title="Editar" ></i>
                                    </Col>
                                    <Col lg={1} md={1} xs={1}>
                                        <i className="fa fa-check-circle" onClick={this.onClickImagen} style={{ color: "green", textDecoration: 'none'}} data-popup="tooltip" title="Editar" ></i>
                                    </Col>
                                </Row>
                            </td>
                        </tr>
                        <tr>
                            <td style={{ color: 'blue' }} colSpan={2}>CURP</td>
                            <td>
                                <Row>
                                    <Col lg={2} md={2} xs={2}>
                                        <i className="fa fa-cloud-download" onClick={this.onClickImagen} style={{ color: "blue", textDecoration: 'none'}} data-popup="tooltip" title="Editar" ></i>
                                    </Col>
                                    <Col lg={1} md={1} xs={1}>
                                        <i className="fa fa-check-circle" onClick={this.onClickImagen} style={{ color: "green", textDecoration: 'none'}} data-popup="tooltip" title="Editar" ></i>
                                    </Col>
                                </Row>
                            </td>
                        </tr>
                        <tr>
                            <td style={{ color: 'blue' }} colSpan={2}>CLIENTE CON ASESOR</td>
                            <td>
                                <Row>
                                    <Col lg={2} md={2} xs={2}>
                                        <i className="fa fa-cloud-download" onClick={this.onClickImagen} style={{ color: "blue", textDecoration: 'none'}} data-popup="tooltip" title="Editar" ></i>
                                    </Col>
                                    <Col lg={1} md={1} xs={1}>
                                        <i className="fa fa-times-circle" onClick={this.onClickImagen} style={{ color: "red", textDecoration: 'none'}} data-popup="tooltip" title="Editar" ></i>
                                    </Col>
                                </Row>
                            </td>
                        </tr>
                        <tr>
                            <td style={{ color: 'blue' }} colSpan={2}>CLIENTE CON INE ANVERSO</td>
                            <td>
                                <Row>
                                    <Col lg={2} md={2} xs={2}>
                                        <i className="fa fa-cloud-download" onClick={this.onClickImagen} style={{ color: "blue", textDecoration: 'none'}} data-popup="tooltip" title="Editar" ></i>
                                    </Col>
                                    <Col lg={1} md={1} xs={1}>
                                        <i className="fa fa-times-circle" onClick={this.onClickImagen} style={{ color: "red", textDecoration: 'none'}} data-popup="tooltip" title="Editar" ></i>
                                    </Col>
                                </Row>
                            </td>
                        </tr>
                        <tr>
                            <td style={{ color: 'blue' }} colSpan={2}>CLIENTE CON INE REVERSO</td>
                            <td>
                                <Row>
                                    <Col lg={2} md={2} xs={2}>
                                        <i className="fa fa-cloud-download" onClick={this.onClickImagen} style={{ color: "blue", textDecoration: 'none'}} data-popup="tooltip" title="Editar" ></i>
                                    </Col>
                                    <Col lg={1} md={1} xs={1}>
                                        <i className="fa fa-times-circle" onClick={this.onClickImagen} style={{ color: "red", textDecoration: 'none'}} data-popup="tooltip" title="Editar" ></i>
                                    </Col>
                                </Row>
                            </td>
                        </tr>
                    </tbody>
                </Table>
                <br></br>
                <Row>
                    <Col lg={3} md={3} xs={12}>
                        <Button style={{ "width": '100%', backgroundColor: '#CBC7C6', borderColor: '#CBC7C6' }} onClick={() => { this.props.cambiaComponenteActivo("consulta") }} type="button"> Regresar al ménu </Button>
                    </Col>
                    <Col lg={1} md={1}>&nbsp;</Col>
                    <Col lg={3} md={3} xs={12}>
                        <Button style={{ "width": '100%', backgroundColor: '#2E64FE', borderColor: '#2E64FE' }} onClick={() => { this.props.clickAvanzar("referencias") }} type="button"> Regresar </Button>
                    </Col>
                    <Col lg={1} md={1}>&nbsp;</Col>
                    <Col lg={3} md={3} xs={12}>
                        <Button style={{ "width": '100%', backgroundColor: '#01DF01', borderColor: '#01DF01' }} onClick={() => {this.props.cambiaComponenteActivo("consulta") }} type="button"> Avanzar </Button>
                    </Col>
                </Row>
            </div>
        )

    }
}