import React, { Component } from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import Container from 'react-bootstrap/Container';
import Card from 'react-bootstrap/Card';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Nav from 'react-bootstrap/Nav';
import Button from 'react-bootstrap/Button';
import conexiones from '../urls/conexiones.json'
import ModalGenerica from "../modales/ModalGenerica";
import Modal from 'react-bootstrap/Modal';
import FastForwardIcon from '@mui/icons-material/FastForward';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import Accordion from 'react-bootstrap/Accordion';
import Table from 'react-bootstrap/Table';
import ThumbDownOffAltOutlinedIcon from '@mui/icons-material/ThumbDownOffAltOutlined';
import ThumbUpOutlinedIcon from '@mui/icons-material/ThumbUpOutlined';
import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "react-datepicker";
import SearchIcon from '@mui/icons-material/Search';
import procesar from '../imagenes/Boton_procesar_Solicitud.png'
import botonConsultaCapacidad from "../imagenes/botonConsultaCapacidad.png"
import botonGuardarSolicitud from "../imagenes/botonGuardarSolicitud.png"
import botonActulizar from "../imagenes/botonActulizar.png"
import PersonIcon from '@mui/icons-material/Person';
import LockIcon from '@mui/icons-material/Lock';
import PlaceIcon from '@mui/icons-material/Place';
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import MicIcon from '@mui/icons-material/Mic';
import RecentActorsIcon from '@mui/icons-material/RecentActors';
import MasksIcon from '@mui/icons-material/Masks';
import SignalWifiStatusbar4BarIcon from '@mui/icons-material/SignalWifiStatusbar4Bar';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
const iconX = require("../imagenes/iconX.PNG")
const IconoPersonSig = require("../imagenes/IconoPersonSig.jpg")
const IconoPersonaBloqueada = require("../imagenes/IconoPersonaBloqueada.jpg")
const permisos1 = require("../imagenes/permisos1.jpg")
const permisos2 = require("../imagenes/permisos2.jpg")
const huellas1 = require("../imagenes/huellas1.jpg")
const huellas2 = require("../imagenes/huellas2.jpg")

export default class CreaNuevaSolicitudForm extends Component {

  constructor() {
    super();
    this.codigo2Ref = React.createRef();
    this.codigo3Ref = React.createRef();
    this.codigo4Ref = React.createRef();

    this.getCatalogo = this.getCatalogo.bind(this)
    this.armaSelect = this.armaSelect.bind(this)
    this.procesaTuSolicitud = this.procesaTuSolicitud.bind(this)
    this.creaSolicitud = this.creaSolicitud.bind(this)
    this.actualizaSolicitud = this.actualizaSolicitud.bind(this)
    this.creaFormularioActuaPorTercero = this.creaFormularioActuaPorTercero.bind(this)
    this.consultaZellCreditoBase = this.consultaZellCreditoBase.bind(this)
    this.creaActuaTercero = this.creaActuaTercero.bind(this)
    this.consultaZellCreditoRefinanciamiento = this.consultaZellCreditoRefinanciamiento.bind(this)
    this.consultaZellCreditoRefinanciamiento = this.consultaZellCreditoRefinanciamiento.bind(this)
    this.aceptaRefinanciamiento = this.aceptaRefinanciamiento.bind(this)
    this.getDatosTercero = this.getDatosTercero.bind(this)
    this.actualizaActuaTercero = this.actualizaActuaTercero.bind(this)
    this.formatoLog = this.formatoLog.bind(this)
    this.consultaCapacidad = this.consultaCapacidad.bind(this)
    this.consultaCapacidad2 = this.consultaCapacidad2.bind(this)

    this.mascaraDecimales = this.mascaraDecimales.bind(this)
    this.onChangeValuesClabeReservar = this.onChangeValuesClabeReservar.bind(this)
    this.onChangeValuesTokenReservar = this.onChangeValuesTokenReservar.bind(this)
    this.onChangeSelectArchivoNomiPay = this.onChangeSelectArchivoNomiPay.bind(this)
    this.quitaEspeciales = this.quitaEspeciales.bind(this)
    this.getPlazo = this.getPlazo.bind(this)
    this.armaSelectPlazos = this.armaSelectPlazos.bind(this)
    this.consultaDatosCompletosZELLporRFC = this.consultaDatosCompletosZELLporRFC.bind(this)
    this.setRFCEncontradoNuestrasBases = this.setRFCEncontradoNuestrasBases.bind(this)
    this.setNullsASolicitud = this.setNullsASolicitud.bind(this)
    this.consultaMontoSolicitadoDescuentoYTasaPorPlazo = this.consultaMontoSolicitadoDescuentoYTasaPorPlazo.bind(this)
    this.quitarEspacioAlFinal = this.quitarEspacioAlFinal.bind(this)
    this.consultaDatosCompletosZellPorRFCValidaRefinanciamiento = this.consultaDatosCompletosZellPorRFCValidaRefinanciamiento.bind(this)
    this.consultaCreditoMorosidad = this.consultaCreditoMorosidad.bind(this)
    this.getLeyendasBiometricos = this.getLeyendasBiometricos.bind(this)
    this.obtenCodigoDeVerificacion = this.obtenCodigoDeVerificacion.bind(this)
    this.validaVerificacion = this.validaVerificacion.bind(this)
    this.state = {
      datosRFCOtraSolicitud: {},
      idBDSolicitud: '0',
      idDXN: '0',
      nombreArchivoNomiPay: "",
      convenioTieneToken: false,
      showModalNomiPay: false,
      tipoCalculoIdPortal: 0,
      datosBanderaBiometricos: false,
      plazoTexto: null,
      idSolicitudBiometricos: null,
      showModalBiometricosIframe: false,
      showModalPermisos: false,
      expresionEmail: /^\w+([.-_+]?\w+)*@\w+([.-]?\w+)*(\.\w{2,10})+$/,
      datosModalesBiometricos: {
        aceptoTerminos1: false,
        aceptoAviso1: false,
        aceptoVerificacionCrediticia: false,
        banderaEnvioCodigo: false,
        emailCodigo: "",
        validaEmail: true,
        codigoVerificacionCampos: "",
        codigoVerificacionServicio: "",
        codigo1: "",
        codigo2: "",
        codigo3: "",
        codigo4: "",
      },
      avisoPrivacidadDXN: "",
      terminosVerificacionCrediticia: "",
      terminosVerificacionCrediticia2: "",
      consentimientoINE: "",
      showModalConsentimientoINE: false,
      showModalBiometricos: false,
      showModalVerificacionCrediticia: false,
      showModalVerificacionCrediticia2: false,
      activar_biometricos: false,
      activar_portal: false,
      activar_folioIMSS: false,
      banderaSolicitudProcesada: false,
      banderaCurp: false,
      banderaRFC: false,
      banderaSector: false,
      banderaConvenio: false,
      colorErrorModal: '#F83C3C',
      colorSuccesModal: '#27F45E',
      colorInformativo: "#2749f4",
      colorModal: '',
      tituloModal: '',
      mensajeError: '',
      showAvanzar: false,
      showModal: false,
      showModalSaldo: false,
      showModalRefiFlash: false,
      showModalReservar: false,
      showModalInfoCapturaHuellas:false,
      banderaBotonReservar: false,

      banderaBotonConsultaCapYCapPago: false,
      //   expresionRFC: /^([A-ZÑ&]{3,4}) ?(?:- ?)?(\d{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[12]\d|3[01])) ?(?:- ?)?([A-Z\d]{2})([A\d])$/,
      //    expresionCURP: /^([A-Z][AEIOUX][A-Z]{2}\d{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[12]\d|3[01])[HM](?:AS|B[CS]|C[CLMSH]|D[FG]|G[TR]|HG|JC|M[CNS]|N[ETL]|OC|PL|Q[TR]|S[PLR]|T[CSL]|VZ|YN|ZS)[B-DF-HJ-NP-TV-Z]{3}[A-Z\d])(\d)$/,

      expresionRFC: /^([A-ZÑ&]{3,4}) ?(?:- ?)?(\d{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[12]\d|3[01])) ?(?:- ?)?([A-Z\d]{2})([A-Z\d])$/,
      expresionCURP: /^([A-Z][A-Z][A-Z]{2}\d{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[12]\d|3[01])[HM](?:AS|B[CS]|C[CLMSH]|D[FG]|G[TR]|HG|JC|M[CNS]|N[ETL]|OC|PL|Q[TR]|S[PLR]|T[CSL]|VZ|YN|ZS)[B-DF-HJ-NP-TV-Z]{3}[A-Z\d])(\d)$/,
      selectSector: [],
      selectProducto: [],
      selectSucursal: [],
      selectDestinoCredito: [],
      selectActuaPor: [],
      selectTipoProducto: [],
      selectPeriodo: [],
      selectPlazo: [],
      aceptoRefinanciamiento: false,
      /*valores del form de tercero */
      selectAFavor: [],
      selectGeneroTercero: [],
      selectEdoCivilTercero: [],
      tresMeses: 1000 * 60 * 60 * 24 * 91,
      mas1Dia: 1000 * 60 * 60 * 24 * 1,
      /*valores del form de tercero */
      opcionesRefServicio: [],
      modalRefiFlash: {
        credito: null,
        plazo: null,
        montoDeudor: null,
        depositoAlCliente: null,
        RefinanceId: null,
        monto: null,
        descuento: null,
        opcionesRef: [],
        opcionSeleccionada: null,
        plazoID: null,
        tipoProdID: null,
        periodoID: null
      },
      modalSaldoVencido: {
        nombreCliente: null,
        creditoID: null,
        saldoPendiente: null,
        total: null,
        referencia: null
      },


      validaciones: {
        validaCURP: false,
        validaRFC: false,
        validaSector: false,
        validaProducto: false,
        validaFolioIMSS: false,
        validafechaIngreso: false,
        validaActuaPor: false,
        validaTipoProducto: false,
        validaPeriodo: false,
        validaCapPago: false,
        validaMontoDeudor: false,
        validaDepoCliente: false,
        validaPlazo: false,
        validaDestinoCredito: false,
        validaMontoSolicitado: false,
        validaSucursal: false
      },
      datosTercero: {
        id: null,
        aFavor: null,
        nombre: null,
        primerApellido: null,
        segundoApellido: null,
        genero: null,
        curp: null,
        rfc: null,
        telefono: null,
        email: null,
        celular: null,
        fechaNacimiento: null,
        lugarNacimiento: null,
        nacionalidad: null,
        edoCivil: null,
        primerNombreConyuge: null,
        segundoNombreConyuge: null,
        primerApellidoConyuge: null,
        segundoApellidoConyuge: null,
        ocupacionConyuge: null,
        calle: null,
        colonia: null,
        municipio: null,
        cp: null,
        ciudad: null,
        estado: null,
        pais: null,
        entreCalles: null
      },
      nuevaSolicitud: {
        curp: null,
        rfc: null,
        sector: null,
        producto: null,
        fechaIngreso: null,
        actuaPor: "1",
        tipoProducto: null,
        periodo: null,
        plazo: null,
        capPago: null,
        destinoCredito: null,
        montoSolicitado: null,
        descuento: null,
        tasaAnual: null,
        sucursal: null,
        monto_deudor: null,
        deposito_cliente: null,
        folio_imss: null,
        appid: null
        //montoDeudorSol: null,
        //depositoAlClienteSol: null

      },
      folio: '',
      folioRefinanciamiento: '',
      producto_id_en_zell: '',
      cuentaClabeReservar: '',
      tokenReservar: '',
      existenDatosPersonales: false,
      esMoroso: false,
      formattedDate: "",
      idAsesorSeleccionado: null,
    }
  }


  mascaraDecimales(valor) {
    return valor.replace(/\D/g, "")
      .replace(/([0-9])([0-9]{2})$/, '$1.$2')
      .replace(/\B(?=(\d{3})+(?!\d)\.?)/g, ",");
  }


  onChangeValuesTercero = e => {
    console.log(e.target.name, e.target.value)
    let datosTercero = this.state.datosTercero

    if (e.target.name == "selectAFavor") {
      datosTercero["aFavor"] = e.target.value
    } else if (e.target.name == "selectGeneroTercero") {
      datosTercero["genero"] = e.target.value

    } else if (e.target.name == "selectEdoCivilTercero") {
      datosTercero["edoCivil"] = e.target.value

    } else {
      datosTercero[e.target.name] = e.target.value
    }

    this.setState({ datosTercero: datosTercero })
  }

  creaFormularioActuaPorTercero() {
    let forma = <div className="container-fluid">
      <div className="row">
        <div className="col-lg-12 col-md-12 col-xs-12">
          <Accordion defaultActiveKey="0">
            <Accordion.Item eventKey="0">
              <Accordion.Header>Datos del tercero</Accordion.Header>
              <Accordion.Body>
                <Row>
                  <Col lg={3} md={3} xs={12}>
                    <Form.Group className="mb-6" controlId="">
                      <Form.Label>A favor de quien: <span style={{ color: 'red' }}>*</span></Form.Label>
                      {this.state.selectAFavor}

                      {/*this.state.nuevaSolicitud.curp == null || this.state.validaciones.validaCURP == false ? (
              <Form.Label><span style={{ color: 'red' }}>El campo debe cumplir con la validación del CURP</span></Form.Label>
            ) : null*/}
                    </Form.Group>
                  </Col>
                  <Col lg={3} md={3} xs={12}>
                    <Form.Group className="mb-6" controlId="">
                      <Form.Label>Nombre(s) de tercero: <span style={{ color: 'red' }}>*</span></Form.Label>
                      <Form.Control disabled={this.props.banderaSolicitud} type="text" placeholder="" value={this.state.datosTercero.nombre} name="nombre" onChange={this.onChangeValuesTercero} />
                      {/*this.state.nuevaSolicitud.curp == null || this.state.validaciones.validaCURP == false ? (
              <Form.Label><span style={{ color: 'red' }}>El campo debe cumplir con la validación del CURP</span></Form.Label>
            ) : null*/}
                    </Form.Group>
                  </Col>
                  <Col lg={3} md={3} xs={12}>
                    <Form.Group className="mb-6" controlId="">
                      <Form.Label>Primer apellido de tercero: <span style={{ color: 'red' }}>*</span></Form.Label>
                      <Form.Control disabled={this.props.banderaSolicitud} type="text" placeholder="" value={this.state.datosTercero.primerApellido} name="primerApellido" onChange={this.onChangeValuesTercero} />
                      {/*this.state.nuevaSolicitud.curp == null || this.state.validaciones.validaCURP == false ? (
              <Form.Label><span style={{ color: 'red' }}>El campo debe cumplir con la validación del CURP</span></Form.Label>
            ) : null*/}
                    </Form.Group>
                  </Col>
                  <Col lg={3} md={3} xs={12}>
                    <Form.Group className="mb-6" controlId="">
                      <Form.Label>Segundo apellido de tercero: <span style={{ color: 'red' }}>*</span></Form.Label>
                      <Form.Control disabled={this.props.banderaSolicitud} type="text" placeholder="" value={this.state.datosTercero.segundoApellido} name="segundoApellido" onChange={this.onChangeValuesTercero} />
                      {/*this.state.nuevaSolicitud.curp == null || this.state.validaciones.validaCURP == false ? (
              <Form.Label><span style={{ color: 'red' }}>El campo debe cumplir con la validación del CURP</span></Form.Label>
            ) : null*/}
                    </Form.Group>
                  </Col>
                </Row>

                <br></br>


                {/* otra fila    */}

                <Row>
                  <Col lg={3} md={3} xs={12}>
                    <Form.Group className="mb-6" controlId="">
                      <Form.Label>Género de tercero: <span style={{ color: 'red' }}>*</span></Form.Label>
                      {this.state.selectGeneroTercero}
                      {/*this.state.nuevaSolicitud.curp == null || this.state.validaciones.validaCURP == false ? (
              <Form.Label><span style={{ color: 'red' }}>El campo debe cumplir con la validación del CURP</span></Form.Label>
            ) : null*/}
                    </Form.Group>
                  </Col>
                  <Col lg={3} md={3} xs={12}>
                    <Form.Group className="mb-6" controlId="">
                      <Form.Label>CURP tercero: <span style={{ color: 'red' }}>*</span></Form.Label>
                      <Form.Control disabled={this.props.banderaSolicitud} type="text" placeholder="" value={this.state.datosTercero.curp} name="curp" onChange={this.onChangeValuesTercero} />
                      {/*this.state.nuevaSolicitud.curp == null || this.state.validaciones.validaCURP == false ? (
              <Form.Label><span style={{ color: 'red' }}>El campo debe cumplir con la validación del CURP</span></Form.Label>
            ) : null*/}
                    </Form.Group>
                  </Col>
                  <Col lg={3} md={3} xs={12}>
                    <Form.Group className="mb-6" controlId="">
                      <Form.Label>RFC tercero: <span style={{ color: 'red' }}>*</span></Form.Label>
                      <Form.Control disabled={this.props.banderaSolicitud} type="text" placeholder="" value={this.state.datosTercero.rfc} name="rfc" onChange={this.onChangeValuesTercero} />
                      {/*this.state.nuevaSolicitud.curp == null || this.state.validaciones.validaCURP == false ? (
              <Form.Label><span style={{ color: 'red' }}>El campo debe cumplir con la validación del CURP</span></Form.Label>
            ) : null*/}
                    </Form.Group>
                  </Col>
                  <Col lg={3} md={3} xs={12}>
                    <Form.Group className="mb-6" controlId="">
                      <Form.Label>Teléfono tercero: <span style={{ color: 'red' }}>*</span></Form.Label>
                      <Form.Control disabled={this.props.banderaSolicitud} type="text" placeholder="" value={this.state.datosTercero.telefono} name="telefono" onChange={this.onChangeValuesTercero} />
                      {/*this.state.nuevaSolicitud.curp == null || this.state.validaciones.validaCURP == false ? (
              <Form.Label><span style={{ color: 'red' }}>El campo debe cumplir con la validación del CURP</span></Form.Label>
            ) : null*/}
                    </Form.Group>
                  </Col>
                </Row>
                <br></br>
                {/* otra fila    */}


                <Row>
                  <Col lg={3} md={3} xs={12}>
                    <Form.Group className="mb-6" controlId="">
                      <Form.Label>Email tercero:<span style={{ color: 'red' }}>*</span></Form.Label>
                      <Form.Control disabled={this.props.banderaSolicitud} type="text" placeholder="" value={this.state.datosTercero.email} name="email" onChange={this.onChangeValuesTercero} />
                      {/*this.state.nuevaSolicitud.curp == null || this.state.validaciones.validaCURP == false ? (
              <Form.Label><span style={{ color: 'red' }}>El campo debe cumplir con la validación del CURP</span></Form.Label>
            ) : null*/}
                    </Form.Group>
                  </Col>
                  <Col lg={3} md={3} xs={12}>
                    <Form.Group className="mb-6" controlId="">
                      <Form.Label>Celular tercero(10 dígitos):<span style={{ color: 'red' }}>*</span></Form.Label>
                      <Form.Control disabled={this.props.banderaSolicitud} type="text" placeholder="" value={this.state.datosTercero.celular} name="celular" onChange={this.onChangeValuesTercero} />
                      {/*this.state.nuevaSolicitud.curp == null || this.state.validaciones.validaCURP == false ? (
              <Form.Label><span style={{ color: 'red' }}>El campo debe cumplir con la validación del CURP</span></Form.Label>
            ) : null*/}
                    </Form.Group>
                  </Col>
                  <Col lg={3} md={3} xs={12}>
                    <Form.Group className="mb-6" controlId="">
                      <Form.Label>Fecha de nacimiento tercero: <span style={{ color: 'red' }}>*</span></Form.Label>
                      <Form.Control disabled={this.props.banderaSolicitud} type="date" placeholder="" value={this.state.datosTercero.fechaNacimiento} name="fechaNacimiento" onChange={this.onChangeValuesTercero} />
                      {/*this.state.nuevaSolicitud.curp == null || this.state.validaciones.validaCURP == false ? (
              <Form.Label><span style={{ color: 'red' }}>El campo debe cumplir con la validación del CURP</span></Form.Label>
            ) : null*/}
                    </Form.Group>
                  </Col>
                  <Col lg={3} md={3} xs={12}>
                    <Form.Group className="mb-6" controlId="">
                      <Form.Label>Lugar de nacimiento tercero: <span style={{ color: 'red' }}>*</span></Form.Label>
                      <Form.Control disabled={this.props.banderaSolicitud} type="text" placeholder="" value={this.state.datosTercero.lugarNacimiento} name="lugarNacimiento" onChange={this.onChangeValuesTercero} />
                      {/*this.state.nuevaSolicitud.curp == null || this.state.validaciones.validaCURP == false ? (
              <Form.Label><span style={{ color: 'red' }}>El campo debe cumplir con la validación del CURP</span></Form.Label>
            ) : null*/}
                    </Form.Group>
                  </Col>
                </Row>
                <br></br>
                {/* otra fila    */}


                <Row>
                  <Col lg={3} md={3} xs={12}>
                    <Form.Group className="mb-6" controlId="">
                      <Form.Label>Nacionalidad tercero:<span style={{ color: 'red' }}>*</span></Form.Label>
                      <Form.Control disabled={this.props.banderaSolicitud} type="text" placeholder="" value={this.state.datosTercero.nacionalidad} name="nacionalidad" onChange={this.onChangeValuesTercero} />
                      {/*this.state.nuevaSolicitud.curp == null || this.state.validaciones.validaCURP == false ? (
              <Form.Label><span style={{ color: 'red' }}>El campo debe cumplir con la validación del CURP</span></Form.Label>
            ) : null*/}
                    </Form.Group>
                  </Col>
                  <Col lg={3} md={3} xs={12}>
                    <Form.Group className="mb-6" controlId="">
                      <Form.Label>Estado civil tercero:<span style={{ color: 'red' }}>*</span></Form.Label>
                      {this.state.selectEdoCivilTercero}
                      {/*this.state.nuevaSolicitud.curp == null || this.state.validaciones.validaCURP == false ? (
              <Form.Label><span style={{ color: 'red' }}>El campo debe cumplir con la validación del CURP</span></Form.Label>
            ) : null*/}
                    </Form.Group>
                  </Col>
                  <Col lg={3} md={3} xs={12}>
                    <Form.Group className="mb-6" controlId="">
                      <Form.Label>Primer Nombre (Conyugue):<span style={{ color: 'red' }}>*</span></Form.Label>
                      <Form.Control disabled={this.props.banderaSolicitud} type="text" placeholder="" value={this.state.datosTercero.primerNombreConyuge} name="primerNombreConyuge" onChange={this.onChangeValuesTercero} />
                      {/*this.state.nuevaSolicitud.curp == null || this.state.validaciones.validaCURP == false ? (
              <Form.Label><span style={{ color: 'red' }}>El campo debe cumplir con la validación del CURP</span></Form.Label>
            ) : null*/}
                    </Form.Group>
                  </Col>
                  <Col lg={3} md={3} xs={12}>
                    <Form.Group className="mb-6" controlId="">
                      <Form.Label>Segundo Nombre (Conyugue): <span style={{ color: 'red' }}>*</span></Form.Label>
                      <Form.Control disabled={this.props.banderaSolicitud} type="text" placeholder="" value={this.state.datosTercero.segundoNombreConyuge} name="segundoNombreConyuge" onChange={this.onChangeValuesTercero} />
                      {/*this.state.nuevaSolicitud.curp == null || this.state.validaciones.validaCURP == false ? (
              <Form.Label><span style={{ color: 'red' }}>El campo debe cumplir con la validación del CURP</span></Form.Label>
            ) : null*/}
                    </Form.Group>
                  </Col>
                </Row>
                <br></br>

                {/* otra fila    */}
                <Row>
                  <Col lg={3} md={3} xs={12}>
                    <Form.Group className="mb-6" controlId="">
                      <Form.Label>Primer apellido (Conyugue):<span style={{ color: 'red' }}>*</span></Form.Label>
                      <Form.Control disabled={this.props.banderaSolicitud} type="text" placeholder="" value={this.state.datosTercero.primerApellidoConyuge} name="primerApellidoConyuge" onChange={this.onChangeValuesTercero} />
                      {/*this.state.nuevaSolicitud.curp == null || this.state.validaciones.validaCURP == false ? (
              <Form.Label><span style={{ color: 'red' }}>El campo debe cumplir con la validación del CURP</span></Form.Label>
            ) : null*/}
                    </Form.Group>
                  </Col>
                  <Col lg={3} md={3} xs={12}>
                    <Form.Group className="mb-6" controlId="">
                      <Form.Label>Segundo apellido (Conyugue):<span style={{ color: 'red' }}>*</span></Form.Label>
                      <Form.Control type="text" placeholder="" value={this.state.datosTercero.segundoApellidoConyuge} name="segundoApellidoConyuge" onChange={this.onChangeValuesTercero} />
                      {/*this.state.nuevaSolicitud.curp == null || this.state.validaciones.validaCURP == false ? (
              <Form.Label><span style={{ color: 'red' }}>El campo debe cumplir con la validación del CURP</span></Form.Label>
            ) : null*/}
                    </Form.Group>
                  </Col>
                  <Col lg={3} md={3} xs={12}>
                    <Form.Group className="mb-6" controlId="">
                      <Form.Label>Ocupacion (Conyugue):<span style={{ color: 'red' }}>*</span></Form.Label>
                      <Form.Control disabled={this.props.banderaSolicitud} type="text" placeholder="" value={this.state.datosTercero.ocupacionConyuge} name="ocupacionConyuge" onChange={this.onChangeValuesTercero} />
                      {/*this.state.nuevaSolicitud.curp == null || this.state.validaciones.validaCURP == false ? (
              <Form.Label><span style={{ color: 'red' }}>El campo debe cumplir con la validación del CURP</span></Form.Label>
            ) : null*/}
                    </Form.Group>
                  </Col>
                </Row>

                <br></br>
                {/* otra fila    */}


                <Row>
                  <Col lg={12} md={12} xs={12}>
                    <Form.Group className="mb-6" controlId="">
                      <Form.Label>Calle y numero tercero:<span style={{ color: 'red' }}>*</span></Form.Label>
                      <Form.Control disabled={this.props.banderaSolicitud} type="text" placeholder="" value={this.state.datosTercero.calle} name="calle" onChange={this.onChangeValuesTercero} />
                      {/*this.state.nuevaSolicitud.curp == null || this.state.validaciones.validaCURP == false ? (
                      <Form.Label><span style={{ color: 'red' }}>El campo debe cumplir con la validación del CURP</span></Form.Label>
                      ) : null*/}
                    </Form.Group>
                  </Col>
                </Row>

                <br></br>
                {/* otra fila */}
                <Row>
                  <Col lg={6} md={6} xs={12}>
                    <Form.Group className="mb-6" controlId="">
                      <Form.Label>Colonia tercero:<span style={{ color: 'red' }}>*</span></Form.Label>
                      <Form.Control disabled={this.props.banderaSolicitud} type="text" placeholder="" value={this.state.datosTercero.colonia} name="colonia" onChange={this.onChangeValuesTercero} />
                      {/*this.state.nuevaSolicitud.curp == null || this.state.validaciones.validaCURP == false ? (
                      <Form.Label><span style={{ color: 'red' }}>El campo debe cumplir con la validación del CURP</span></Form.Label>
                      ) : null*/}
                    </Form.Group>
                  </Col>
                  <Col lg={6} md={6} xs={12}>
                    <Form.Group className="mb-6" controlId="">
                      <Form.Label>Municipio / Alcaldía tercero:<span style={{ color: 'red' }}>*</span></Form.Label>
                      <Form.Control disabled={this.props.banderaSolicitud} type="text" placeholder="" value={this.state.datosTercero.municipio} name="municipio" onChange={this.onChangeValuesTercero} />
                      {/*this.state.nuevaSolicitud.curp == null || this.state.validaciones.validaCURP == false ? (
                      <Form.Label><span style={{ color: 'red' }}>El campo debe cumplir con la validación del CURP</span></Form.Label>
                      ) : null*/}
                    </Form.Group>
                  </Col>
                </Row>
                <br></br>
                {/* otra fila */}
                <Row>
                  <Col lg={3} md={3} xs={12}>
                    <Form.Group className="mb-6" controlId="">
                      <Form.Label>CP tercero:<span style={{ color: 'red' }}>*</span></Form.Label>
                      <Form.Control disabled={this.props.banderaSolicitud} type="text" placeholder="" value={this.state.datosTercero.cp} name="cp" onChange={this.onChangeValuesTercero} />
                      {/*this.state.nuevaSolicitud.curp == null || this.state.validaciones.validaCURP == false ? (
              <Form.Label><span style={{ color: 'red' }}>El campo debe cumplir con la validación del CURP</span></Form.Label>
            ) : null*/}
                    </Form.Group>
                  </Col>
                  <Col lg={3} md={3} xs={12}>
                    <Form.Group className="mb-6" controlId="">
                      <Form.Label>Ciudad tercero:<span style={{ color: 'red' }}>*</span></Form.Label>
                      <Form.Control disabled={this.props.banderaSolicitud} type="text" placeholder="" value={this.state.datosTercero.ciudad} name="ciudad" onChange={this.onChangeValuesTercero} />
                      {/*this.state.nuevaSolicitud.curp == null || this.state.validaciones.validaCURP == false ? (
              <Form.Label><span style={{ color: 'red' }}>El campo debe cumplir con la validación del CURP</span></Form.Label>
            ) : null*/}
                    </Form.Group>
                  </Col>
                  <Col lg={3} md={3} xs={12}>
                    <Form.Group className="mb-6" controlId="">
                      <Form.Label>Estado tercero:<span style={{ color: 'red' }}>*</span></Form.Label>
                      <Form.Control disabled={this.props.banderaSolicitud} type="text" placeholder="" value={this.state.datosTercero.estado} name="estado" onChange={this.onChangeValuesTercero} />
                      {/*this.state.nuevaSolicitud.curp == null || this.state.validaciones.validaCURP == false ? (
              <Form.Label><span style={{ color: 'red' }}>El campo debe cumplir con la validación del CURP</span></Form.Label>
            ) : null*/}
                    </Form.Group>
                  </Col>
                  <Col lg={3} md={3} xs={12}>
                    <Form.Group className="mb-6" controlId="">
                      <Form.Label>Pais tercero:<span style={{ color: 'red' }}>*</span></Form.Label>
                      <Form.Control disabled={this.props.banderaSolicitud} type="text" placeholder="" value={this.state.datosTercero.pais} name="pais" onChange={this.onChangeValuesTercero} />
                      {/*this.state.nuevaSolicitud.curp == null || this.state.validaciones.validaCURP == false ? (
              <Form.Label><span style={{ color: 'red' }}>El campo debe cumplir con la validación del CURP</span></Form.Label>
            ) : null*/}
                    </Form.Group>
                  </Col>
                </Row>
                <br></br>
                {/* otra fila  */}
                <Row>
                  <Col lg={3} md={3} xs={12}>
                    <Form.Group className="mb-6" controlId="">
                      <Form.Label>Entre calles tercero:<span style={{ color: 'red' }}>*</span></Form.Label>
                      <Form.Control disabled={this.props.banderaSolicitud} type="text" placeholder="" value={this.state.datosTercero.entreCalles} name="entreCalles" onChange={this.onChangeValuesTercero} />
                      {/*this.state.nuevaSolicitud.curp == null || this.state.validaciones.validaCURP == false ? (
                      <Form.Label><span style={{ color: 'red' }}>El campo debe cumplir con la validación del CURP</span></Form.Label>
                      ) : null*/}
                    </Form.Group>
                  </Col>

                </Row>



              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </div>
      </div>
    </div>



    return forma;
  }

  esLetra(caracter) {

    let bandera = false
    if (caracter != undefined) {
      let ascii = caracter.toUpperCase().charCodeAt(0);
      console.log("el caracter es ------ ", ascii)
      bandera = ascii > 64 && ascii < 91
    }
    return bandera;
  }

  esNumero(caracter) {
    console.log("el caracter es ------ ", caracter)
    let bandera = false
    if (caracter != undefined) {
      if (caracter == '0' || caracter == '1' || caracter == '2' || caracter == '3' || caracter == '4' || caracter == '5' || caracter == '6' || caracter == '7' || caracter == '8' || caracter == '9') {
        bandera = true
      }
    }
    return bandera;

  }

  onChangeValuesFolioDXN = e => {
    let curp = e.target.value
    let ultimoCaracter = curp.split('')[curp.length - 1] // 'd'
    if (ultimoCaracter != undefined) {
      if (this.esNumero(ultimoCaracter)) {
        this.setState({ folioRefinanciamiento: e.target.value })
        if (curp.length == 6) {
          setTimeout(() => { this.consultaDatosCompletosZellPorRFCValidaRefinanciamiento() }, 500)
        }
      }
    } else {
      this.setState({ folioRefinanciamiento: "" })
    }
  }



  onChangeValues = e => {
    console.log(e.target.name, e.target.value)
    let validaciones = this.state.validaciones
    let nuevaSolicitud = this.state.nuevaSolicitud

    if (e.target.name == "curp") {
      let curp = e.target.value;
      if (curp.length <= 4) {
        let ultimoCaracter = curp.split('')[curp.length - 1] // 'd'
        if (ultimoCaracter != undefined) {


          if (this.esLetra(ultimoCaracter)  /*&& ultimoCaracter != '0' && ultimoCaracter != '1' && ultimoCaracter != '2' && ultimoCaracter != '3' && ultimoCaracter != '4' && ultimoCaracter != '5' && ultimoCaracter != '6' && ultimoCaracter != '7' && ultimoCaracter != '8' && ultimoCaracter != '9'*/) {
            console.log("curp entrando  ", ultimoCaracter)
            nuevaSolicitud[[e.target.name]] = e.target.value.toUpperCase()
          } else {
            nuevaSolicitud[[e.target.name]] = ""
          }

        } else {
          nuevaSolicitud[[e.target.name]] = ""
        }
        this.setState({ banderaCurp: false })

      } else if (curp.length > 4 && curp.length <= 10) {
        let ultimoCaracter = curp.split('')[curp.length - 1] // 'd'
        if (ultimoCaracter != undefined) {
          if (this.esNumero(ultimoCaracter)) {
            nuevaSolicitud[[e.target.name]] = e.target.value.toUpperCase()
          }
        }
        this.setState({ banderaCurp: false })
      } else if (curp.length > 10 && curp.length <= 16) {
        let ultimoCaracter = curp.split('')[curp.length - 1] // 'd'
        if (ultimoCaracter != undefined) {
          if (this.esLetra(ultimoCaracter)/*ultimoCaracter != '0' && ultimoCaracter != '1' && ultimoCaracter != '2' && ultimoCaracter != '3' && ultimoCaracter != '4' && ultimoCaracter != '5' && ultimoCaracter != '6' && ultimoCaracter != '7' && ultimoCaracter != '8' && ultimoCaracter != '9'*/) {
            nuevaSolicitud[[e.target.name]] = e.target.value.toUpperCase()
          }
        }
        this.setState({ banderaCurp: false })
      } else if (curp.length > 16 && curp.length <= 18) {
        let ultimoCaracter = curp.split('')[curp.length - 1] // 'd'
        if (this.esLetra(ultimoCaracter) || this.esNumero(ultimoCaracter)) {
          nuevaSolicitud[[e.target.name]] = e.target.value.toUpperCase()

        }
        this.setState({ banderaCurp: false })
        if (curp.length == 18) {
          this.setState({ banderaCurp: true })
        }
      }
      if (curp.length == 18) {
        let val = this.state.validaciones
        val.validaCURP = false
        this.setState({ banderaCurp: true, validaciones: validaciones })
      }

      /* if(curp.length == 18){
         if(curp.match(this.state.expresionCURP)) {
           validaciones.validaCURP = false
           this.setState({ validaciones: validaciones })
         } else {
           validaciones.validaCURP = false
           this.setState({ validaciones: validaciones })
         }
       }*/

      /*if (curp.match(this.state.expresionCURP)) {

        validaciones.validaCURP = false
        this.setState({ validaciones: validaciones })
      } else {
        let longitud = curp + "";
        if (longitud.length < 18) {
          validaciones.validaCURP = true
          this.setState({ validaciones: validaciones })
        } else {
          validaciones.validaCURP = false
          this.setState({ validaciones: validaciones })
        }
      }
      let longitud = curp + "";
      if (longitud.length <= 18) {
        nuevaSolicitud[[e.target.name]] = e.target.value.toUpperCase()
      }*/



    }


    if (e.target.name == "rfc") {
      let curp = e.target.value;
      if (curp.length <= 4) {
        let ultimoCaracter = curp.split('')[curp.length - 1] // 'd'
        if (ultimoCaracter != undefined) {
          if (this.esLetra(ultimoCaracter)/*ultimoCaracter != '0' && ultimoCaracter != '1' && ultimoCaracter != '2' && ultimoCaracter != '3' && ultimoCaracter != '4' && ultimoCaracter != '5' && ultimoCaracter != '6' && ultimoCaracter != '7' && ultimoCaracter != '8' && ultimoCaracter != '9'*/) {
            nuevaSolicitud[[e.target.name]] = e.target.value.toUpperCase()
          } else {
            nuevaSolicitud[[e.target.name]] = ""
          }
        } else {
          nuevaSolicitud[[e.target.name]] = ""
        }
        this.setState({ banderaRFC: false })

      } else if (curp.length > 4 && curp.length < 10) {
        let ultimoCaracter = curp.split('')[curp.length - 1] // 'd'
        if (ultimoCaracter != undefined) {

          if (this.esNumero(ultimoCaracter)) {
            nuevaSolicitud[[e.target.name]] = e.target.value.toUpperCase()
          }
        }
        this.setState({ banderaRFC: false })
      } else if (curp.length == 10) {
        let ultimoCaracter = curp.split('')[curp.length - 1] // 'd'
        if (ultimoCaracter != undefined) {
          console.log("entrando en el 10")
          if (this.esNumero(ultimoCaracter) || this.esLetra(ultimoCaracter)) {
            nuevaSolicitud[[e.target.name]] = e.target.value.toUpperCase()
          }
        }
        this.setState({ banderaRFC: false })
      } else if (curp.length > 10 && curp.length <= 11) {
        let ultimoCaracter = curp.split('')[curp.length - 1] // 'd'
        if (ultimoCaracter != undefined) {
          if (this.esNumero(ultimoCaracter) || this.esLetra(ultimoCaracter)) {
            nuevaSolicitud[[e.target.name]] = e.target.value.toUpperCase()
          }
        }
        this.setState({ banderaRFC: false })
      } else if (curp.length > 11 && curp.length <= 13) {
        let ultimoCaracter = curp.split('')[curp.length - 1] // 'd'
        if (ultimoCaracter != undefined) {
          if (this.esNumero(ultimoCaracter) || this.esLetra(ultimoCaracter)) {
            nuevaSolicitud[[e.target.name]] = e.target.value.toUpperCase()
          }
        }
        this.setState({ banderaRFC: false })
        if (curp.length == 13) {
          this.setState({ banderaRFC: true })
        }
      }

      if (curp.length == 13) {
        let val = this.state.validaciones
        val.validaRFC = false
        this.setState({ banderaRFC: true, validaciones: validaciones })
      }



      /* if (rfc.match(this.state.expresionRFC)) {
        validaciones.validaRFC = false
        this.setState({ validaciones: validaciones })
      } else {
        let longitud = rfc + "";
        if (longitud.length < 13) {
          validaciones.validaRFC = true
          this.setState({ validaciones: validaciones })
        } else {
          validaciones.validaRFC = false
          this.setState({ validaciones: validaciones })
        }
      }
      let longitud = rfc + "";
      if (longitud.length <= 13) {
        nuevaSolicitud[[e.target.name]] = e.target.value.toUpperCase()
      }*/

    }

    if (e.target.name == "selectSector") {
      let sector = e.target.value;
      if (sector == null || sector == "Seleccione...") {
        validaciones.validaSector = true
        nuevaSolicitud["producto"] = null
        this.setState({ validaciones: validaciones, selectProducto: [], producto_id_en_zell: "", banderaSector: false })
      } else {
        validaciones.validaSector = false
        nuevaSolicitud["producto"] = null
        this.setState({ selectProducto: [], producto_id_en_zell: "", nuevaSolicitud: nuevaSolicitud })
        this.getCatalogo(conexiones.urlPytonParametros + "catalogo_producto/" + e.target.value, "selectProducto")

        this.setState({ validaciones: validaciones, banderaSector: true })
      }
      nuevaSolicitud["sector"] = parseInt(e.target.value)
    }


    if (e.target.name == "selectProducto") {
      let sector = e.target.value;
      if (sector == null || sector == "Seleccione...") {
        validaciones.validaProducto = true
        this.setState({ validaciones: validaciones, banderaConvenio: false })
      } else {
        validaciones.validaProducto = false
        this.setState({ validaciones: validaciones, banderaConvenio: true })
      }
      let res = e.target.value
      if (res.split(":").length > 0) {
        nuevaSolicitud["producto"] = res.split(":")[0]
        this.validaBanderaPortales(nuevaSolicitud["producto"])

        this.setState({ producto_id_en_zell: res.split(":")[1] })
      }
      //  nuevaSolicitud["producto"] = e.target.value
    }


    if (e.target.name == "fechaIngreso") {
      let fechaIngreso = e.target.value;
      if (fechaIngreso == null || fechaIngreso == "") {
        validaciones.validafechaIngreso = true
        this.setState({ validaciones: validaciones })
      } else {
        validaciones.validafechaIngreso = false
        this.setState({ validaciones: validaciones })
      }
      nuevaSolicitud["fechaIngreso"] = e.target.value
    }

    if (e.target.name == "selectActuaPor") {
      let sector = e.target.value;
      if (sector == null || sector == "Seleccione...") {
        validaciones.validaActuaPor = true
        this.setState({ validaciones: validaciones })
      } else {
        validaciones.validaActuaPor = false
        this.setState({ validaciones: validaciones })
      }

      nuevaSolicitud["actuaPor"] = e.target.value
    }

    if (e.target.name == "selectTipoProducto") {
      let sector = e.target.value;
      if (sector == null || sector == "Seleccione...") {
        validaciones.validaTipoProducto = true
        this.setState({ validaciones: validaciones })
      } else {
        nuevaSolicitud["plazo"] = null
        validaciones.validaTipoProducto = false
        this.setState({ validaciones: validaciones })
      }
      if (e.target.value == "3" || e.target.value == "2" || e.target.value == "5" || e.target.value == "8" ||
      e.target.value == "6" || e.target.value == "10" || e.target.value == "11" || e.target.value == "12"
      ) {
        this.props.modificaCompraDeuda(true)
      } else {
        this.props.modificaCompraDeuda(false)
      }
      if (!validaciones.validaTipoProducto) {
        this.getPlazo(parseInt(e.target.value))
      }

      nuevaSolicitud["tipoProducto"] = e.target.value
    }

    if (e.target.name == "selectPeriodo") {
      let sector = e.target.value;
      if (sector == null || sector == "Seleccione...") {
        validaciones.validaPeriodo = true
        this.setState({ validaciones: validaciones })
      } else {
        validaciones.validaPeriodo = false
        this.setState({ validaciones: validaciones })
      }
      nuevaSolicitud["periodo"] = e.target.value
    }

    if (e.target.name == "selectPlazo") {
      console.log("values  ", e.target.options[e.target.selectedIndex].text)
      let sector = e.target.value;
      if (sector == null || sector == "Seleccione...") {
        validaciones.validaPlazo = true
        this.setState({ validaciones: validaciones, plazoTexto: null })
      } else {
        validaciones.validaPlazo = false
        this.setState({ validaciones: validaciones, plazoTexto: e.target.options[e.target.selectedIndex].text })
        this.consultaMontoSolicitadoDescuentoYTasaPorPlazo(e.target.value, "plazo")
      }
      nuevaSolicitud["plazo"] = e.target.value
    }


    if (e.target.name == "capPago") {
      let capPago = e.target.value;
      let StrngCapPago = capPago + ""
      if (capPago == null || capPago == 0 || StrngCapPago == "") {
        validaciones.validaCapPago = true
        this.setState({ validaciones: validaciones })
      } else {
        validaciones.validaCapPago = false
        this.setState({ validaciones: validaciones })
      }
      nuevaSolicitud["capPago"] = this.mascaraDecimales(e.target.value)
    }


    if (e.target.name == "monto_deudor") {
      let capPago = e.target.value;
      let StrngCapPago = capPago + ""
      if (capPago == null || capPago == 0 || StrngCapPago == "") {
        validaciones.validaMontoDeudor = true
        this.setState({ validaciones: validaciones })
      } else {
        validaciones.validaMontoDeudor = false
        this.setState({ validaciones: validaciones })
      }
      nuevaSolicitud["monto_deudor"] = this.mascaraDecimales(e.target.value)
    }


    if (e.target.name == "deposito_cliente") {
      let capPago = e.target.value;
      let StrngCapPago = capPago + ""
      if (capPago == null || capPago == 0 || StrngCapPago == "") {
        validaciones.validaDepoCliente = true
        this.setState({ validaciones: validaciones })
      } else {
        validaciones.validaDepoCliente = false
        this.setState({ validaciones: validaciones })
      }
      nuevaSolicitud["deposito_cliente"] = this.mascaraDecimales(e.target.value)
    }




    if (e.target.name == "selectDestinoCredito") {
      let sector = e.target.value;
      if (sector == null || sector == "Seleccione...") {
        validaciones.validaDestinoCredito = true
        this.setState({ validaciones: validaciones })
      } else {
        validaciones.validaDestinoCredito = false
        this.setState({ validaciones: validaciones })
      }
      nuevaSolicitud["destinoCredito"] = e.target.value
    }

    if (e.target.name == "montoSolicitado") {
      let capPago = e.target.value;
      let StrngCapPago = capPago + ""
      if (capPago == null || capPago == 0 || StrngCapPago == "") {
        validaciones.validaMontoSolicitado = true
        this.setState({ validaciones: validaciones })
      } else {
        validaciones.validaMontoSolicitado = false
        this.setState({ validaciones: validaciones })
      }
      nuevaSolicitud["montoSolicitado"] = e.target.value
    }

    if (e.target.name == "selectSucursal") {
      let sector = e.target.value;
      if (sector == null || sector == "Seleccione...") {
        validaciones.validaSucursal = true
        this.setState({ validaciones: validaciones })
      } else {
        validaciones.validaSucursal = false
        this.setState({ validaciones: validaciones })
      }
      nuevaSolicitud["sucursal"] = e.target.value
    }


    if (e.target.name == "folio_imss") {
      let capPago = e.target.value;
      let StrngCapPago = capPago + ""
      if (capPago == null || StrngCapPago == "") {
        validaciones.validaFolioIMSS = true
        this.setState({ validaciones: validaciones })
      } else {
        validaciones.validaFolioIMSS = false
        this.setState({ validaciones: validaciones })
      }
      nuevaSolicitud["folio_imss"] = e.target.value
    }

    console.log(nuevaSolicitud)
    this.setState({ nuevaSolicitud: nuevaSolicitud })
    this.props.guardaDatosNuevaSolicitud(nuevaSolicitud)

  }


  quitaEspeciales(valor) {
    let finalString = valor.replace(/[^0-9]/g, '')
    return finalString
  }

  onChangeValuesClabeReservar = e => {
    let fechaIngreso = e.target.value;
    let longitud = fechaIngreso + "";
    if (longitud.length <= 18) {
      this.setState({ cuentaClabeReservar: this.quitaEspeciales(e.target.value) })
    }
  }

  onChangeValuesTokenReservar = e => {
    let fechaIngreso = e.target.value;
    let longitud = fechaIngreso + "";
    if (longitud.length <= 30) {
      this.setState({ tokenReservar: /*this.quitaEspeciales(e.target.value)*/e.target.value })
    }
  }

  async getDatosPersonales(idSolicitud) {
    try {
      const response = await fetch(conexiones.urlServicios + "wedo_datos_solicitante/" + idSolicitud);
      if (response.ok) {
        const json = await response.json();
        if (json.length > 0) {
          this.props.setBanderaExistenDatosPersonales(true)
          this.props.setDatosPersonalesServicio(json)
        } else {
          this.consultaDatosCompletosZELLporRFC(this.state.nuevaSolicitud.rfc, false)
        }


      } else {
        console.error('Error:', response.status);
        this.setState({ colorModal: this.state.colorErrorModal, tituloModal: 'Ocurrio un error!', mensajeError: 'Error:' + response.status + "en getDatosPersonales", showModal: true })
      }
    } catch (error) {
      console.error('Error:', error);
      this.setState({ colorModal: this.state.colorErrorModal, tituloModal: 'Ocurrio un error!', mensajeError: error.toString() + " en getDatosPersonales", showModal: true })
    }
  }

  async getDatosDomicilio(idSolicitud) {
    try {
      const response = await fetch(conexiones.urlServicios + "wedo_domicilio/" + idSolicitud);
      if (response.ok) {
        const json = await response.json();
        console.log("respuesta de la solicitud datos personales ", json[0])
        if (json.length > 0) {
          this.props.setBanderaExistenDatosDomicilio(true)
          this.props.setDatosDomicilioServicio(json)

        } else {

        }

      } else {
        console.error('Error response.status :', response.status);
        this.setState({ colorModal: this.state.colorErrorModal, tituloModal: 'Ocurrio un error!', mensajeError: 'Error :' + response.status + " en getDatosDomicilio", showModal: true })

      }
    } catch (error) {
      console.error('Error: ', error);
      this.setState({ colorModal: this.state.colorErrorModal, tituloModal: 'Ocurrio un error!', mensajeError: error.toString() + " en getDatosDomicilio", showModal: true })

    }
  }


  async getDatosContacto(idSolicitud) {
    try {
      const response = await fetch(conexiones.urlServicios + "wedo_contacto/" + idSolicitud);
      if (response.ok) {
        const json = await response.json();
        console.log("respuesta de la solicitud datos personales ", json[0])
        if (json.length > 0) {
          this.props.setBanderaExistenDatosContacto(true)
          this.props.setDatosContactoServicio(json)

        } else {

        }

      } else {
        console.error('Error response.status :', response.status);
        this.setState({ colorModal: this.state.colorErrorModal, tituloModal: 'Ocurrio un error!', mensajeError: 'Error :' + response.status + " en getDatosDomicilio", showModal: true })

      }
    } catch (error) {
      console.error('Error: ', error);
      this.setState({ colorModal: this.state.colorErrorModal, tituloModal: 'Ocurrio un error!', mensajeError: error.toString() + " en getDatosDomicilio", showModal: true })

    }
  }


  async getDatosBancarios(idSolicitud) {
    try {
      const response = await fetch(conexiones.urlServicios + "wedo_datos_bancarios/" + idSolicitud);
      if (response.ok) {
        const json = await response.json();
        console.log("respuesta de la solicitud datos bancarios ", json[0])
        if (json.length > 0) {
          this.props.setBanderaExistenDatosBancarios(true)
          this.props.setDatosBancariosServicio(json)

        } else {

        }

      } else {
        console.error('Error response.status :', response.status);
        this.setState({ colorModal: this.state.colorErrorModal, tituloModal: 'Ocurrio un error!', mensajeError: 'Error :' + response.status + " en getDatosDomicilio", showModal: true })

      }
    } catch (error) {
      console.error('Error: ', error);
      this.setState({ colorModal: this.state.colorErrorModal, tituloModal: 'Ocurrio un error!', mensajeError: error.toString() + " en getDatosDomicilio", showModal: true })

    }
  }



  async getDatosOcupacion(idSolicitud) {
    try {
      const response = await fetch(conexiones.urlServicios + "wedo_ocupacion/" + idSolicitud);
      if (response.ok) {
        const json = await response.json();
        console.log("respuesta de la solicitud datos personales ", json[0])
        if (json.length > 0) {
          this.props.setBanderaExistenDatosOcupacion(true)
          this.props.setDatosOcupacionServicio(json)
        }

      } else {
        console.error('Error response.status :', response.status);
        this.setState({ colorModal: this.state.colorErrorModal, tituloModal: 'Ocurrio un error!', mensajeError: 'Error :' + response.status + " en getDatosDomicilio", showModal: true })

      }
    } catch (error) {
      console.error('Error: ', error);
      this.setState({ colorModal: this.state.colorErrorModal, tituloModal: 'Ocurrio un error!', mensajeError: error.toString() + " en getDatosDomicilio", showModal: true })

    }
  }


  async getReferencias(idSolicitud) {
    try {
      const response = await fetch(conexiones.urlServicios + "wedo_referencias/" + idSolicitud);
      if (response.ok) {
        const json = await response.json();
        console.log("referencias ", json)
        if (json.length > 0) {
          this.props.setBanderaExistenDatosReferencias(true)
          this.props.setDatosReferenciasServicio(json)
        }


      } else {
        console.error('Error:', response.status);
        this.setState({ colorModal: this.state.colorErrorModal, tituloModal: 'Ocurrio un error!', mensajeError: response.status, showModal: true })

      }
    } catch (error) {
      console.error('Error:', error);
      this.setState({ colorModal: this.state.colorErrorModal, tituloModal: 'Ocurrio un error!', mensajeError: error.toString(), showModal: true })
    }
  }


  UNSAFE_componentWillMount() {
    const today = new Date();
    console.log("porps", this.props)
    // Restar tres meses a la fecha actual
    const threeMonthsAgo = new Date(today);
    threeMonthsAgo.setMonth(today.getMonth() - 3);
    const formattedDate = `${threeMonthsAgo.getFullYear()}-${threeMonthsAgo.getMonth() + 1}-${threeMonthsAgo.getDate()}`;
    this.setState({ formattedDate: formattedDate })
    // this.getAsesores()
    if (this.props.idSolicitud != null) {
      console.log("entrando a solicitud no null")
      this.setState({ idSolicitudBiometricos: this.props.idSolicitud, banderaSolicitudProcesada: true, showAvanzar: true })
      this.getSolicitud(this.props.idSolicitud)
      setTimeout(() => {
        this.getAsesores()
        this.getCatalogo(conexiones.urlPytonParametros + "catalogo_sucursal/" + this.props.idUsuario, "selectSucursal")
        this.getCatalogo(conexiones.urlPytonParametros + "catalogo_destino_credito/0", "selectDestinoCredito")
        this.getCatalogo(conexiones.urlPytonParametros + "catalogo_actuacion/0", "selectActuaPor")
        //  this.getCatalogo(conexiones.urlPytonParametros + "catalogo_tipo_producto/0", "selectTipoProducto")//catalogo_vtipo_producto_producto
        this.getCatalogo(conexiones.urlPytonParametros + "catalogo_vtipo_producto_producto/" + this.state.nuevaSolicitud.producto, "selectTipoProducto")
        this.getCatalogo(conexiones.urlPytonParametros + "catalogo_afavor/0", "selectAFavor")
        this.getCatalogo(conexiones.urlPytonParametros + "catalogo_genero/0", "selectGeneroTercero")
        // this.setState({ banderaSolicitudProcesada: true, showAvanzar: true })
        //

        this.getDatosPersonales(this.props.idSolicitud)
        this.getDatosDomicilio(this.props.idSolicitud)
        this.getDatosContacto(this.props.idSolicitud)
        this.getDatosBancarios(this.props.idSolicitud)
        /* datos solicitante */
        this.getDatosOcupacion(this.props.idSolicitud)
        this.getReferencias(this.props.idSolicitud)

      }, 500);
    } else {



      let banderaSolicitudProcesada = true
      if (this.props.nuevaSolicitud.curp == null || this.props.nuevaSolicitud.rfc == null || this.props.nuevaSolicitud.sector == null || this.props.nuevaSolicitud.producto == null) {
        banderaSolicitudProcesada = false
      }
      let nuevaSolicitud = this.props.nuevaSolicitud
      nuevaSolicitud.sucursal = this.props.idSucursal
      this.setState({ nuevaSolicitud: nuevaSolicitud, banderaSolicitudProcesada: banderaSolicitudProcesada, showAvanzar: banderaSolicitudProcesada })
      setTimeout(() => {
        //  this.getCatalogo(conexiones.urlPytonParametros + "catalogo_tipo_producto/0", "selectTipoProducto")
        this.getCatalogo(conexiones.urlPytonParametros + "catalogo_vtipo_producto_producto/" + this.props.nuevaSolicitud.producto, "selectTipoProducto")
        this.getCatalogo(conexiones.urlPytonParametros + "catalogo_actuacion/0", "selectActuaPor")
        this.getCatalogo(conexiones.urlPytonParametros + "catalogo_sucursal/" + this.props.idUsuario, "selectSucursal")
        this.getCatalogo(conexiones.urlPytonParametros + "catalogo_destino_credito/0", "selectDestinoCredito")
        this.getCatalogo(conexiones.urlPytonParametros + "catalogo_producto/" + this.props.nuevaSolicitud.sector, "selectProducto")
        this.getCatalogo(conexiones.urlPytonParametros + "catalogo_periodo/" + this.props.nuevaSolicitud.producto, "selectPeriodo")
        this.validaBanderaPortales(this.props.nuevaSolicitud.producto)
        this.getPlazo(parseInt(this.props.nuevaSolicitud.tipoProducto))
      }, 1000)
    }


    setTimeout(() => {
      this.getCatalogo(conexiones.urlPytonParametros + "catalogo_sector/0", "selectSector")
    }, 1000);



  }

  armaSelectPlazos(filas) {
    let select = []
    //  if(selector == "selectSector" ||  selector == "selectProducto" || selector == "selectSucursal"){
    let options = []
    console.log("asignando esto ")
    options.push(<option value={null}>Seleccione...</option>)
    for (var i = 0; i < filas.length; i++) {
      let fila = filas[i]

      if (this.props.idSolicitud == null) {
        if (!this.state.aceptoRefinanciamiento) {
          if (i == 0) {
            this.state.nuevaSolicitud.plazo = fila.id
            options.push(<option selected value={fila.id}>{fila.plazo}</option>)
          } else {
            options.push(<option value={fila.id}>{fila.plazo}</option>)
          }
        } else {
          if (this.state.nuevaSolicitud.plazo == fila.id) {
            options.push(<option selected value={fila.id}>{fila.plazo}</option>)

          } else {
            options.push(<option value={fila.id}>{fila.plazo}</option>)
          }
        }

      } else {
        if (this.state.nuevaSolicitud.plazo == fila.id) {
          options.push(<option selected value={fila.id}>{fila.plazo}</option>)

        } else {
          options.push(<option value={fila.id}>{fila.plazo}</option>)
        }
      }



    }


    select.push(<Form.Select onChange={this.onChangeValues} name={"selectPlazo"} disabled={this.props.banderaSolicitud} >{options}</Form.Select>)
    this.setState({ "selectPlazo": select })

  }


  onChangeValuesAsesores = e => {
    this.setState({
      idAsesorSeleccionado: e.target.value
    })
  }
  armaAsesores(filas) {
    let select = []
    //  if(selector == "selectSector" ||  selector == "selectProducto" || selector == "selectSucursal"){
    let options = []
    console.log("asignando esto ")
    options.push(<option value={null}>Seleccione...</option>)
    for (var i = 0; i < filas.length; i++) {
      let fila = filas[i]

      if (this.state.idAsesorSeleccionado == fila.id) {
        options.push(<option selected value={fila.id}>{fila.asesor}</option>)

      } else {
        options.push(<option value={fila.id}>{fila.asesor}</option>)

      }
    }


    select.push(<Form.Select onChange={this.onChangeValuesAsesores} name={"selectAsesores"} disabled={this.props.banderaSolicitud} >{options}</Form.Select>)
    this.setState({ "selectAsesores": select })

  }


  esCompraDeDeuda(valorSeleccionado){
  
    console.log("esCompraDeDeuda ", valorSeleccionado, valorSeleccionado ==  "3" || valorSeleccionado == "2" || valorSeleccionado == "8" || valorSeleccionado == "5" ? true:false)
     return   valorSeleccionado ==  "3" || valorSeleccionado == "2" || valorSeleccionado == "8" || valorSeleccionado == "5" || valorSeleccionado == "6" || valorSeleccionado == "10" || valorSeleccionado == "11" || valorSeleccionado == "12" ? true:false
  }

  armaSelect(filas, selector) {
    let select = []
    //  if(selector == "selectSector" ||  selector == "selectProducto" || selector == "selectSucursal"){
    let options = []
    options.push(<option value={null}>Seleccione...</option>)

    /* if (this.props.idSolicitud == null) {
       for (var i = 0; i < filas.length; i++) {
         let fila = filas[i].fila
         options.push(<option value={fila[0].value}>{fila[1].value}</option>)
       }
     } else {*/
    var contadorFilas = 0
    for (var i = 0; i < filas.length; i++) {
      let fila = filas[i].fila

      if (selector == "selectSector") {
        if (this.state.nuevaSolicitud.sector == fila[0].value) {
          options.push(<option selected value={fila[0].value}>{fila[1].value}</option>)
        } else {
          options.push(<option value={fila[0].value}>{fila[1].value}</option>)
        }
      }

      if (selector == "selectProducto") {
        console.log("armano el select prodcuto ", this.state.nuevaSolicitud.producto, fila[0].value)
        if (this.state.nuevaSolicitud.producto == fila[0].value) {


          options.push(<option selected value={fila[0].value + ":" + fila[8].value}>{fila[1].value}</option>)


        } else {


          options.push(<option value={fila[0].value + ":" + fila[8].value}>{fila[1].value}</option>)

        }
      }

      if (selector == "selectSucursal") {
        /*if(this.props.idSolicitud == null){
          if(i ==0 ){
            this.state.nuevaSolicitud.sucursal = fila[0].value
            options.push(<option selected value={fila[0].value}>{fila[1].value}</option>)
          }else{
            options.push(<option value={fila[0].value}>{fila[1].value}</option>)
          }
        }else{*/
        console.log("la perra id de la solicid  ", this.state.nuevaSolicitud.sucursal, fila[0].value, this.state.nuevaSolicitud.sucursal == fila[0].value)
        if (this.state.nuevaSolicitud.sucursal == fila[0].value) {
          options.push(<option selected value={fila[0].value}>{fila[1].value}</option>)
        } else {
          options.push(<option value={fila[0].value}>{fila[1].value}</option>)
        }
        // }


      }

      if (selector == "selectDestinoCredito") {
        if (this.props.idSolicitud == null) {
          if (i == 0) {
            this.state.nuevaSolicitud.destinoCredito = fila[0].value
            options.push(<option selected value={fila[0].value}>{fila[1].value}</option>)
          } else {
            options.push(<option value={fila[0].value}>{fila[1].value}</option>)
          }
        } else {
          if (this.state.nuevaSolicitud.destinoCredito == fila[0].value) {
            options.push(<option selected value={fila[0].value}>{fila[1].value}</option>)
          } else {
            options.push(<option value={fila[0].value}>{fila[1].value}</option>)
          }
        }
      }

      if (selector == "selectActuaPor") {
        //    console.log("selectActuaPor", "selectActuaPor", this.state.nuevaSolicitud.actuaPor, fila[0].value)
        if (this.state.nuevaSolicitud.actuaPor == fila[0].value) {
          options.push(<option selected value={fila[0].value}>{fila[1].value}</option>)
        } else {
          options.push(<option value={fila[0].value}>{fila[1].value}</option>)
        }
      }

      if (selector == "selectTipoProducto") {

        if (this.props.idSolicitud == null) {

          if (!this.state.aceptoRefinanciamiento) {
            if (i == 0) {
              this.props.modificaCompraDeuda(this.esCompraDeDeuda(fila[0].value))
              this.state.nuevaSolicitud.tipoProducto = fila[0].value
              options.push(<option selected value={fila[0].value}>{fila[1].value}</option>)
            } else {
             // this.props.modificaCompraDeuda(this.esCompraDeDeuda(fila[0].value))
              options.push(<option value={fila[0].value}>{fila[1].value}</option>)
            }
          } else {
            if (this.state.nuevaSolicitud.tipoProducto == fila[0].value) {
              this.props.modificaCompraDeuda(this.esCompraDeDeuda(fila[0].value))
              options.push(<option selected value={fila[0].value}>{fila[1].value}</option>)
            } else {
            //  this.props.modificaCompraDeuda(this.esCompraDeDeuda(fila[0].value))
              options.push(<option value={fila[0].value}>{fila[1].value}</option>)
            }
          }
        } else {
          console.log("selectTipoProducto", fila)
          if (this.state.nuevaSolicitud.tipoProducto == fila[0].value) {
            this.props.modificaCompraDeuda(this.esCompraDeDeuda(fila[0].value))

            options.push(<option selected value={fila[0].value}>{fila[1].value}</option>)
          } else {
         //   this.props.modificaCompraDeuda(this.esCompraDeDeuda(fila[0].value))
            options.push(<option value={fila[0].value}>{fila[1].value}</option>)
          }
        }
      }

      if (selector == "selectPeriodo") {
        if (this.props.idSolicitud == null) {
          if (!this.state.aceptoRefinanciamiento) {
            if (i == 0) {
              this.state.nuevaSolicitud.periodo = fila[0].value
              options.push(<option selected value={fila[0].value}>{fila[1].value}</option>)
            } else {
              options.push(<option value={fila[0].value}>{fila[1].value}</option>)
            }
          } else {
            if (this.state.nuevaSolicitud.periodo == fila[0].value) {
              options.push(<option selected value={fila[0].value}>{fila[1].value}</option>)
            } else {
              options.push(<option value={fila[0].value}>{fila[1].value}</option>)
            }
          }

        } else {
          if (this.state.nuevaSolicitud.periodo == fila[0].value) {
            options.push(<option selected value={fila[0].value}>{fila[1].value}</option>)
          } else {
            options.push(<option value={fila[0].value}>{fila[1].value}</option>)
          }
        }
      }

      if (selector == "selectPlazo") {
        if (this.props.idSolicitud == null) {
          if (i == 0) {
            this.state.nuevaSolicitud.plazo = fila[0].value
            options.push(<option selected value={fila[0].value}>{fila[1].value}</option>)
          } else {
            options.push(<option value={fila[0].value}>{fila[1].value}</option>)
          }
        } else {
          if (this.state.nuevaSolicitud.plazo == fila[0].value) {
            options.push(<option selected value={fila[0].value}>{fila[1].value}</option>)
          } else {
            options.push(<option value={fila[0].value}>{fila[1].value}</option>)
          }
        }
      }

      if (selector == "selectAFavor") {
        if (this.state.datosTercero.aFavor == fila[0].value) {
          options.push(<option selected value={fila[0].value}>{fila[1].value}</option>)
        } else {
          options.push(<option value={fila[0].value}>{fila[1].value}</option>)
        }
      }


      if (selector == "selectGeneroTercero") {
        if (this.state.datosTercero.genero == fila[0].value) {
          options.push(<option selected value={fila[0].value}>{fila[1].value}</option>)
        } else {
          options.push(<option value={fila[0].value}>{fila[1].value}</option>)
        }
      }

      if (selector == "selectEdoCivilTercero") {
        if (this.state.datosTercero.edoCivil == fila[0].value) {
          options.push(<option selected value={fila[0].value}>{fila[1].value}</option>)
        } else {
          options.push(<option value={fila[0].value}>{fila[1].value}</option>)
        }
      }

    }
    // }


    if (selector == "selectSector") {
      select.push(<Form.Select onChange={this.onChangeValues} name={selector} disabled={this.state.banderaSolicitudProcesada}  >{options}</Form.Select>)
    }

    if (selector == "selectProducto") {
      select.push(<Form.Select onChange={this.onChangeValues} name={selector} disabled={this.state.banderaSolicitudProcesada} >{options}</Form.Select>)
    }

    if (selector == "selectSucursal") {
      select.push(<Form.Select onChange={this.onChangeValues} disabled={(this.props.idSucursal != null && this.props.idSolicitud == null) || this.props.banderaSolicitud} name={selector}  >{options}</Form.Select>)
    }

    if (selector == "selectDestinoCredito") {
      select.push(<Form.Select disabled={this.props.banderaSolicitud} onChange={this.onChangeValues} name={selector} >{options}</Form.Select>)
    }

    if (selector == "selectActuaPor") {
      select.push(<Form.Select disabled={this.props.banderaSolicitud} onChange={this.onChangeValues} name={selector}  >{options}</Form.Select>)
    }
    if (selector == "selectTipoProducto") {
      select.push(<Form.Select disabled={this.props.banderaSolicitud} onChange={this.onChangeValues} name={selector}  >{options}</Form.Select>)
    }
    if (selector == "selectPeriodo") {
      select.push(<Form.Select disabled={this.props.banderaSolicitud} onChange={this.onChangeValues} name={selector}  >{options}</Form.Select>)
    }
    if (selector == "selectPlazo") {
      select.push(<Form.Select disabled={this.props.banderaSolicitud} onChange={this.onChangeValues} name={selector}  >{options}</Form.Select>)
    }

    if (selector == "selectAFavor") {
      select.push(<Form.Select disabled={this.props.banderaSolicitud} onChange={this.onChangeValuesTercero} name={selector}  >{options}</Form.Select>)
    }

    if (selector == "selectGeneroTercero") {
      select.push(<Form.Select disabled={this.props.banderaSolicitud} onChange={this.onChangeValuesTercero} name={selector}  >{options}</Form.Select>)
    }

    if (selector == "selectEdoCivilTercero") {
      select.push(<Form.Select disabled={this.props.banderaSolicitud} onChange={this.onChangeValuesTercero} name={selector}  >{options}</Form.Select>)
    }
    this.setState({ [selector]: select })

  }

  async getDatosTercero(idSol) {
    try {
      const response = await fetch(conexiones.urlServicios + "wedo_actua_tercero/" + idSol);
      if (response.ok) {
        const json = await response.json();
        console.log(json)
        if (json.length > 0) {
          let datosTercero = {
            id: json[0].id,
            aFavor: json[0].afavor,
            nombre: json[0].nombre,
            primerApellido: json[0].ape_paterno,
            segundoApellido: json[0].ape_materno,
            genero: json[0].genero,
            curp: json[0].curp,
            rfc: json[0].rfc,
            telefono: json[0].telefono,
            email: json[0].email,
            celular: json[0].celular,
            fechaNacimiento: json[0].fecha_nacimiento,
            lugarNacimiento: json[0].lugar_nacimiento,
            nacionalidad: json[0].nacionalidad,
            edoCivil: json[0].edocivil,
            primerNombreConyuge: json[0].nombre_conyuge,
            segundoNombreConyuge: json[0].nombre_segundo_conyuge,
            primerApellidoConyuge: json[0].ape_paterno_conyuge,
            segundoApellidoConyuge: json[0].ape_materno_conyuge,
            ocupacionConyuge: json[0].ocupacion_conyuge,
            calle: json[0].calle_numero,
            colonia: json[0].colonia,
            municipio: json[0].municipio,
            cp: json[0].cp,
            ciudad: json[0].ciudad,
            estado: json[0].estado,
            pais: json[0].pais,
            entreCalles: json[0].entre_calles
          }
          this.setState({ datosTercero: datosTercero })

        }



      }
    } catch (error) {
      console.error('Error:', error);
    }
  }

  async getCatalogo(url, selector) {
    try {
      const response = await fetch(url);
      if (response.ok) {
        const json = await response.json();
        console.log(json)
        this.armaSelect(json.filas, selector)
      } else {
        console.error('Error:', response.status);
      }
    } catch (error) {
      console.error('Error:', error);
    }
  }


  async validaBanderaPortales(producto_id) {
    try {
      const response = await fetch(conexiones.urlPytonParametros + "catalogo_convenios/" + producto_id);
      if (response.ok) {
        const json = await response.json();
        if (json.length > 0) {
          this.setState({
            activar_portal: json[0].activar_portal, activar_folioIMSS: json[0].activar_ine,
            datosBanderaBiometricos: json[0].datos_biometricos, tipoCalculoIdPortal: json[0].tipo_calculo_id,
            convenioTieneToken: json[0].token
          })
          this.props.modificaBanderaIMSS(json[0].activar_ine)
        }
      }
    } catch (error) {

    }
  }


  async validaProductoIdZELL(producto_id) {
    try {
      const response = await fetch(conexiones.urlPytonParametros + "catalogo_convenios/" + producto_id);
      if (response.ok) {
        const json = await response.json();
        if (json.length > 0) {
          this.setState({ producto_id_en_zell: json[0].id_to_zell })
        }
      }
    } catch (error) {

    }
  }

  async getSolicitud(idSolicitud) {
    try {
      const response = await fetch(conexiones.urlServicios + "wedo_solicitud/" + idSolicitud);
      if (response.ok) {
        const json = await response.json();
        console.log("respuesta de la solicitud ", json[0], this.props.idSucursal)
        let compraDeuda = false
        this.setState({ idBDSolicitud: json[0].id, idDXN: json[0].appid })
        if (json.length > 0) {

          console.log("la fecha en la entrada", json[0].fecha_ingreso/* new Date(Date.parse(json[0].fecha_ingreso)).getTime() + this.state.mas1Dia */)
          if (json[0].tipo_producto_id == "3" || json[0].tipo_producto_id == "2" 
            || json[0].tipo_producto_id == "8" || json[0].tipo_producto_id == "5"
            || json[0].tipo_producto_id == "6" || json[0].tipo_producto_id == "10"
            || json[0].tipo_producto_id == "11" || json[0].tipo_producto_id == "12"
          ) { compraDeuda = true }

          if (json[0].actuacion_id == "2") {
            this.getDatosTercero(json[0].id)
          }
          let nuevaSolicitud = {
            curp: json[0].curp,
            rfc: json[0].rfc,
            sector: json[0].sector_id,
            producto: json[0].producto_id,
            folio_imss: json[0].producto_id == 86 ||json[0].producto_id == 85 ||json[0].producto_id == 84||json[0].producto_id == 83||json[0].producto_id == 82 ||json[0].producto_id == 81 ||json[0].producto_id == 76 || json[0].producto_id == 77 ||json[0].producto_id == 9 || json[0].producto_id == 10 ||   json[0].producto_id == 11 ? json[0].folio_imss : null,
            fechaIngreso: json[0].fecha_ingreso,//new Date(json[0].fecha_ingreso).getTime() + this.state.mas1Dia,
            actuaPor: json[0].actuacion_id,
            tipoProducto: json[0].tipo_producto_id,
            periodo: json[0].periodo_id,
            plazo: json[0].plazo_id,
            capPago: json[0].capacidad_pago,
            destinoCredito: json[0].destcredito_id,
            montoSolicitado: json[0].monto_solicitado,
            descuento: json[0].descuento,
            tasaAnual: json[0].tasa_anual,
            sucursal: json[0].sucursal_id,
            monto_deudor: json[0].tipo_producto_id == 2 ? json[0].monto_deudor : null,
            deposito_cliente: json[0].tipo_producto_id == 2 ? json[0].deposito_cliente : null,
            appid: json[0].tipo_producto_id == 2 ? json[0].appid : json[0].appid,
            estatus: json[0].estatus,


          }

          this.setState({
            idAsesorSeleccionado: json[0].asesor_id,
            folio: json[0].appid,
            folioRefinanciamiento: json[0].tipo_producto_id == 2 || json[0].tipo_producto_id == 5 ? json[0].appid : json[0].appid,
            activar_biometricos: (json[0].producto_id == 9 || json[0].producto_id == 10 || json[0].producto_id == 11) /*&&   this.props.idUsuario== 323*/ ? true : false
          })

          let validaciones = {
            validaCURP: false,
            validaRFC: false,
            validaSector: false,
            validafechaIngreso: false,
            validaProducto: false,
            validaActuaPor: false,
            validaTipoProducto: false,
            validaPeriodo: false,
            validaCapPago: false,
            validaPlazo: false,
            validaDestinoCredito: false,
            validaMontoSolicitado: false,
            validaSucursal: false,
            validaMontoDeudor: false,
            validaDepoCliente: false
          }
          console.log("respuesta de la nueva solicitud ", nuevaSolicitud)
          this.props.setNuevaSolicitudServicio(json[0])
          this.props.modificaCompraDeuda(compraDeuda)
          this.validaBanderaPortales(json[0].producto_id)
          this.validaProductoIdZELL(json[0].producto_id)

          this.setState({ nuevaSolicitud: nuevaSolicitud, validaciones: validaciones, showAvanzar: true })
          this.getCatalogo(conexiones.urlPytonParametros + "catalogo_producto/" + json[0].sector_id, "selectProducto")
          this.getCatalogo(conexiones.urlPytonParametros + "catalogo_periodo/" + json[0].producto_id, "selectPeriodo")
          this.getPlazo(parseInt(json[0].tipo_producto_id))
          this.consultaCreditoMorosidad(json[0])

        }



      } else {
        console.error('Error:', response.status);
      }
    } catch (error) {
      console.error('Error:', error);
    }
  }


  async getPlazo(idTipoProducto) {
    const settings = {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        "producto": this.state.nuevaSolicitud.producto,
        "tipo_producto": idTipoProducto
      })
    };
    try {
      const fetchResponse = await fetch(conexiones.urlPytonParametros + "catalogo_producto_plazo/0", settings);
      const data = await fetchResponse.json();
      console.log("respesta de los plazos  ", data)
      this.armaSelectPlazos(data)


    } catch (e) {
      return e;
    }
  }

  async getAsesores() {
    const settings = {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        "user_id": this.props.idUsuario,
        "puesto_id": this.props.idPuesto
      })
    };
    try {
      const fetchResponse = await fetch(conexiones.urlUsuarios + "usuario_vreporta/0", settings);
      const data = await fetchResponse.json();
      console.log("respesta de los assores reportados  ", data)
      this.armaAsesores(data)


    } catch (e) {
      return e;
    }
  }

  async consultaMontoSolicitadoDescuentoYTasaPorPlazo(plazo, origen) {
    const cleanedString = this.state.nuevaSolicitud.capPago.replace(/,/g, ''); // Eliminar las comas
    const parsedValueCapPago = parseFloat(cleanedString);
    console.log("la capacidad de pago ", parsedValueCapPago)

    const settings = {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        "tipo_calculo": origen == "plazo" || origen == "capPago" ? 1 : 2,
        "producto": this.state.nuevaSolicitud.producto,
        "tipo_producto": this.state.nuevaSolicitud.tipoProducto,//tradicional
        "plazo": plazo,
        "frecuencia": this.state.nuevaSolicitud.periodo,
        "capacidad_pago": Number.isNaN(parsedValueCapPago) == true ? 0 : parsedValueCapPago,
        "monto_solicitado": origen == "plazo" || origen == "capPago" ? 0 : parseFloat(this.state.nuevaSolicitud.montoSolicitado)
      })
    };
    try {
      const fetchResponse = await fetch(conexiones.ulrPortales + "wedo_capacidad_pago/0", settings);
      const data = await fetchResponse.json();
      console.log("respesta de los consumos  ", data[0])
      let resp = data[0]
      if (resp.sucesso == 0) {
        let nuevaSoicitud = this.state.nuevaSolicitud
        nuevaSoicitud.montoSolicitado = resp.monto_solicitado
        nuevaSoicitud.descuento = resp.descuento
        nuevaSoicitud.tasaAnual = resp.tasa
        this.setState({ nuevaSolicitud: nuevaSoicitud })
        this.props.guardaDatosNuevaSolicitud(nuevaSoicitud)
      } else if (resp.sucesso == 99) {
        this.setState({ colorModal: this.state.colorErrorModal, mensajeError: resp.mensagem, showModal: true })
        let nuevaSoicitud = this.state.nuevaSolicitud
        nuevaSoicitud.montoSolicitado = resp.monto_solicitado
        nuevaSoicitud.descuento = resp.descuento
        nuevaSoicitud.tasaAnual = resp.tasa
        this.setState({ nuevaSolicitud: nuevaSoicitud })
        this.props.guardaDatosNuevaSolicitud(nuevaSoicitud)


      }
    } catch (e) {
      return e;
    }
  }


  setRFCEncontradoNuestrasBases(json) {
    let nuevaSolicitud = {
      curp: json[0].curp,
      rfc: json[0].rfc,
      sector: json[0].sector_id,
      producto: json[0].producto_id,
      fechaIngreso: this.state.nuevaSolicitud.fechaIngreso,//new Date(json[0].fecha_ingreso).getTime() + this.state.mas1Dia,
      actuaPor: json[0].actuacion_id,
      tipoProducto: json[0].tipo_producto_id,
      periodo: json[0].periodo_id,
      plazo: json[0].plazo_id,
      capPago: json[0].capacidad_pago,
      destinoCredito: json[0].destcredito_id,
      montoSolicitado: json[0].monto_solicitado,
      descuento: json[0].descuento,
      tasaAnual: json[0].tasa_anual,
      sucursal: json[0].sucursal_id,

    }
    let compraDeuda = false
    if (json[0].tipo_producto_id == "2") { compraDeuda = true }
    if (json[0].actuacion_id == "2") {
      this.getDatosTercero(json[0].id)
    }
    let validaciones = {
      validaCURP: false,
      validaRFC: false,
      validaSector: false,
      validafechaIngreso: false,
      validaProducto: false,
      validaActuaPor: false,
      validaTipoProducto: false,
      validaPeriodo: false,
      validaCapPago: false,
      validaPlazo: false,
      validaDestinoCredito: false,
      validaMontoSolicitado: false,
      validaSucursal: false
    }

    this.props.guardaIdSolicitud(json[0].id);
    this.props.modificaCompraDeuda(compraDeuda)
    console.log("respuesta de la nueva solicitud ", nuevaSolicitud)
    this.setState({
      nuevaSolicitud: nuevaSolicitud, validaciones: validaciones, showAvanzar: true,
      colorModal: this.state.colorSuccesModal, mensajeError: "!Tu petición ha sido procesada¡", showModal: true
    })
    setTimeout(() => {
      //   console.log("Hola Mundo");
      this.getCatalogo(conexiones.urlPytonParametros + "catalogo_sector/0", "selectSector")
      this.getCatalogo(conexiones.urlPytonParametros + "catalogo_producto/0", "selectProducto")
      this.getCatalogo(conexiones.urlPytonParametros + "catalogo_sucursal/" + this.props.idUsuario, "selectSucursal")
      this.getCatalogo(conexiones.urlPytonParametros + "catalogo_destino_credito/0", "selectDestinoCredito")
      this.getCatalogo(conexiones.urlPytonParametros + "catalogo_actuacion/0", "selectActuaPor")
      // this.getCatalogo(conexiones.urlPytonParametros + "catalogo_tipo_producto/0", "selectTipoProducto")
      this.getCatalogo(conexiones.urlPytonParametros + "catalogo_vtipo_producto_producto/" + nuevaSolicitud.producto, "selectTipoProducto")

      //  this.getCatalogo(conexiones.urlPytonParametros + "catalogo_periodo/0", "selectPeriodo")
      // this.getCatalogo(conexiones.urlPytonParametros + "catalogo_plazo/0", "selectPlazo")
      this.getCatalogo(conexiones.urlPytonParametros + "catalogo_periodo/" + nuevaSolicitud.producto, "selectPeriodo")
      this.getPlazo(parseInt(nuevaSolicitud.tipoProducto))
    }, 1000);
  }


  setNullsASolicitud() {
    this.props.guardaIdSolicitud(null);
    this.props.modificaCompraDeuda(false)
    this.props.generaJsonsParaNuevaSolicitud()
    let nuevaSolicitud = this.state.nuevaSolicitud
    nuevaSolicitud.fechaIngreso = ""
    nuevaSolicitud.actuaPor = "1"
    //   nuevaSolicitud.tipoProducto = null
    nuevaSolicitud.periodo = null
    nuevaSolicitud.plazo = null
    nuevaSolicitud.capPago = ""
    nuevaSolicitud.destinoCredito = null
    nuevaSolicitud.montoSolicitado = ""
    nuevaSolicitud.descuento = null
    nuevaSolicitud.tasaAnual = null
    nuevaSolicitud.sucursal = this.props.idSucursal
    let validaciones = {
      validaCURP: true,
      validaRFC: true,
      validaSector: true,
      validaProducto: true,
      validafechaIngreso: true,
      validaActuaPor: true,
      validaTipoProducto: true,
      validaPeriodo: true,
      validaCapPago: true,
      validaPlazo: true,
      validaDestinoCredito: true,
      validaMontoSolicitado: true,
      validaSucursal: true
    }
    setTimeout(() => {

      this.setState({
        selectSector: [],
        selectProducto: [],
        selectSucursal: [],
        selectDestinoCredito: [],
        selectActuaPor: [],
        selectTipoProducto: [],
        selectPeriodo: [],
        selectPlazo: [],
      })

      this.setState({ /*validaciones: validaciones,*/ nuevaSolicitud: nuevaSolicitud, showAvanzar: true, colorModal: this.state.colorSuccesModal, mensajeError: "Tu RFC no se encuentra adherido a una solcitud todavía", showModal: true })
      //  this.props.guardaDatosNuevaSolicitud(nuevaSolicitud)
      //   console.log("Hola Mundo");
      this.getCatalogo(conexiones.urlPytonParametros + "catalogo_sector/0", "selectSector")
      this.getCatalogo(conexiones.urlPytonParametros + "catalogo_producto/0", "selectProducto")
      this.getCatalogo(conexiones.urlPytonParametros + "catalogo_sucursal/" + this.props.idUsuario, "selectSucursal")
      this.getCatalogo(conexiones.urlPytonParametros + "catalogo_destino_credito/0", "selectDestinoCredito")
      this.getCatalogo(conexiones.urlPytonParametros + "catalogo_actuacion/0", "selectActuaPor")
      //      this.getCatalogo(conexiones.urlPytonParametros + "catalogo_tipo_producto/0", "selectTipoProducto")
      this.getCatalogo(conexiones.urlPytonParametros + "catalogo_vtipo_producto_producto/" + this.state.nuevaSolicitud.producto, "selectTipoProducto")

      this.getCatalogo(conexiones.urlPytonParametros + "catalogo_periodo/" + this.state.nuevaSolicitud.producto, "selectPeriodo")
      //this.getCatalogo(conexiones.urlPytonParametros + "catalogo_plazo/0", "selectPlazo")
      setTimeout(() => {
        console.log("el tipo del productos  ", this.state.nuevaSolicitud.tipoProducto)
        this.getPlazo(parseInt(this.state.nuevaSolicitud.tipoProducto))
      }, 500)


    }, 1000);
  }







  async procesaTuSolicitud() {
    try {
      let json = {
        "rfc": this.state.nuevaSolicitud.rfc,//"VAGM771011J84",
        "usuario": this.props.idUsuario
      }
      const requestReferidoOptions = {
        method: "POST",
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(json)
      }
      const responseRFC = await fetch(conexiones.urlServicios + "valida_usurio_rfc/0", requestReferidoOptions);
      if (responseRFC.ok) {
        const result = await responseRFC.json();
        console.log("respuesta del rfc validado ", result)
        if (result[0].codigo == 99) {
          this.setState({ showAvanzar: false, colorModal: this.state.colorErrorModal, mensajeError: result[0].estatus, showModal: true })
        } else {
          const response = await fetch(conexiones.urlBuscaRFC + this.state.nuevaSolicitud.rfc);
          if (response.ok) {
            const json = await response.json();
            console.log("respuesta de la solicitud ", json[0])

            this.setNullsASolicitud()
            this.consultaDatosCompletosZELLporRFC(this.state.nuevaSolicitud.rfc, false)

          } else {
            console.error('Error:', response.status);
            this.setState({ showAvanzar: false, colorModal: this.state.colorErrorModal, mensajeError: "Error en la peticion del rfc", showModal: true })
          }
        }




      }



    } catch (error) {
      console.error('Error:', error);
      this.setState({ showAvanzar: false, colorModal: this.state.colorErrorModal, mensajeError: error.toString(), showModal: true })
    }
  }

  async reservaCredito() {
    let json =
    {
      "producto": this.state.nuevaSolicitud.producto,
      "cpf": this.state.nuevaSolicitud.rfc,
      "prazo": this.state.plazoTexto,
      "valorLiberado": this.state.nuevaSolicitud.montoSolicitado,
      "banco": 0,
      "agencia": 0,
      "conta": this.state.cuentaClabeReservar,
      "valorParcela": this.state.nuevaSolicitud.descuento,
      "token": this.state.convenioTieneToken ? this.state.tokenReservar : null
    }
    console.log("el json ", json)
    const requestReferidoOptions = {
      method: "POST",
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(json)
    }
    fetch(conexiones.ulrPortales + "wedo_portal_reserva/0", requestReferidoOptions)
      .then(response => response.json())
      .then(data => {
        this.setState({ showModalReservar: false })
        if (data.length > 0) {
          let res = data[0]
          if (res.sucesso == true) {
            this.setState({ colorModal: this.state.colorSuccesModal, tituloModal: 'Aviso !', mensajeError: res.mensagem, showModal: true, activar_portal: false, banderaBotonReservar: false })
          }
        } else {
          this.setState({ colorModal: this.state.colorErrorModal, tituloModal: 'Ocurrio un error en la respuesta de Portales reserva credito !', mensajeError: "Ha devuelto el array vacio", showModal: true })
        }




      }).catch(error => {
        this.setState({ showModalReservar: false })
        this.setState({ colorModal: this.state.colorErrorModal, tituloModal: 'Ocurrio un error al consumir Portales consulta capacidad !', mensajeError: error.toString(), showModal: true })

      })

  }

  formatoLog(data) {
    let encabezados = Object.keys(data)
    let contenido = Object.values(data)
    let salida = ''

    let salidaEncabezados = []

    console.log("encabzados ", encabezados)
    for (var i = 0; i < encabezados.length; i++) {
      salidaEncabezados.push(
        <tr>
          <td>{encabezados[i]}</td>
          <td>{contenido[i]}</td>
        </tr>

      );
    }


    salida =
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>Campo</th>
            <th>Mensaje</th>
          </tr>
        </thead>
        <tbody>
          {salidaEncabezados}
        </tbody>
      </Table>



    return salida
  }

  actualizaSolicitud() {
    let validaciones = this.state.validaciones
    let nuevaSoicitud = this.state.nuevaSolicitud



    if (this.state.activar_folioIMSS) {
      if (nuevaSoicitud.folio_imss == null || nuevaSoicitud.folio_imss == "") {
        validaciones.validaFolioIMSS = true;
        this.setState({ validaciones: validaciones })
      } else {
        validaciones.validaFolioIMSS = false;
        this.setState({ validaciones: validaciones })
      }
    }


    if (nuevaSoicitud.fechaIngreso == null) {
      validaciones.validafechaIngreso = true;
      this.setState({ validaciones: validaciones })
    } else {
      validaciones.validafechaIngreso = false;
      this.setState({ validaciones: validaciones })
    }

    if (nuevaSoicitud.actuaPor == null || nuevaSoicitud.actuaPor == "Seleccione...") {
      validaciones.validaActuaPor = true;
      this.setState({ validaciones: validaciones })
    } else {
      validaciones.validaActuaPor = false;
      this.setState({ validaciones: validaciones })
    }

    if (nuevaSoicitud.tipoProducto == null || nuevaSoicitud.tipoProducto == "Seleccione...") {
      validaciones.validaTipoProducto = true;
      this.setState({ validaciones: validaciones })
    } else {
      validaciones.validaTipoProducto = false;
      this.setState({ validaciones: validaciones })
    }

    if (nuevaSoicitud.periodo == null || nuevaSoicitud.periodo == "Seleccione...") {
      validaciones.validaPeriodo = true;
      this.setState({ validaciones: validaciones })
    } else {
      validaciones.validaPeriodo = false;
      this.setState({ validaciones: validaciones })
    }

    if (nuevaSoicitud.plazo == null || nuevaSoicitud.plazo == "Seleccione...") {
      validaciones.validaPlazo = true;
      this.setState({ validaciones: validaciones })
    } else {
      validaciones.validaPlazo = false;
      this.setState({ validaciones: validaciones })
    }

    if (nuevaSoicitud.capPago == null) {
      validaciones.validaCapPago = true;
      this.setState({ validaciones: validaciones })
    } else {
      validaciones.validaCapPago = false;
      this.setState({ validaciones: validaciones })
    }

    if (nuevaSoicitud.destinoCredito == null || nuevaSoicitud.destinoCredito == "Seleccione...") {
      validaciones.validaDestinoCredito = true;
      this.setState({ validaciones: validaciones })
    } else {
      validaciones.validaDestinoCredito = false;
      this.setState({ validaciones: validaciones })
    }


    if (nuevaSoicitud.montoSolicitado == null) {
      validaciones.validaMontoSolicitado = true;
      this.setState({ validaciones: validaciones })
    } else {
      validaciones.validaMontoSolicitado = false;
      this.setState({ validaciones: validaciones })
    }

    console.log("suscursal ", nuevaSoicitud.sucursal)
    if (nuevaSoicitud.sucursal == null || nuevaSoicitud.sucursal == "Seleccione...") {
      validaciones.validaSucursal = true;
      this.setState({ validaciones: validaciones })
    } else {
      validaciones.validaSucursal = false;
      this.setState({ validaciones: validaciones })
    }


    let fechaFormateada = new Date(this.state.nuevaSolicitud.fechaIngreso)
    var dateFormat = fechaFormateada.getFullYear() + "-" + ((fechaFormateada.getMonth() + 1).length != 2 ? "" + (fechaFormateada.getMonth() + 1) : (fechaFormateada.getMonth() + 1)) + "-" + (fechaFormateada.getDate().length != 2 ? fechaFormateada.getDate() : fechaFormateada.getDate())


    console.log("conversion de la feha ", this.state.nuevaSolicitud.fechaIngreso, dateFormat)
    let user = this.props.idUsuario
    let json =
    {
      "estatus": 1,
      "folio": this.props.idSolicitud,
      "asesor_id": this.state.idAsesorSeleccionado != null && this.state.idAsesorSeleccionado != "Seleccione..." ? this.state.idAsesorSeleccionado : 0,
      "curp": this.state.nuevaSolicitud.curp,
      "rfc": this.state.nuevaSolicitud.rfc,
      "sector_id": this.state.nuevaSolicitud.sector,
      "producto_id": this.state.nuevaSolicitud.producto,
      "folio_imss": this.state.activar_folioIMSS ? this.state.nuevaSolicitud.folio_imss : null,
      "fecha_ingreso": this.state.nuevaSolicitud.fechaIngreso,// dateFormat,
      "actuacion_id": this.state.nuevaSolicitud.actuaPor,
      "tipo_producto_id": this.state.nuevaSolicitud.tipoProducto,
      "periodo_id": this.state.nuevaSolicitud.periodo,
      "plazo_id": this.state.nuevaSolicitud.plazo,
      "capacidad_pago": this.state.nuevaSolicitud.capPago,
      "destcredito_id": this.state.nuevaSolicitud.destinoCredito,
      "monto_solicitado": this.state.nuevaSolicitud.montoSolicitado,
      "descuento": this.state.nuevaSolicitud.descuento,
      "tasa_anual": this.state.nuevaSolicitud.tasaAnual,
      "sucursal_id": this.state.nuevaSolicitud.sucursal,
      "monto_deudor": this.state.nuevaSolicitud.tipoProducto == 2 || this.state.nuevaSolicitud.tipoProducto == 5 ? this.state.nuevaSolicitud.monto_deudor : null,
      "deposito_cliente": this.state.nuevaSolicitud.tipoProducto == 2 || this.state.nuevaSolicitud.tipoProducto == 5 ? this.state.nuevaSolicitud.deposito_cliente : null,
      "appid": this.state.nuevaSolicitud.tipoProducto == 2 || this.state.nuevaSolicitud.tipoProducto == 5 ? this.state.folioRefinanciamiento == null || this.state.folioRefinanciamiento.length == 0 ? null : this.state.folioRefinanciamiento : this.state.nuevaSolicitud.appid,
      "user_id": (this.props.idPuesto == 4 || this.props.idPuesto == 7 || this.props.idPuesto == 8 || this.props.idPuesto == 9) && this.state.idAsesorSeleccionado != null && this.state.idAsesorSeleccionado != "Seleccione..." ? this.state.idAsesorSeleccionado : user
    }
    console.log("el json ",
      (this.props.idPuesto == 4 || this.props.idPuesto == 7 || this.props.idPuesto == 8 || this.props.idPuesto == 9) &&
      this.state.idAsesorSeleccionado != null && this.state.idAsesorSeleccionado != "Seleccione...", json)
    const requestReferidoOptions = {
      method: "PUT",
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(json)
    }
    if (!this.state.activar_folioIMSS) {
      fetch(conexiones.urlServiciosActualiza + "wedo_solicitud/" + this.props.idSolicitud, requestReferidoOptions)
        .then(response => response.json())
        .then(data => {
          this.setState({ activar_biometricos: false })
          console.log("respuesta del servicio al actualizar  una solicitud ", data)
          if (data["id"] != undefined) {
            this.setState({ idBDSolicitud: data["id"], idDXN: data["appid"] })
            this.props.setNuevaSolicitudServicio(data)
            if (this.state.nuevaSolicitud.actuaPor == "2") {
              if (this.state.datosTercero.id == null) {
                this.creaActuaTercero()

              } else {
                this.actualizaActuaTercero()
              }
            } else {
              setTimeout(() => {
                /*if (!this.state.activar_biometricos)if (!validaciones.validaFolioIMSS) {*/
                this.props.clickAvanzar("datosSol");
                //}
              }, 1500)

            }

          } else {

            this.setState({ colorModal: this.state.colorErrorModal, tituloModal: 'Ocurrio un error!', mensajeError: this.formatoLog(data), showModal: true })
          }


        }).catch(error => {
          this.setState({ colorModal: this.state.colorErrorModal, tituloModal: 'Ocurrio un error!', mensajeError: error.toString(), showModal: true })

        })
    } else {
      if (validaciones.validaFolioIMSS) {
        this.setState({ colorModal: this.state.colorErrorModal, tituloModal: 'Ocurrio un error!', mensajeError: "En este convenio es requerido el Folio IMSS", showModal: true })
      } else {
        fetch(conexiones.urlServiciosActualiza + "wedo_solicitud/" + this.props.idSolicitud, requestReferidoOptions)
          .then(response => response.json())
          .then(data => {

            console.log("respuesta del servicio al actualizar  una solicitud ", data)
            if (data["id"] != undefined) {

              this.props.setNuevaSolicitudServicio(data)
              if (this.state.nuevaSolicitud.actuaPor == "2") {
                if (this.state.datosTercero.id == null) {
                  this.creaActuaTercero()

                } else {
                  this.actualizaActuaTercero()
                }
              } else {
                if (!(this.state.nuevaSolicitud.producto != 86 ||this.state.nuevaSolicitud.producto != 85 
                  || this.state.nuevaSolicitud.producto != 84 ||this.state.nuevaSolicitud.producto != 83 ||this.state.nuevaSolicitud.producto != 82 
                  ||this.state.nuevaSolicitud.producto != 81 ||this.state.nuevaSolicitud.producto != 76 || this.state.nuevaSolicitud.producto != 77 
                  ||this.state.nuevaSolicitud.producto != 9 || this.state.nuevaSolicitud.producto != 10 || this.state.nuevaSolicitud.producto != 11)) {
                  setTimeout(() => {
                    /*if (!this.state.activar_biometricos)if (!validaciones.validaFolioIMSS) {*/
                    this.props.clickAvanzar("datosSol");
                    //}
                  }, 1500)
                } else {
                  if (this.state.datosBanderaBiometricos) {
                    this.setState({ activar_biometricos: true })
                    this.setState({ colorModal: this.state.colorSuccesModal,
                       tituloModal: 'Actualizacion correcta !',
                        mensajeError: "Recuerda que en convenios del IMSS se permanece en la pantalla de solicitud despues de actualizar solicitud", showModal: true })


                  } else {
                    setTimeout(() => {
                      /*if (!this.state.activar_biometricos)if (!validaciones.validaFolioIMSS) {*/
                      this.props.clickAvanzar("datosSol");
                      //}
                    }, 1500)
                  }
                }
              }

            } else {

              this.setState({ colorModal: this.state.colorErrorModal, tituloModal: 'Ocurrio un error!', mensajeError: this.formatoLog(data), showModal: true })
            }


          }).catch(error => {
            this.setState({ colorModal: this.state.colorErrorModal, tituloModal: 'Ocurrio un error!', mensajeError: error.toString(), showModal: true })

          })
      }

    }


  }


  creaSolicitud() {

    let validaciones = this.state.validaciones
    let nuevaSoicitud = this.state.nuevaSolicitud


    if (this.state.activar_folioIMSS) {
      if (nuevaSoicitud.folio_imss == null || nuevaSoicitud.folio_imss == "") {
        validaciones.validaFolioIMSS = true;
        this.setState({ validaciones: validaciones })
      } else {
        validaciones.validaFolioIMSS = false;
        this.setState({ validaciones: validaciones })
      }
    }

    if (nuevaSoicitud.fechaIngreso == null) {
      validaciones.validafechaIngreso = true;
      this.setState({ validaciones: validaciones })
    } else {
      validaciones.validafechaIngreso = false;
      this.setState({ validaciones: validaciones })
    }

    if (nuevaSoicitud.actuaPor == null) {
      validaciones.validaActuaPor = true;
      this.setState({ validaciones: validaciones })
    } else {
      validaciones.validaActuaPor = false;
      this.setState({ validaciones: validaciones })
    }

    if (nuevaSoicitud.tipoProducto == null) {
      validaciones.validaTipoProducto = true;
      this.setState({ validaciones: validaciones })
    } else {
      validaciones.validaTipoProducto = false;
      this.setState({ validaciones: validaciones })
    }

    if (nuevaSoicitud.periodo == null) {
      validaciones.validaPeriodo = true;
      this.setState({ validaciones: validaciones })
    } else {
      validaciones.validaPeriodo = false;
      this.setState({ validaciones: validaciones })
    }

    if (nuevaSoicitud.plazo == null) {
      validaciones.validaPlazo = true;
      this.setState({ validaciones: validaciones })
    } else {
      validaciones.validaPlazo = false;
      this.setState({ validaciones: validaciones })
    }

    if (nuevaSoicitud.capPago == null) {
      validaciones.validaCapPago = true;
      this.setState({ validaciones: validaciones })
    } else {
      validaciones.validaCapPago = false;
      this.setState({ validaciones: validaciones })
    }

    if (nuevaSoicitud.destinoCredito == null) {
      validaciones.validaDestinoCredito = true;
      this.setState({ validaciones: validaciones })
    } else {
      validaciones.validaDestinoCredito = false;
      this.setState({ validaciones: validaciones })
    }


    if (nuevaSoicitud.montoSolicitado == null) {
      validaciones.validaMontoSolicitado = true;
      this.setState({ validaciones: validaciones })
    } else {
      validaciones.validaMontoSolicitado = false;
      this.setState({ validaciones: validaciones })
    }

    if (nuevaSoicitud.sucursal == null) {
      validaciones.validaSucursal = true;
      this.setState({ validaciones: validaciones })
    } else {
      validaciones.validaSucursal = false;
      this.setState({ validaciones: validaciones })
    }

    let fechaFormateada = new Date(this.state.nuevaSolicitud.fechaIngreso)
    var dateFormat = fechaFormateada.getFullYear() + "-" + ((fechaFormateada.getMonth() + 1).length != 2 ? "" + (fechaFormateada.getMonth() + 1) : (fechaFormateada.getMonth() + 1)) + "-" + (fechaFormateada.getDate().length != 2 ? fechaFormateada.getDate() : fechaFormateada.getDate())


    //console.log("conversion de la feha ", this.state.nuevaSolicitud.fechaIngreso, dateFormat)
    let user = this.props.idUsuario
    let json =
    {
      "estatus": 1,
      "folio": 0,
      "asesor_id": this.state.idAsesorSeleccionado != null && this.state.idAsesorSeleccionado != "Seleccione..." ? this.state.idAsesorSeleccionado : 0,
      "curp": this.state.nuevaSolicitud.curp,
      "rfc": this.state.nuevaSolicitud.rfc,
      "sector_id": this.state.nuevaSolicitud.sector,
      "producto_id": this.state.nuevaSolicitud.producto,
      "folio_imss": this.state.activar_folioIMSS ? this.state.nuevaSolicitud.folio_imss : null,
      "fecha_ingreso": this.state.nuevaSolicitud.fechaIngreso,//dateFormat,//this.state.nuevaSolicitud.fechaIngreso,
      "actuacion_id": this.state.nuevaSolicitud.actuaPor,
      "tipo_producto_id": this.state.nuevaSolicitud.tipoProducto,
      "periodo_id": this.state.nuevaSolicitud.periodo,
      "plazo_id": this.state.nuevaSolicitud.plazo,
      "capacidad_pago": this.state.nuevaSolicitud.capPago,
      "destcredito_id": this.state.nuevaSolicitud.destinoCredito,
      "monto_solicitado": this.state.nuevaSolicitud.montoSolicitado,
      "descuento": this.state.nuevaSolicitud.descuento,
      "tasa_anual": this.state.nuevaSolicitud.tasaAnual,
      "sucursal_id": this.state.nuevaSolicitud.sucursal,
      "monto_deudor": this.state.nuevaSolicitud.tipoProducto == 2 || this.state.nuevaSolicitud.tipoProducto == 5 ? this.state.nuevaSolicitud.monto_deudor : null,
      "deposito_cliente": this.state.nuevaSolicitud.tipoProducto == 2 || this.state.nuevaSolicitud.tipoProducto == 5 ? this.state.nuevaSolicitud.deposito_cliente : null,
      "appid": this.state.nuevaSolicitud.tipoProducto == 2 || this.state.nuevaSolicitud.tipoProducto == 5 ? this.state.folioRefinanciamiento == null || this.state.folioRefinanciamiento.length == 0 ? null : this.state.folioRefinanciamiento : this.state.nuevaSolicitud.appid,
      "user_id": (this.props.idPuesto == 4 || this.props.idPuesto == 7 || this.props.idPuesto == 8 || this.props.idPuesto == 9) && this.state.idAsesorSeleccionado != null && this.state.idAsesorSeleccionado != "Seleccione..." ? this.state.idAsesorSeleccionado : user
    }
    console.log("el json ", json)
    const requestReferidoOptions = {
      method: "POST",
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(json)
    }
    if (!this.state.activar_folioIMSS) {
      fetch(conexiones.urlServicios + "wedo_solicitud/0", requestReferidoOptions)
        .then(response => response.json())
        .then(data => {

          console.log("respuesta del servicio al crea una solicitud ", data)
          if (data["id"] != undefined) {
            this.setState({ idBDSolicitud: data["id"], idDXN: data["appid"] })

            this.props.guardaIdSolicitud(data["id"])
            this.props.setNuevaSolicitudServicio(data)
            setTimeout(() => {
              /*if (!this.state.activar_biometricos)if (!validaciones.validaFolioIMSS) {*/
              this.props.clickAvanzar("datosSol");
              //}
            }, 1500)


          } else {

            this.setState({ colorModal: this.state.colorErrorModal, tituloModal: 'Ocurrio un error!', mensajeError: this.formatoLog(data), showModal: true })
          }


        }).catch(error => {
          this.setState({ colorModal: this.state.colorErrorModal, tituloModal: 'Ocurrio un error!', mensajeError: error.toString(), showModal: true })

        })
    } else {
      if (validaciones.validaFolioIMSS) {
        this.setState({ colorModal: this.state.colorErrorModal, tituloModal: 'Ocurrio un error!', mensajeError: "En este convenio es requerido el Folio IMSS", showModal: true })
      } else {
        fetch(conexiones.urlServicios + "wedo_solicitud/0", requestReferidoOptions)
          .then(response => response.json())
          .then(data => {

            console.log("activar_biometricos ", this.state.activar_biometricos)
            if (data["id"] != undefined) {
              this.props.guardaIdSolicitud(data["id"])
              this.props.setNuevaSolicitudServicio(data)
              if (!(this.state.nuevaSolicitud.producto != 86 ||this.state.nuevaSolicitud.producto != 85 || this.state.nuevaSolicitud.producto != 84 ||this.state.nuevaSolicitud.producto != 83 ||this.state.nuevaSolicitud.producto != 82 ||this.state.nuevaSolicitud.producto != 81 ||this.state.nuevaSolicitud.producto != 76 || this.state.nuevaSolicitud.producto != 77 || this.state.nuevaSolicitud.producto != 9 || this.state.nuevaSolicitud.producto != 10 || this.state.nuevaSolicitud.producto != 11)) {
                setTimeout(() => {
                  /*if (!this.state.activar_biometricos)if (!validaciones.validaFolioIMSS) {*/
                  this.props.clickAvanzar("datosSol");
                  //}
                }, 1500)
              } else {
                if (this.state.datosBanderaBiometricos) {
                  this.setState({ idSolicitudBiometricos: data["id"], activar_biometricos: true })
                  this.setState({ colorModal: this.state.colorSuccesModal,
                    tituloModal: 'Actualizacion correcta !',
                     mensajeError: "Recuerda que en convenios del IMSS se permanece en la pantalla de solicitud despues de crear la solicitud", showModal: true })
                } else {
                  setTimeout(() => {
                    /*if (!this.state.activar_biometricos)if (!validaciones.validaFolioIMSS) {*/
                    this.props.clickAvanzar("datosSol");
                    //}
                  }, 1500)
                }
              }


            } else {

              this.setState({ colorModal: this.state.colorErrorModal, tituloModal: 'Ocurrio un error!', mensajeError: this.formatoLog(data), showModal: true })
            }


          }).catch(error => {
            this.setState({ colorModal: this.state.colorErrorModal, tituloModal: 'Ocurrio un error!', mensajeError: error.toString(), showModal: true })

          })
      }
    }



  }


  consultaDatosCompletosZELLporRFC(rfc, idsNull) {
    let json = {
      "TreasuryId": rfc
    }
    const requestReferidoOptions = {
      method: "POST",
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(json)
    }
    fetch(conexiones.urlServicioZell + "zell_credito_consulta/0", requestReferidoOptions)
      .then(response => response.json())
      .then(data => {
        console.log("respuesta desde zell ", data)
        let respuesta = data[0]
        if (respuesta.ErrorCode == 0 && respuesta.ErrorDescription == null) {
          //se encontro la informacion 
          this.props.guardaYGeneraJsonsSecciones(respuesta, this.state.nuevaSolicitud)
          this.getPlazo(parseInt(this.state.nuevaSolicitud.tipoProducto))
        } else {
          //no se encontro la info 
          if (idsNull == true) { this.setNullsASolicitud() }
        }

      }).catch(error => {
        this.setState({ colorModal: this.state.colorErrorModal, tituloModal: 'Ocurrio un error al consultar en zell por rfc!', mensajeError: error.toString(), showModal: true })
      })
  }

  consultaDatosCompletosZellPorRFCValidaRefinanciamiento() {
    let json = {
      "vAppId": "DXN-" + this.state.folioRefinanciamiento
    }
    const requestReferidoOptions = {
      method: "POST",
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(json)
    }
    fetch(conexiones.urlServicioZell + "zell_credito_consulta/0", requestReferidoOptions)
      .then(response => response.json())
      .then(data => {
        console.log("respuesta desde zell ", data)
        let respuesta = data[0]
        if (respuesta.ErrorCode == 20015 && respuesta.ErrorDescription != null) {
          this.setState({ colorModal: this.state.colorErrorModal, tituloModal: "OOOOPPPS al consultar en zell no se encontro el rfc!", mensajeError: respuesta.ErrorDescription, showModal: true })
        } else if (respuesta.ErrorCode == 0 && respuesta.ErrorDescription == null) {
          console.log("rfc servicio ", respuesta.TreasuryId, " rfc pantalla ", this.state.nuevaSolicitud.rfc, "convenio servicio  ", parseInt(respuesta.AfiliateId), "id convenio en memoria  ", this.state.producto_id_en_zell)
          if (respuesta.TreasuryId == this.state.nuevaSolicitud.rfc && parseInt(respuesta.AfiliateId) == this.state.producto_id_en_zell) {
            this.setState({ colorModal: this.state.colorSuccesModal, tituloModal: "", mensajeError: "El folio ingresado es correcto", showModal: true })

            this.props.guardaYGeneraJsonsSecciones(respuesta, this.state.nuevaSolicitud)
            this.getPlazo(parseInt(this.state.nuevaSolicitud.tipoProducto))
          } else {
            this.setState({ colorModal: this.state.colorErrorModal, tituloModal: "OOOOPPPS al consultar en zell !", mensajeError: "El RFC no coincide con el Folio ingresado, Verifica la información", showModal: true })
          }

        }




      }).catch(error => {
        this.setState({ colorModal: this.state.colorErrorModal, tituloModal: 'Ocurrio un error al consultar en zell por rfc!', mensajeError: error.toString(), showModal: true })
      })
  }


  actualizaActuaTercero() {
    let json =
    {
      "nombre": this.state.datosTercero.nombre,
      "ape_paterno": this.state.datosTercero.primerApellido,
      "ape_materno": this.state.datosTercero.segundoApellido,
      "curp": this.state.datosTercero.curp,
      "rfc": this.state.datosTercero.rfc,
      "telefono": this.state.datosTercero.telefono,
      "email": this.state.datosTercero.email,
      "celular": this.state.datosTercero.celular,
      "fecha_nacimiento": this.state.datosTercero.fechaNacimiento,
      "lugar_nacimiento": this.state.datosTercero.lugarNacimiento,
      "nacionalidad": this.state.datosTercero.nacionalidad,
      "nombre_conyuge": this.state.datosTercero.primerNombreConyuge,
      "nombre_segundo_conyuge": this.state.datosTercero.segundoNombreConyuge,
      "ape_paterno_conyuge": this.state.datosTercero.primerNombreConyuge,
      "ape_materno_conyuge": this.state.datosTercero.segundoApellidoConyuge,
      "ocupacion_conyuge": this.state.datosTercero.ocupacionConyuge,
      "calle_numero": this.state.datosTercero.calle,
      "colonia": this.state.datosTercero.colonia,
      "municipio": this.state.datosTercero.municipio,
      "cp": this.state.datosTercero.cp,
      "ciudad": this.state.datosTercero.ciudad,
      "estado": this.state.datosTercero.estado,
      "pais": this.state.datosTercero.pais,
      "entre_calles": this.state.datosTercero.entreCalles,
      "solicitud": this.props.idSolicitud,
      "afavor": this.state.datosTercero.aFavor,
      "genero": this.state.datosTercero.genero,
      "edocivil": this.state.datosTercero.edoCivil,
      "user_id": this.props.idUsuario
    }
    console.log("el json ", json)
    const requestReferidoOptions = {
      method: "PUT",
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(json)
    }
    fetch(conexiones.urlServiciosActualiza + "wedo_actua_tercero/" + this.state.datosTercero.id, requestReferidoOptions)
      .then(response => response.json())
      .then(data => {

        if (data["id"] != undefined) {

          console.log("respuesta del servicio al crea un actua por tercero ", data)
          //  this.props.clickAvanzar("datosSol");
        } else {
          this.setState({ colorModal: this.state.colorErrorModal, tituloModal: 'Ocurrio un error!', mensajeError: this.formatoLog(data), showModal: true })
        }


      }).catch(error => {
        this.setState({ colorModal: this.state.colorErrorModal, tituloModal: 'Ocurrio un error!', mensajeError: error.toString(), showModal: true })

      })
  }



  creaActuaTercero() {
    let json =
    {
      "nombre": this.state.datosTercero.nombre,
      "ape_paterno": this.state.datosTercero.primerApellido,
      "ape_materno": this.state.datosTercero.segundoApellido,
      "curp": this.state.datosTercero.curp,
      "rfc": this.state.datosTercero.rfc,
      "telefono": this.state.datosTercero.telefono,
      "email": this.state.datosTercero.email,
      "celular": this.state.datosTercero.celular,
      "fecha_nacimiento": this.state.datosTercero.fechaNacimiento,
      "lugar_nacimiento": this.state.datosTercero.lugarNacimiento,
      "nacionalidad": this.state.datosTercero.nacionalidad,
      "nombre_conyuge": this.state.datosTercero.primerNombreConyuge,
      "nombre_segundo_conyuge": this.state.datosTercero.segundoNombreConyuge,
      "ape_paterno_conyuge": this.state.datosTercero.primerNombreConyuge,
      "ape_materno_conyuge": this.state.datosTercero.segundoApellidoConyuge,
      "ocupacion_conyuge": this.state.datosTercero.ocupacionConyuge,
      "calle_numero": this.state.datosTercero.calle,
      "colonia": this.state.datosTercero.colonia,
      "municipio": this.state.datosTercero.municipio,
      "cp": this.state.datosTercero.cp,
      "ciudad": this.state.datosTercero.ciudad,
      "estado": this.state.datosTercero.estado,
      "pais": this.state.datosTercero.pais,
      "entre_calles": this.state.datosTercero.entreCalles,
      "solicitud": this.props.idSolicitud,
      "afavor": this.state.datosTercero.aFavor,
      "genero": this.state.datosTercero.genero,
      "edocivil": this.state.datosTercero.edoCivil,
      "user_id": this.props.idUsuario
    }
    console.log("el json ", json)
    const requestReferidoOptions = {
      method: "POST",
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(json)
    }
    fetch(conexiones.urlServicios + "wedo_actua_tercero/0", requestReferidoOptions)
      .then(response => response.json())
      .then(data => {

        if (data["id"] != undefined) {

          console.log("respuesta del servicio al crea un actua por tercero ", data)
          //   this.props.clickAvanzar("datosSol");
        } else {
          this.setState({ colorModal: this.state.colorErrorModal, tituloModal: 'Ocurrio un error!', mensajeError: this.formatoLog(data), showModal: true })
        }


      }).catch(error => {
        this.setState({ colorModal: this.state.colorErrorModal, tituloModal: 'Ocurrio un error!', mensajeError: error.toString(), showModal: true })

      })
  }

  consultaCreditoMorosidad(nuevaSolicitud) {
    let json = {
      "AfiliateId": nuevaSolicitud.producto_id != undefined ? nuevaSolicitud.producto_id : nuevaSolicitud.producto,
      "TreasuryId": nuevaSolicitud.rfc
    }
    const requestReferidoOptions = {
      method: "POST",
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(json)
    }

    fetch(conexiones.urlServicioZell + "zell_credito_base/0", requestReferidoOptions)
      .then(response => response.json())
      .then(data => {


        if (data.length > 0) {
          this.setState({ esMoroso: true })
          if (data[0].ErrorCode == 0 /*&& data[0].Pending > 0*/) {// moroso
            setTimeout(() => {
              this.setState({ showModal: false })
              let stackModalVencido = []
              for (var i = 0; i < data.length; i++) {
                let modalSaldoVencido = {
                  nombreCliente: data[i].FirstName + ' ' + (data[i].SecondName == null ? '' : data[i].SecondName) + ' ' + data[i].FLastName + ' ' + data[i].SLastName,
                  creditoID: data[i].CreditId,
                  saldoPendiente: data[i].Pending,
                  total: data[i].Pending,
                  referencia: data[i].PayReference
                }
                stackModalVencido.push(
                  <tr>
                    <td colSpan={1}>{modalSaldoVencido.creditoID}</td>
                    <td colSpan={1}>{"$" + modalSaldoVencido.saldoPendiente}</td>
                    <td colSpan={1}>{modalSaldoVencido.referencia}</td>
                  </tr>
                )
              }




              this.setState({ modalSaldoVencido: stackModalVencido, showModalSaldo: true })

            }, 1000);
          }
        } else {
          this.setState({ esMoroso: false })
        }



      }).catch(error => {
        this.setState({ colorModal: this.state.colorErrorModal, tituloModal: 'Ocurrio un error al consumir Zell !', mensajeError: error.toString(), showModal: true })

      });


  }


  consultaZellCreditoBase() {
    let validaciones = this.state.validaciones
    let nuevaSoicitud = this.state.nuevaSolicitud

    if (nuevaSoicitud.curp == null) {
      validaciones.validaCURP = true;
      this.setState({ validaciones: validaciones })
    } else {
      if (this.state.banderaCurp) {
        //  validaciones.validaCURP = false;
        //  this.setState({ validaciones: validaciones })
      } else {
        validaciones.validaCURP = true;
        this.setState({ validaciones: validaciones })
      }
    }

    if (nuevaSoicitud.rfc == null) {
      validaciones.validaRFC = true;
      this.setState({ validaciones: validaciones })
    } else {
      if (this.state.banderaRFC) {
        //  validaciones.validaRFC = false;
        //  this.setState({ validaciones: validaciones })
      } else {
        validaciones.validaRFC = true;
        this.setState({ validaciones: validaciones })
      }
    }

    if (nuevaSoicitud.sector == null) {
      validaciones.validaSector = true;
      this.setState({ validaciones: validaciones })
    } else {
      validaciones.validaSector = false;
      this.setState({ validaciones: validaciones })
    }


    if (nuevaSoicitud.producto == null) {
      validaciones.validaProducto = true;
      this.setState({ validaciones: validaciones })
    } else {
      validaciones.validaProducto = false;
      this.setState({ validaciones: validaciones })
    }


    if (validaciones.validaCURP == false && validaciones.validaRFC == false && validaciones.validaSector == false && validaciones.validaProducto == false) {
      this.setState({ banderaSolicitudProcesada: true })
      let json = {
        "AfiliateId": this.state.nuevaSolicitud.producto,
        "TreasuryId": this.state.nuevaSolicitud.rfc
      }
      const requestReferidoOptions = {
        method: "POST",
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(json)
      }

      let opcionesRefServicio = []
      let modalRefiFlash = {
        credito: null,
        monto: null,
        descuento: null,
        opcionesRef: [],
        opcionSeleccionada: null
      }
      this.setState({ opcionesRefServicio: opcionesRefServicio, modalRefiFlash: modalRefiFlash })
      fetch(conexiones.urlServicioZell + "zell_credito_base/0", requestReferidoOptions)
        .then(response => response.json())
        .then(data => {


          this.setState({ colorModal: this.state.colorSuccesModal, tituloModal: '', mensajeError: "Se ha procesado tu solicitud", showModal: true })
          if (data.length > 0) {
            if (data[0].ErrorCode == 0 /*&& data[0].Pending > 0*/) {// moroso
              setTimeout(() => {
                console.log(data)
                this.setState({ showModal: false })
                let stackModalVencido = []
                for (var i = 0; i < data.length; i++) {
                  let modalSaldoVencido = {
                    nombreCliente: data[i].FirstName + ' ' + (data[i].SecondName == null ? '' : data[i].SecondName) + ' ' + data[i].FLastName + ' ' + data[i].SLastName,
                    creditoID: data[i].CreditId,
                    saldoPendiente: data[i].Pending,
                    total: data[i].Pending,
                    referencia: data[i].PayReference
                  }
                  stackModalVencido.push(
                    <tr>
                      <td colSpan={1}>{modalSaldoVencido.creditoID}</td>
                      <td colSpan={1}>{"$" + modalSaldoVencido.saldoPendiente}</td>
                      <td colSpan={1}>{modalSaldoVencido.referencia}</td>
                    </tr>
                  )
                }




                this.setState({ modalSaldoVencido: stackModalVencido, showModalSaldo: true })
                //  this.consultaZellCreditoRefinanciamiento();
              }, 1000);
            } else if (data[0].ErrorCode == 0 && data[0].Pending == 0) {// no moroso
              this.consultaZellCreditoRefinanciamiento(false);
            }
          } else {
            this.consultaZellCreditoRefinanciamiento(false);
          }

          this.procesaTuSolicitud()
          this.setState({ showAvanzar: true })

        }).catch(error => {
          this.setState({ colorModal: this.state.colorErrorModal, tituloModal: 'Ocurrio un error al consumir Zell !', mensajeError: error.toString(), showModal: true })

        });
    }






  }


  onChangeValuesOpcionesRef = e => {
    let modalRefiFlash = this.state.modalRefiFlash
    modalRefiFlash.opcionSeleccionada = e.target.value
    console.log(modalRefiFlash)
    let data = this.state.opcionesRefServicio
    let opcionesRef = []
    for (let i = 0; i < data.length; i++) {

      if (data[i].refinanciamientos[0].Payments == this.state.modalRefiFlash.opcionSeleccionada) {
        modalRefiFlash.monto = data[i].refinanciamientos[0].Credit
        modalRefiFlash.descuento = data[i].refinanciamientos[0].Payment
        modalRefiFlash.RefinanceId = data[i].refinanciamientos[0].RefinanceId
        modalRefiFlash.plazo = data[i].refinanciamientos[0].Payments
        modalRefiFlash.montoDeudor = data[i].refinanciamientos[0].Liquidate
        modalRefiFlash.depositoAlCliente = data[i].refinanciamientos[0].Deposit
        modalRefiFlash.plazoID = data[i].refinanciamientos[0].plazoid
        modalRefiFlash.tipoProdID = data[i].refinanciamientos[0].tipoprodid
        modalRefiFlash.periodoID = data[i].refinanciamientos[0].periodoid
      }

      opcionesRef.push(
        <tr>
          <td colSpan={1}>
            <Form.Check
              type={"radio"}
              //      name="group1"
              checked={data[i].refinanciamientos[0].Payments == this.state.modalRefiFlash.opcionSeleccionada ? true : false}
              value={data[i].refinanciamientos[0].Payments}
              onChange={this.onChangeValuesOpcionesRef}
            />
          </td>
          <td colSpan={1}>{"$" + data[i].refinanciamientos[0].Deposit}</td>
          <td colSpan={1}>{data[i].refinanciamientos[0].Payments}</td>
          <td colSpan={1}>{"$" + data[i].refinanciamientos[0].Credit} </td>
          <td colSpan={1}>{"$" + data[i].refinanciamientos[0].Payment}</td>

        </tr>
      )
    }
    modalRefiFlash.opcionesRef = opcionesRef

    this.setState({ modalRefiFlash: modalRefiFlash })
  }

  consultaZellCreditoRefinanciamiento(lanzaModal) {
    let json = {
      "PersonId": 0,
      "FullName": "",
      "BirthDate": "",
      "TreasuryId": this.state.nuevaSolicitud.rfc,
      "LegalId": "",
      "convenio": this.state.nuevaSolicitud.producto
    }

    const requestReferidoOptions = {
      method: "POST",
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(json)
    }
    fetch(conexiones.urlServicioZell + "zell_credito_refinanciamiento/0", requestReferidoOptions)
      .then(response => response.json())
      .then(data => {

        let opcionesRef = []

        console.log("imprimiendo refinanciamiento ", data)
        if (data[0].refinanciamientos[0].CreditId != 0) {


          for (let i = 0; i < data.length; i++) {
            opcionesRef.push(
              <tr>
                <td colSpan={1}>
                  <Form.Check
                    type={"radio"}
                    //      name="group1"
                    checked={data[i].refinanciamientos[0].Payments == this.state.modalRefiFlash.opcionSeleccionada ? true : false}
                    value={data[i].refinanciamientos[0].Payments}
                    onChange={this.onChangeValuesOpcionesRef}
                  />
                </td>
                <td colSpan={1}>{"$" + data[i].refinanciamientos[0].Deposit}</td>
                <td colSpan={1}>{data[i].refinanciamientos[0].Payments}</td>
                <td colSpan={1}>{"$" + data[i].refinanciamientos[0].Credit} </td>
                <td colSpan={1}>{"$" + data[i].refinanciamientos[0].Payment}</td>

              </tr>
            )
          }

          let modalRefiFlash = {
            credito: data[0].refinanciamientos[0].CreditId,
            monto: "",
            descuento: "",
            opcionesRef: opcionesRef
          }

          if (lanzaModal == true) {
            let nuevaSolicitud = this.state.nuevaSolicitud
            nuevaSolicitud.tipoProducto = "2"
            nuevaSolicitud.periodo = "1"
            let validaciones = this.state.validaciones
            validaciones.validaTipoProducto = true
            validaciones.validaPeriodo = true
            this.setState({ nuevaSolicitud: nuevaSolicitud, validaciones })
            //  this.getCatalogo(conexiones.urlPytonParametros + "catalogo_tipo_producto/0", "selectTipoProducto")
            this.getCatalogo(conexiones.urlPytonParametros + "catalogo_vtipo_producto_producto/" + this.state.nuevaSolicitud.producto, "selectTipoProducto")

            // this.getCatalogo(conexiones.urlPytonParametros + "catalogo_periodo/0", "selectPeriodo")
            this.getCatalogo(conexiones.urlPytonParametros + "catalogo_periodo/" + this.state.nuevaSolicitud.producto, "selectPeriodo")

          }

          this.setState({ modalRefiFlash: modalRefiFlash, showModalRefiFlash: lanzaModal, opcionesRefServicio: data })

        }

      }).catch(error => {
        this.setState({ colorModal: this.state.colorErrorModal, tituloModal: 'Ocurrio un error al consumir Zell  Refinanciamiento !', mensajeError: error.toString(), showModal: true })

      });


  }


  aceptaRefinanciamiento() {
    let json =
    {
      "RefinanceId": this.state.modalRefiFlash.RefinanceId,
      "CreditId": this.state.modalRefiFlash.credito,
      "Payments": this.state.modalRefiFlash.plazo
    }
    console.log("el json ", json)
    const requestReferidoOptions = {
      method: "POST",
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(json)
    }
    fetch(conexiones.urlServicioZell + "zell_acepta_refiflash/0", requestReferidoOptions)
      .then(response => response.json())
      .then(data => {

        if (data[0].ErrorCode == 0 && data[0].AppId != undefined && data[0].AppId != null || data[0].AppId != 0) {
          let nuevaSolicitud = this.state.nuevaSolicitud
          nuevaSolicitud.capPago = this.state.modalRefiFlash.descuento
          nuevaSolicitud.monto_deudor = this.state.modalRefiFlash.montoDeudor
          nuevaSolicitud.montoSolicitado = this.state.modalRefiFlash.monto
          nuevaSolicitud.descuento = this.state.modalRefiFlash.descuento
          nuevaSolicitud.deposito_cliente = this.state.modalRefiFlash.depositoAlCliente
          nuevaSolicitud.tipoProducto = this.state.modalRefiFlash.tipoProdID
          nuevaSolicitud.plazo = this.state.modalRefiFlash.plazoID
          nuevaSolicitud.periodo = this.state.modalRefiFlash.periodoID
          console.log("salida acptar refini ", nuevaSolicitud)
          let validaciones = this.state.validaciones
          validaciones.validaCapPago = false
          validaciones.validaMontoSolicitado = false
          validaciones.validaTipoProducto = false
          validaciones.validaPeriodo = false

          this.setState({
            folioRefinanciamiento: data[0].AppId,
            aceptoRefinanciamiento: true,
            validaciones: validaciones,
            nuevaSolicitud: nuevaSolicitud,
            colorModal: this.state.colorSuccesModal, mensajeError: "!Tu petición de refinanciamineto ha sido enviada con exito¡", showModal: true
          })
          setTimeout(() => {
            this.getCatalogo(conexiones.urlPytonParametros + "catalogo_vtipo_producto_producto/" + this.state.nuevaSolicitud.producto, "selectTipoProducto")
            this.getCatalogo(conexiones.urlPytonParametros + "catalogo_periodo/" + this.state.nuevaSolicitud.producto, "selectPeriodo")
            this.getPlazo(parseInt(this.props.nuevaSolicitud.tipoProducto))
          }, 100)


        }


      }).catch(error => {
        this.setState({ colorModal: this.state.colorErrorModal, tituloModal: 'Ocurrio un error al consumir Zell la aceptación de Refinanciamiento !', mensajeError: error.toString(), showModal: true })

      })

  }


  consultaCapacidad2() {

    let json =
    {
      "solicitud": 0,
      "producto": this.state.nuevaSolicitud.producto,
      "cpf": this.state.nuevaSolicitud.rfc
    }
    console.log("el json ", json)
    const requestReferidoOptions = {
      method: "POST",
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(json)
    }
    fetch(conexiones.ulrPortales + "wedo_portal/0", requestReferidoOptions)
      .then(response => response.json())
      .then(data => {

        console.log("consulta capacidad ", data)
        if (data.length > 0) {
          let res = data[0]
          if (res.sucesso == true) {
            let nuevoEstado = this.state.nuevaSolicitud
           
            nuevoEstado.capPago = this.mascaraDecimales(res.valorMargem + "")
            if (res.valorMargem < 0) {
              nuevoEstado.capPago = "-" + nuevoEstado.capPago
            }
            this.setState({ nuevaSoicitud: nuevoEstado, banderaBotonReservar: true, banderaBotonConsultaCapYCapPago: true })
            this.consultaMontoSolicitadoDescuentoYTasaPorPlazo(this.state.nuevaSolicitud.plazo, "capPago")
          } else {
            this.setState({ colorModal: "#FF6B00", tituloModal: 'Portales consulta capacidad ', mensajeError: res.mensagem, showModal: true })
          }
        } else {
          this.setState({ colorModal: this.state.colorErrorModal, tituloModal: 'Ocurrio un error en la respuesta de Portales consulta capacidad !', mensajeError: "Ha devuelto el array vacio", showModal: true })
        }

      }).catch(error => {
        this.setState({ colorModal: this.state.colorErrorModal, tituloModal: 'Ocurrio un error al consumir Portales consulta capacidad !', mensajeError: error.toString(), showModal: true })

      })


  }

  consultaCapacidad() {

    if (this.state.tipoCalculoIdPortal != 4) {
      let json =
      {
        "solicitud": 0,
        "producto": this.state.nuevaSolicitud.producto,
        "cpf": this.state.nuevaSolicitud.rfc
      }
      console.log("el json ", json)
      const requestReferidoOptions = {
        method: "POST",
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(json)
      }
      fetch(conexiones.ulrPortales + "wedo_portal/0", requestReferidoOptions)
        .then(response => response.json())
        .then(data => {

          console.log("consulta capacidad ", data)
          if (data.length > 0) {
            let res = data[0]
            if (res.sucesso == true) {
              let nuevoEstado = this.state.nuevaSolicitud
              let cap= res.valorMargem.toString()
              let decimales=cap.split(".")[1]
              console.log("decimales ",decimales)
              if(decimales == undefined){
               console.log("es un numero entero  ",this.mascaraDecimales(cap+".00"))
               nuevoEstado.capPago=this.mascaraDecimales(cap+".00")
              }else if(decimales != undefined && decimales.length == 1){
                console.log("es un numero con decimal pero con un solo digito decimal   ",this.mascaraDecimales(cap+"0"))
                nuevoEstado.capPago=this.mascaraDecimales(cap+"0")
              }else if(decimales != undefined && decimales.length == 2){
                console.log("es un numero con decimal pero 2 digito decimal   ",this.mascaraDecimales(cap))
                nuevoEstado.capPago=this.mascaraDecimales(cap)
              }else if(decimales != undefined && decimales.length > 2){
                console.log("es un numero con decimal pero mas de 3  digitos decimal   ",this.mascaraDecimales(cap.split(".")[0]+"."+decimales.substring(0, 2)))
                nuevoEstado.capPago=this.mascaraDecimales(cap.split(".")[0]+"."+decimales.substring(0, 2))
              }
        

              if (res.valorMargem < 0) {
                nuevoEstado.capPago = "-" + nuevoEstado.capPago
              }
              this.setState({ nuevaSoicitud: nuevoEstado, banderaBotonReservar: true, banderaBotonConsultaCapYCapPago: true })
              this.consultaMontoSolicitadoDescuentoYTasaPorPlazo(this.state.nuevaSolicitud.plazo, "capPago")
            } else {
              this.setState({ colorModal: "#FF6B00", tituloModal: 'Portales consulta capacidad ', mensajeError: res.mensagem, showModal: true })
            }
          } else {
            this.setState({ colorModal: this.state.colorErrorModal, tituloModal: 'Ocurrio un error en la respuesta de Portales consulta capacidad !', mensajeError: "Ha devuelto el array vacio", showModal: true })
          }

        }).catch(error => {
          this.setState({ colorModal: this.state.colorErrorModal, tituloModal: 'Ocurrio un error al consumir Portales consulta capacidad !', mensajeError: error.toString(), showModal: true })

        })

    } else {
      this.getDocumentoNomiPay(this.state.nuevaSolicitud.rfc)
      setTimeout(() => {
        this.setState({ showModalNomiPay: true })
      }, 500)
    }
  }

  getDocumentoNomiPay(rfc) {
    fetch(conexiones.urlSeviciosArchivos + "/doc_solicitud/" + rfc)
      .then(response => response.json())
      .then(data => {
        if (data.length > 0) {

          this.setState({ nombreArchivoNomiPay: data[0].documento_ruta.split("/")[data[0].documento_ruta.split("/").length - 1] })
        } else {
          this.setState({ nombreArchivoNomiPay: "" })
        }
      }).catch(error => {
        this.setState({ colorModal: this.state.colorErrorModal, tituloModal: 'Ocurrio un error al consumir documento de Nomipay !', mensajeError: error.toString(), showModal: true })
      })
  }


  quitarEspacioAlFinal(campo, inputString) {
    console.log("entrando a quitar espacio ", campo, inputString)
    if (inputString != null) {
      // Verificar si el string termina con un espacio
      if (inputString.endsWith(' ')) {
        // Si sí termina con espacio, eliminarlo y devolver el nuevo string
        console.log("cero pasada ", datosSolicitante["" + campo + ""].length)

        inputString = inputString.substring(0, inputString.length - 1);
        console.log("priera pasada ", datosSolicitante["" + campo + ""].length)

      }

      // Si no termina con espacio, devolver el string original
      let datosSolicitante = this.state.nuevaSolicitud
      datosSolicitante["" + campo + ""] = inputString
      console.log("segunda pasada ", datosSolicitante["" + campo + ""].length)
      this.setState({ nuevaSolicitud: datosSolicitante });
    }

  }

  validaLongitudFolioRefinanciamiento = e => {
    if (this.state.folioRefinanciamiento != null && this.state.folioRefinanciamiento != undefined) {
      if (this.state.folioRefinanciamiento.length < 6) {
        this.setState({ folioRefinanciamiento: '' })
      }
    }

  }

  getLeyendasBiometricos() {
    fetch(conexiones.urlServicios + "wedo_aviso_privacidad/" + this.state.nuevaSolicitud.producto)
      .then(response => response.json())
      .then(data => {
        console.log("respuesta de las leyendas ", data)
        this.setState({
          avisoPrivacidadDXN: data[0].aviso_privacidad, terminosVerificacionCrediticia: data[0].verificacion_crediticia1,
          consentimientoINE: data[0].consentimiento_ine, terminosVerificacionCrediticia2: data[0].verificacion_crediticia2, showModalBiometricos: true
        })

      }).catch(error => {
        this.setState({ colorModal: this.state.colorErrorModal, tituloModal: 'Ocurrio un error al consumir Portales consulta capacidad !', mensajeError: error.toString(), showModal: true })

      })

  }

  onChangeValuesBanderasAvisoPrivacidadBiometricos = e => {
    let datosModalesBiometricos = this.state.datosModalesBiometricos
    if (e.target.name == "aceptoAviso1") {
      datosModalesBiometricos.aceptoAviso1 = e.target.checked
      this.setState({ datosModalesBiometricos: datosModalesBiometricos })
    }
    if (e.target.name == "aceptoTerminos1") {
      datosModalesBiometricos.aceptoTerminos1 = e.target.checked
      this.setState({ datosModalesBiometricos: datosModalesBiometricos })
    }

    if (e.target.name == "aceptoVerificacionCrediticia") {
      datosModalesBiometricos.aceptoVerificacionCrediticia = e.target.checked
      this.setState({ datosModalesBiometricos: datosModalesBiometricos })
    }

    if (e.target.name == "emailCodigo") {
      if (this.state.expresionEmail.test(e.target.value)) {
        datosModalesBiometricos.validaEmail = false
      } else {
        datosModalesBiometricos.validaEmail = true
      }
      datosModalesBiometricos["emailCodigo"] = e.target.value
      this.setState({ datosModalesBiometricos: datosModalesBiometricos })
    }

    if (e.target.name == "codigo1") {
      if (e.target.value.length == 0 || e.target.value.length == 1) {
        datosModalesBiometricos.codigo1 = e.target.value
        this.setState({ datosModalesBiometricos: datosModalesBiometricos })
        this.codigo2Ref.current.focus()
      }
    }

    if (e.target.name == "codigo2") {
      if (e.target.value.length == 0 || e.target.value.length == 1) {
        datosModalesBiometricos.codigo2 = e.target.value
        this.setState({ datosModalesBiometricos: datosModalesBiometricos })
        this.codigo3Ref.current.focus()
      }

    }

    if (e.target.name == "codigo3") {
      if (e.target.value.length == 0 || e.target.value.length == 1) {
        datosModalesBiometricos.codigo3 = e.target.value
        this.setState({ datosModalesBiometricos: datosModalesBiometricos })
        this.codigo4Ref.current.focus()
      }
    }

    if (e.target.name == "codigo4") {
      if (e.target.value.length == 0 || e.target.value.length == 1) {
        datosModalesBiometricos.codigo4 = e.target.value
      }
      this.setState({ datosModalesBiometricos: datosModalesBiometricos })
    }


  }

  obtenCodigoDeVerificacion() {
    if (!this.state.datosModalesBiometricos.validaEmail) {
      let datosModalesBiometricos = this.state.datosModalesBiometricos
      datosModalesBiometricos.banderaEnvioCodigo = true
      this.setState({ datosModalesBiometricos: datosModalesBiometricos })
      let json =
      {
        "solicitud": this.props.idSolicitud,
        "telefono": "555555555",
        "email": this.state.datosModalesBiometricos.emailCodigo
      }
      const requestReferidoOptions = {
        method: "POST",
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(json)
      }
      fetch(conexiones.urlServicios + "wedo_codigo_verificacion/0", requestReferidoOptions)
        .then(response => response.json())
        .then(data => {
          if (data.length > 0) {
            let datosModalesBiometricos = this.state.datosModalesBiometricos
            console.log(data)
            datosModalesBiometricos.codigoVerificacionServicio = data[0].codigo
            datosModalesBiometricos.banderaEnvioCodigo = true
            this.setState({ datosModalesBiometricos: datosModalesBiometricos })

          } else {
            this.setState({ colorModal: this.state.colorErrorModal, tituloModal: 'Ocurrio un error en la respuesta al envio del codigo de verificación !', mensajeError: "Se ha devuelto un array vacio", showModal: true })
            datosModalesBiometricos.validaEmail = false
            this.setState({ datosModalesBiometricos: datosModalesBiometricos })
          }
        }).catch(error => {
          datosModalesBiometricos.validaEmail = false
          this.setState({ datosModalesBiometricos: datosModalesBiometricos })
          this.setState({ colorModal: this.state.colorErrorModal, tituloModal: 'Ocurrio un error al consumir el envio del codigo de verificación !', mensajeError: error.toString(), showModal: true })

        })
    }

  }


  validaVerificacion() {
    let codigoCampos = this.state.datosModalesBiometricos.codigo1.toString() + this.state.datosModalesBiometricos.codigo2.toString() + this.state.datosModalesBiometricos.codigo3.toString() + this.state.datosModalesBiometricos.codigo4.toString()
    console.log(codigoCampos, this.state.datosModalesBiometricos.codigoVerificacionServicio)
    if (this.state.datosModalesBiometricos.codigoVerificacionServicio.length != 0) {
      if (codigoCampos.length == 4) {
        if (codigoCampos == this.state.datosModalesBiometricos.codigoVerificacionServicio) {
          this.setState({ showModalVerificacionCrediticia2: false, showModalPermisos: true })
        } else {
          this.setState({ colorModal: this.state.colorErrorModal, tituloModal: 'UPSS !', mensajeError: "El codigo de verificacion no coincide ", showModal: true })
        }
      } else {
        this.setState({ colorModal: this.state.colorErrorModal, tituloModal: 'UPSS !', mensajeError: "Debes de llenar los 4 campos del codigo de verificación ", showModal: true })
      }

    } else {
      this.setState({ colorModal: this.state.colorErrorModal, tituloModal: 'UPSS !', mensajeError: "Debes pedir el envío del codigo de verificación ", showModal: true })
    }


  }


  onChangeSelectArchivoNomiPay = () => event => {
    let archivo = event.target.files[0]
    if (archivo != undefined) {
      this.guardaArchivoNomiPay(archivo)
    }
  }


  async guardaArchivoNomiPay(archivo) {
    let formData = new FormData();
    formData.append("rfc", this.state.nuevaSolicitud.rfc)
    formData.append("documento_ruta", archivo)
    formData.append("cargado", 1)
    formData.append("documento", 123)
    formData.append("solicitud", 0)
    try {
      const res = await fetch(
        conexiones.urlSeviciosArchivos + "/doc_solicitud/",
        {
          method: 'POST',
          body: formData,
        },
      );
      const resData = await res.json();
      if (resData.id != undefined) {
        this.setState({ nombreArchivoNomiPay: archivo.name })
      }

    } catch (error) {
      this.setState({ colorModal: this.state.colorErrorModal, tituloModal: '¡Ocurrio un error !', mensajeError: "Favor de cargar nuevamente el archivo.", showModal: true })

    }

  }


  render() {
    return (
      <div>
        <Row>
          <Col xs={12}>
            <span style={{ font: "bold", color: "#FF4000" }}>Sol: {this.state.idBDSolicitud} DXN:{this.state.idDXN} </span>

          </Col>
        </Row>

        <Row>
          <Col xs={6}>
            {

              this.state.nuevaSolicitud.tipoProducto != "2" && this.state.nuevaSolicitud.tipoProducto != "5" ?
                <Form.Group className="mb-6" controlId="">
                  <Form.Label > {this.state.folio == 0 ? '' : 'Folio ZELL: DXN-' + this.state.folio}</Form.Label>
                </Form.Group>
                :
                <Row lg={3} md={3} xs={12}>
                  <Form.Group className="mb-12" controlId="">
                    <Form.Label > {'Folio ZELL: DXN-'} </Form.Label>
                    <Form.Control disabled={this.props.banderaSolicitud} type="text" placeholder="" maxLength={6} onBlur={this.validaLongitudFolioRefinanciamiento}
                      value={this.state.folioRefinanciamiento}
                      onChange={this.onChangeValuesFolioDXN} name="folioBusqueda" />
                  </Form.Group>
                  <Col lg={1} md={1} xs={6}>
                    <Form.Group className="mb-6" controlId="">
                      <Form.Label> &nbsp; </Form.Label>
                      {/* <Button
                      style={{ width: '100%', backgroundColor: '#FF6B00', borderColor: '#FF6B00', borderRadius: '40px' }}
                      disabled={this.state.folioRefinanciamiento != undefined ?  this.state.folioRefinanciamiento.length < 6:true}
                      type="button" onClick={this.consultaDatosCompletosZellPorRFCValidaRefinanciamiento}
                    >
                      <SearchIcon/>
                    </Button>*/}
                    </Form.Group>
                  </Col>
                </Row>
            }
          </Col>

          <Col xs={6}>
            {

              this.props.idPuesto == 4 && this.state.nuevaSolicitud.estatus == null || this.state.nuevaSolicitud.estatus == 1 || this.state.nuevaSolicitud.estatus == 8 ?

                <Form.Group className="mb-6" controlId="">
                  <Form.Label>Asesor: <span style={{ color: 'red' }}>*</span></Form.Label>
                  {this.state.selectAsesores}

                </Form.Group>
                : ''

            }
          </Col>
        </Row>

        <br></br>
        {
          this.state.esMoroso ?
            <Row>
              <Col xs={6} md={3} lg={3}>
                <Button
                  style={{ width: '100%', backgroundColor: '#FF6B00', borderColor: '#FF6B00', borderRadius: '40px' }}
                  type="button" onClick={() => { this.consultaCreditoMorosidad(this.state.nuevaSolicitud) }}
                >
                  Saldo vencido
                </Button>

              </Col>
            </Row> : ""
        }


        <hr className="solid" />

        <Row>
          <Col lg={3} md={3} xs={12}>
            <Form.Group className="mb-6" controlId="">
              <Form.Label>CURP: <span style={{ color: 'red' }}>*</span></Form.Label>
              <Form.Control disabled={this.state.banderaSolicitudProcesada} type="text" placeholder="" maxLength={18} value={this.state.nuevaSolicitud.curp} name="curp"
                onChange={this.onChangeValues} />
              {this.state.validaciones.validaCURP == true ? (
                <Form.Label><span style={{ color: 'red' }}>El campo debe cumplir con la validación del CURP</span></Form.Label>
              ) : null}
            </Form.Group>
          </Col>
          <Col lg={3} md={3} xs={12}>

            <Form.Group className="mb-6" controlId="">
              <Form.Label>RFC (con homoclave): <span style={{ color: 'red' }}>*</span></Form.Label>
              <Form.Control type="text" placeholder="" maxLength={13} value={this.state.nuevaSolicitud.rfc} name="rfc"
                onChange={this.onChangeValues} disabled={this.state.banderaSolicitudProcesada} />
              {this.state.validaciones.validaRFC == true ? (
                <Form.Label><span style={{ color: 'red' }}>El campo debe cumplir con la validación del RFC</span></Form.Label>
              ) : null}
            </Form.Group>


          </Col>
          <Col lg={3} md={3} xs={12}>
            <Form.Group className="mb-6" controlId="">
              <Form.Label>Sector: <span style={{ color: 'red' }}>*</span></Form.Label>
              {this.state.selectSector}
              {this.state.validaciones.validaSector == true ? (
                <Form.Label><span style={{ color: 'red' }}>Se debe seleccionar un sector</span></Form.Label>
              ) : null}
            </Form.Group>
          </Col>
          <Col lg={3} md={3} xs={12}>
            <Form.Group className="mb-6" controlId="">
              <Form.Label>Convenio: <span style={{ color: 'red' }}>*</span></Form.Label>
              {this.state.selectProducto}
              {this.state.validaciones.validaProducto == true ? (
                <Form.Label><span style={{ color: 'red' }}>Se debe seleccionar un convenio</span></Form.Label>
              ) : null}
            </Form.Group>
          </Col>
        </Row>

        {
          this.state.activar_folioIMSS ?
            <Row>
              <Col lg={3} md={3} xs={0}>
                <Form.Group className="mb-6" controlId="">
                  <Form.Label>&nbsp; </Form.Label>
                </Form.Group>
              </Col>
              <Col lg={3} md={3} xs={0}>
                <Form.Group className="mb-6" controlId="">
                  <Form.Label>&nbsp; </Form.Label>
                </Form.Group>
              </Col>
              <Col lg={3} md={3} xs={0}>
                <Form.Group className="mb-6" controlId="">
                  <Form.Label>&nbsp; </Form.Label>

                </Form.Group>
              </Col>
              <Col lg={3} md={3} xs={12}>
                <Form.Group className="mb-6" controlId="">
                  <Form.Label>Folio IMSS : <span style={{ color: 'red' }}>*</span></Form.Label>
                  <Form.Control type="text" placeholder="" maxLength={50} value={this.state.nuevaSolicitud.folio_imss} name="folio_imss"
                    onChange={this.onChangeValues} disabled={this.props.banderaSolicitud} />
                  {this.state.validaciones.validaFolioIMSS == true ? (
                    <Form.Label><span style={{ color: 'red' }}>El campo es requerido</span></Form.Label>
                  ) : null}
                </Form.Group>
              </Col>
            </Row> : ''
        }


        <br />
        <Row>
          <Col lg={3} md={3} xs={12}>
            <img src={procesar}
              style={{ width: '150px', height: "60px", borderRadius: '40px' }}
              disabled={this.state.banderaSolicitudProcesada /*this.state.banderaCurp == false || this.state.banderaRFC == false || this.state.banderaSector == false || this.state.banderaConvenio == false*/}

              onClick={this.props.banderaSolicitud ? () => { console.log("mkk") } : this.consultaZellCreditoBase}

            />
          </Col>




        </Row>
        <hr className="solid" />

        {this.state.showAvanzar == true ?
          <div>
            <Row>
              <br />
              <Col lg={2} md={2} xs={12}>
                <Form.Group className="mb-2" controlId="">
                  <Form.Label >Fecha de ingreso: <span style={{ color: 'red' }}>*</span></Form.Label>
                  <Form.Control disabled={this.props.banderaSolicitud}
                    dateFormat="dd/mm/yyyy"
                    type="date"
                    placeholder="dd-mm-yyyy"
                    value={this.state.nuevaSolicitud["fechaIngreso"]}
                    onChange={(e) => {
                      console.log("fecha een le evento  ", e.target.value)
                      let nuevaSolicitud = this.state.nuevaSolicitud

                      nuevaSolicitud["fechaIngreso"] = e.target.value
                      this.setState({ nuevaSolicitud: nuevaSolicitud })
                    }}
                    max={this.state.formattedDate}
                  >

                  </Form.Control>
                  {/* <DatePicker style={{ width: "100%" }}
                    className="form-control"
                    dateFormat="dd-MM-yyyy"
                    name="fechaIngreso"
                    selected={this.state.nuevaSolicitud.fechaIngreso}
                    onChange={(e) => {
                      let nuevaSolicitud = this.state.nuevaSolicitud

                      nuevaSolicitud["fechaIngreso"] = e
                      this.setState({ nuevaSolicitud: nuevaSolicitud })
                    }}
                    excludeDateIntervals={[
                      { start: new Date().getTime() - this.state.tresMeses, end: new Date("01/01/2100") },
                    ]}

                  />*/}
                </Form.Group>
              </Col>

              <Col lg={2} md={2} xs={12}>
                <Form.Group className="mb-6" controlId="">
                  <Form.Label>Actua por: <span style={{ color: 'red' }}>*</span></Form.Label>
                  {this.state.selectActuaPor}
                  {this.state.validaciones.validaActuaPor == true ? (
                    <Form.Label><span style={{ color: 'red' }}>Se debe seleccionar una opción</span></Form.Label>
                  ) : null}
                </Form.Group>
              </Col>

              <Col lg={2} md={2} xs={12}>
                <Form.Group className="mb-6" controlId="">
                  <Form.Label>Tipo de producto: <span style={{ color: 'red' }}>*</span></Form.Label>
                  {this.state.selectTipoProducto}
                  {this.state.validaciones.validaTipoProducto == true ? (
                    <Form.Label><span style={{ color: 'red' }}>Se debe seleccionar un prodcuto</span></Form.Label>
                  ) : null}
                </Form.Group>
              </Col>
              <Col lg={2} md={2} xs={12}>
                <Form.Group className="mb-6" controlId="">
                  <Form.Label>Periodo: <span style={{ color: 'red' }}>*</span></Form.Label>
                  {this.state.selectPeriodo}
                  {this.state.validaciones.validaPeriodo == true ? (
                    <Form.Label><span style={{ color: 'red' }}>Se debe seleccionar un periodo</span></Form.Label>
                  ) : null}
                </Form.Group>
              </Col>
              <Col lg={2} md={2} xs={12}>
                <Form.Group className="mb-6" controlId="">
                  <Form.Label>Plazo: <span style={{ color: 'red' }}>*</span></Form.Label>
                  {this.state.selectPlazo}
                  {this.state.validaciones.validaPlazo == true ? (
                    <Form.Label><span style={{ color: 'red' }}>Se debe seleccionar un plazo</span></Form.Label>
                  ) : null}
                </Form.Group>
              </Col>
              <Col lg={2} md={2} xs={12}>
                <Form.Group className="mb-6" controlId="">
                  <Form.Label>Capacidad de pago : <span style={{ color: 'red' }}>*</span></Form.Label>
                  <Form.Control type="text" placeholder="" name="capPago"
                    onBlur={() => { this.consultaMontoSolicitadoDescuentoYTasaPorPlazo(this.state.nuevaSolicitud.plazo, "capPago") }}
                    onChange={this.onChangeValues} disabled={this.state.banderaBotonConsultaCapYCapPago || this.props.banderaSolicitud} value={this.state.nuevaSolicitud.capPago} />
                  {this.state.validaciones.validaCapPago == true ? (
                    <Form.Label><span style={{ color: 'red' }}>Se debe ingresar un monto</span></Form.Label>
                  ) : null}
                </Form.Group>
              </Col>
            </Row>
            <br />
            <Row>
              <Col lg={2} md={2} xs={12}>
                {
                  this.state.activar_portal && this.state.nuevaSolicitud.tipoProducto == 1 ?
                    <img src={botonConsultaCapacidad} style={{ width: '150px', height: "60px", borderRadius: '40px' }} disabled={this.state.banderaBotonConsultaCapYCapPago || this.props.banderaSolicitud} onClick={this.state.banderaBotonConsultaCapYCapPago ? () => { console.log("") } : this.consultaCapacidad} />
                    : ""
                }

              </Col>

              {
                this.state.banderaBotonReservar == true ?
                  <Col lg={2} md={2} xs={12}>
                    <Button disabled={this.props.banderaSolicitud} style={{ width: '100%', backgroundColor: '#2E64FE', borderColor: '#2E64FE', borderRadius: '40px' }} onClick={() => { this.setState({ showModalReservar: true }) }} type="button">
                      <FastForwardIcon /> Reservar
                    </Button>
                  </Col>
                  : ''
              }

              {this.state.opcionesRefServicio.length > 0 && (this.state.nuevaSolicitud.estatus == null || this.state.nuevaSolicitud.estatus == 1) ?
                <Col lg={1} md={1} xs={12}>
                  <Button disabled={this.props.banderaSolicitud} onClick={() => { this.consultaZellCreditoRefinanciamiento(true) }} style={{ width: '100%', backgroundColor: '#2E64FE', borderColor: '#2E64FE', borderRadius: '40px' }} type="button">
                    <FastForwardIcon /> RefiFlash
                  </Button>
                </Col> : ''}

            </Row>
            <br />


            {/* aqui se inyecta el nuevo form  */}
            {
              this.state.nuevaSolicitud.actuaPor == "2" ? this.creaFormularioActuaPorTercero() : ''
            }


            <br></br>

            <Row>
              <Col lg={3} md={3} xs={12}>
                <Form.Group className="mb-6" controlId="">
                  <Form.Label>Destino de crédito: <span style={{ color: 'red' }}>*</span></Form.Label>
                  {this.state.selectDestinoCredito}
                  {this.state.validaciones.validaDestinoCredito == true ? (
                    <Form.Label><span style={{ color: 'red' }}>Se debe seleccionar un destino de crédito</span></Form.Label>
                  ) : null}
                </Form.Group>
              </Col>


              {
                this.state.aceptoRefinanciamiento == true || this.state.nuevaSolicitud.tipoProducto == "2" ?
                  <Col lg={3} md={3} xs={12}>
                    <Form.Group className="mb-6" controlId="">
                      <Form.Label>Monto deudor: <span style={{ color: 'red' }}>*</span></Form.Label>
                      <Form.Control disabled={this.props.banderaSolicitud} type="text" placeholder="" name="monto_deudor" value={this.state.nuevaSolicitud.monto_deudor} onChange={this.onChangeValues} />
                      {this.state.validaciones.validaMontoDeudor == true ? (
                        <Form.Label><span style={{ color: 'red' }}>Se debe ingresar un monto</span></Form.Label>
                      ) : null}
                    </Form.Group>
                  </Col>

                  : ''
              }


              {
                this.state.aceptoRefinanciamiento == true || this.state.nuevaSolicitud.tipoProducto == "2" ?
                  <Col lg={3} md={3} xs={12}>
                    <Form.Group className="mb-6" controlId="">
                      <Form.Label>Depósito al cliente : <span style={{ color: 'red' }}>*</span></Form.Label>
                      <Form.Control disabled={this.props.banderaSolicitud} type="text" placeholder="" name="deposito_cliente" value={this.state.nuevaSolicitud.deposito_cliente} onChange={this.onChangeValues} />
                      {this.state.validaciones.validaDepoCliente == true ? (
                        <Form.Label><span style={{ color: 'red' }}>Se debe ingresar un monto</span></Form.Label>
                      ) : null}
                    </Form.Group>
                  </Col>

                  : ''
              }



            </Row>
            <hr className="solid" />

            <Row>
              <Col lg={2} md={2} xs={12}>
                <Form.Group className="mb-6" controlId="">
                  <Form.Label>Monto solicitado: <span style={{ color: 'red' }}>*</span></Form.Label>
                  <Form.Control disabled={this.props.banderaSolicitud} type="text" onBlur={() => { this.consultaMontoSolicitadoDescuentoYTasaPorPlazo(this.state.nuevaSolicitud.plazo, "montoSolicitado") }} placeholder="" name="montoSolicitado" onChange={this.onChangeValues} value={this.state.nuevaSolicitud.montoSolicitado} />
                  {this.state.validaciones.validaMontoSolicitado == true ? (
                    <Form.Label><span style={{ color: 'red' }}>Se debe ingresar un monto</span></Form.Label>
                  ) : null}
                </Form.Group>
              </Col>
              <Col lg={2} md={2} xs={12}>
                <Form.Group className="mb-6" controlId="">
                  <Form.Label>Descuentos: <span style={{ color: 'red' }}>*</span></Form.Label>
                  <Form.Control disabled={this.props.banderaSolicitud} type="number" placeholder="" value={this.state.nuevaSolicitud.descuento} />
                </Form.Group>
              </Col>
              <Col lg={2} md={2} xs={12}>
                <Form.Group className="mb-6" controlId="">
                  <Form.Label>Tasa anual: <span style={{ color: 'red' }}>*</span></Form.Label>
                  <Form.Control disabled={this.props.banderaSolicitud} type="number" placeholder="" value={this.state.nuevaSolicitud.tasaAnual} />
                </Form.Group>
              </Col>
            </Row>

            <hr className="solid" />

            <Row>
              <Col lg={3} md={3} xs={12}>
                <Form.Group className="mb-6" controlId="">
                  <Form.Label>Sucursal: <span style={{ color: 'red' }}>*</span></Form.Label>
                  {this.state.selectSucursal}
                  {this.state.validaciones.validaSucursal == true ? (
                    <Form.Label><span style={{ color: 'red' }}>Se debe seleccionar una sucursal</span></Form.Label>
                  ) : null}
                </Form.Group>
              </Col>
            </Row>
            <br />
            <Row>
              <Col lg={3} md={3} xs={3}>
                {this.props.idSolicitud == null ?
                  <img src={botonGuardarSolicitud}
                    style={{ width: '150px', height: "60px", borderRadius: '40px' }}
                    onClick={
                      this.creaSolicitud
                    }
                    type="button"
                  /> :
                  <img src={botonActulizar}
                    style={{ width: '150px', height: "60px", borderRadius: '40px' }}
                    disabled={this.props.banderaSolicitud}
                    onClick={
                      this.props.banderaSolicitud ? () => { console.log("mkk") } : this.actualizaSolicitud
                    }
                  />
                }
              </Col>

              <Col lg={6} md={6} xs={3}>
                &nbsp;
              </Col>

              {this.state.activar_biometricos && this.state.datosBanderaBiometricos ?
                <Col lg={3} md={3} xs={3}>
                  <Button onClick={() => {
                    this.getLeyendasBiometricos() 
                  }

                  } style={{ backgroundColor: '#FF6B00', borderColor: '#FF6B00', width: '150px', height: "60px", borderRadius: '40px' }} >
                    Toma de Biométricos
                  </Button>
                </Col> : ""
              }
            </Row>
          </div> : ''}

        {/*  backgroundColor: '#FF6B00' */}
        <Modal show={this.state.showModalNomiPay} onHide={() => { this.setState({ showModalNomiPay: false }) }} centered size="xs">

          <Modal.Header style={{ backgroundColor: "#FF6B00", textAlign: 'center' }} closeButton >
            <Modal.Title style={{ textAlign: 'center', color: "white" }}>Consulta NomiPay </Modal.Title>
          </Modal.Header>

          <Modal.Body >
            <>
              <span style={{ textAlign: 'left', fontWeight: "bold" }}>Selecciona un archivo para subir: {this.state.nombreArchivoNomiPay}</span>

              <br></br>
              <div style={{ "position": "relative", "display": "inline-block" }}>
                <input
                  type="file"
                  id={"custom-file-input"}
                  style={{ display: 'none' }}
                  name={""}
                  accept=".jpg, .jpeg, .pdf, .jfif, .mp4, .mov"
                  onChange={
                    this.onChangeSelectArchivoNomiPay()
                  }
                />
                <label htmlFor={"custom-file-input"} style={{
                  "display": "inline-block",
                  "padding": "5px 5px",
                  "backgroundColor": this.state.nombreArchivoNomiPay.length == 0 ? "#2E64FE" : this.state.colorSuccesModal,
                  "color": "white",
                  "cursor": "pointer",
                  "borderRadius": "5px",
                  "border": "none",
                  "fontSize": "15px"
                }}
                >
                  Seleccionar archivo
                </label>
              </div>

            </>
          </Modal.Body>

          <Modal.Footer>
            <Button style={{ backgroundColor: '#FF6B00', borderColor: '#FF6B00', borderRadius: '40px' }} onClick={() => {
              this.setState({ showModalNomiPay: false })
            }}>
              Cerrar
            </Button>

            <Button variant="primary" style={{ borderRadius: '40px' }} onClick={() => {
              this.consultaCapacidad2()
            }}>
              Consulta Capacidad
            </Button>
          </Modal.Footer>

        </Modal>


        <Modal show={this.state.showModalBiometricosIframe} onHide={() => { this.setState({ showModalBiometricosIframe: false }) }} centered size="lg" fullscreen={true}>
          <Modal.Header style={{ backgroundColor: "white", textAlign: 'center' }} closeButton >
            <Modal.Title style={{ textAlign: 'center' }}></Modal.Title>
            <Button style={{ backgroundColor: '#FF6B00', borderColor: '#FF6B00', borderRadius: '40px' }} onClick={() => {
              this.setState({ showModalBiometricosIframe: false })
              this.props.clickAvanzar("datosSol");
            }}>
              Regresar a Datos Solicitante
            </Button>
          </Modal.Header>
          <Modal.Body style={{ textAlign: 'center' }}>
            <>
              <Row style={{ height: "100%", width: "100%" }}>
                {/*<iframe id="myIframe5" height={"100%"} width={"100%"} src={"https://serviciosdxnine.mx/escaneo_ine_prod/?idSolicitud=" + this.state.idSolicitudBiometricos} allow="camera *;"></iframe>*/}
                <iframe id="myIframe5" height={"100%"} width={"100%"} src={"https://serviciosdxnine.mx/flujo_servicios/?idSolicitud=" + this.state.idSolicitudBiometricos} allow="camera *; geolocation *; ambient-light-sensor *;"></iframe>
              </Row>
            </>
          </Modal.Body>
          <Modal.Footer>
            <Button style={{ backgroundColor: '#FF6B00', borderColor: '#FF6B00', borderRadius: '40px' }} onClick={() => {
              this.setState({ showModalBiometricosIframe: false })
              this.props.clickAvanzar("datosSol");
            }}>
              Regresar a Datos Solicitante
            </Button>
          </Modal.Footer>
        </Modal>



        <Modal show={this.state.showModalPermisos} onHide={() => { this.setState({ showModalPermisos: false }) }} centered size="lg">
          <Modal.Header style={{ backgroundColor: "white", textAlign: 'center' }} closeButton>
            <Modal.Title style={{ textAlign: 'center' }}></Modal.Title>
          </Modal.Header>
          <Modal.Body style={{ textAlign: 'center' }}>
          <Row>
              <Col xs={12} md={12} lg={12}>
              <img style={{height:"80%",width:"100%"}} src={permisos1} />
              </Col>
          </Row>    
          {/*  <Row>
              <Col xs={12} md={12} lg={12}>
                <span>Durante el proceso te solicitaremos los siguientes permisos en tu dispositivo.</span>
              </Col>
            </Row>

            <br />
            <br />


            <Row>
              <Col xs={12} md={12} lg={12}>
                <PlaceIcon />
                <span>Ubicación: para saber dónde estás firmando</span>
              </Col>
            </Row>
            <br />


            <Row>
              <Col xs={12} md={12} lg={12}>
                <CameraAltIcon />
                <span>Cámara: para saber que eres tú</span>
              </Col>
            </Row>
            <br />

            <Row>
              <Col xs={12} md={12} lg={12}>
                <MicIcon />
                <span>Micrófono: para saber que eres tú</span>
              </Col>
            </Row>
            <br />
            <br />
          */}

            <hr />
            <Row>
              <Col xs={12} md={12} lg={12}>
              <img style={{height:"80%",width:"100%"}} src={permisos2} />
              </Col>
          </Row>    
            {/*
            <Row>
              <Col xs={12} md={12} lg={12}>
                <span>Recomendaciones generales</span>
              </Col>
            </Row>

            <br />
            <br />
            <Row>
              <Col xs={12} md={12} lg={12}>
                <RecentActorsIcon />
                <span>Ten a la mano tu Credencial de Elector</span>
              </Col>
            </Row>
            <br />
            <Row>
              <Col xs={12} md={12} lg={12}>
                <MasksIcon />
                <span> Retira cualquier accesorio que impida visualizar el rostro</span>
              </Col>
            </Row>
            <br />
            <Row>
              <Col xs={12} md={12} lg={12}>
                <SignalWifiStatusbar4BarIcon />
                <span>
                  Verifica que cuentes con una conexión estable de internet.</span>
              </Col>
            </Row>
            <br />
            <Row>
              <Col xs={12} md={12} lg={12}>
                <AccessTimeIcon />
                <span>
                  Considera dedicar el tiempo necesario para este proceso</span>
              </Col>
            </Row>

            */}
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => { this.setState({ showModalPermisos: false, showModalInfoCapturaHuellas: true }) }}>
              OK
            </Button>
          </Modal.Footer>
        </Modal>



        <Modal show={this.state.showModalInfoCapturaHuellas} onHide={() => { this.setState({ showModalInfoCapturaHuellas: false }) }} centered size="lg">
          <Modal.Header style={{ backgroundColor: "white", textAlign: 'center' }} closeButton>
            <Modal.Title style={{ textAlign: 'center' }}></Modal.Title>
          </Modal.Header>
          <Modal.Body style={{ textAlign: 'center' }}>
         
          <Row>
              <Col xs={12} md={12} lg={12}>
              <img style={{height:"80%",width:"100%"}} src={huellas1} />
              </Col>
          </Row>    
          <hr />
          <Row>
              <Col xs={12} md={12} lg={12}>
              <img style={{height:"100%",width:"100%"}} src={huellas2} />
              </Col>
          </Row>    
            
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => { this.setState({ showModalInfoCapturaHuellas: false, showModalBiometricosIframe: true }) }}>
              OK
            </Button>
          </Modal.Footer>
        </Modal>











        <Modal show={this.state.showModal} onHide={() => { this.setState({ showModal: false }) }} centered size="lg">
          <Modal.Header style={{ backgroundColor: this.state.colorModal, textAlign: 'center' }} closeButton>
            <Modal.Title style={{ textAlign: 'center', color: "white" }}>{this.state.tituloModal}</Modal.Title>
          </Modal.Header>
          <Modal.Body style={{ textAlign: 'center' }}>{this.state.mensajeError}</Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => { this.setState({ showModal: false }) }}>
              Cerrar
            </Button>

          </Modal.Footer>
        </Modal>

        <Modal
          show={this.state.showModalVerificacionCrediticia2}
          onHide={() => this.setState({ showModalVerificacionCrediticia2: false })}
          centered
          size="lg"
          fullscreen="sm-down"
        >
          <Modal.Header style={{ backgroundColor: "white", textAlign: 'center' }} closeButton>
            <Modal.Title style={{ textAlign: 'center' }}>Verificación crediticia</Modal.Title>
          </Modal.Header>
          <Modal.Body style={{ textAlign: 'center' }}>
            {/*<Row>
             
              <Col lg={12} md={12} xs={12}>
                <div
                  style={{
                    height: '300px',
                    overflowY: 'scroll',
                    border: '1px solid #ccc',
                    padding: '10px',
                    marginTop: '10px',
                    textAlign: 'left'
                  }}
                >

                  <p>
                    {this.state.terminosVerificacionCrediticia2}
                  </p>
                </div>
              </Col>

            </Row>
            <br /><br />*/}
            <Row>
              <Col lg={12} md={12} xs={12}>
                <Row>
                  <Col lg={8} md={8} xs={8}>
                    <Form.Group className="mb-6" controlId="">
                      <Form.Label>Email al cual recibiras el codigo: <span style={{ color: 'red' }}>*</span></Form.Label>
                      <Form.Control type="email" placeholder="" name="emailCodigo" onChange={this.onChangeValuesBanderasAvisoPrivacidadBiometricos} value={this.state.datosModalesBiometricos.emailCodigo} />
                      {this.state.datosModalesBiometricos.validaEmail == true ? (
                        <Form.Label><span style={{ color: 'red' }}>Se debe ingresar un email válido</span></Form.Label>
                      ) : null}
                    </Form.Group>
                  </Col>
                  <Col lg={4} md={4} xs={4}>
                    <Form.Group className="mb-6" controlId="">
                      <Form.Label><span >&nbsp;</span></Form.Label>
                      <Button style={{ width: "100%" ,backgroundColor: '#FF6B00', borderColor: '#FF6B00'}} variant="secondary" disabled={this.state.datosModalesBiometricos.validaEmail || this.state.datosModalesBiometricos.banderaEnvioCodigo}
                        onClick={this.obtenCodigoDeVerificacion}>
                        Enviar codigo
                      </Button>
                      <Form.Label><span >&nbsp;</span></Form.Label>
                    </Form.Group>

                  </Col>
                </Row>
              </Col>
            </Row>
            {
              this.state.datosModalesBiometricos.banderaEnvioCodigo == true ?
                <>
                  <br />
                  <br />
                  <Row>
                    <Col lg={12} md={12} xs={12}>
                      <Form.Label style={{ color: "#ff6b00" }}>{"Su código de verificación se ha enviado al correo digitado, por favor verifique."} </Form.Label>
                    </Col>
                  </Row>
                </> : ""
            }
            <br />
            <br />
            <Row>
              <Col lg={12} md={12} xs={12}>
                <Form.Label>{"Ingresa el código de verificación que enviamos al correo " + this.state.datosModalesBiometricos.emailCodigo} </Form.Label>
              </Col>
            </Row>



            <Row>
              <Col lg={12} md={12} xs={12}>
                <Row>
                  <Col lg={3} md={3} xs={3}>
                    <Form.Group className="mb-6" controlId="">
                      <Form.Control type="number" placeholder="" name="codigo1" onChange={this.onChangeValuesBanderasAvisoPrivacidadBiometricos} value={this.state.datosModalesBiometricos.codigo1} />
                    </Form.Group>
                  </Col>

                  <Col lg={3} md={3} xs={3}>
                    <Form.Group className="mb-6" controlId="">
                      <Form.Control type="number" placeholder="" ref={this.codigo2Ref} name="codigo2" onChange={this.onChangeValuesBanderasAvisoPrivacidadBiometricos} value={this.state.datosModalesBiometricos.codigo2} />
                    </Form.Group>
                  </Col>

                  <Col lg={3} md={3} xs={3}>
                    <Form.Group className="mb-6" controlId="">
                      <Form.Control type="number" placeholder="" ref={this.codigo3Ref} name="codigo3" onChange={this.onChangeValuesBanderasAvisoPrivacidadBiometricos} value={this.state.datosModalesBiometricos.codigo3} />
                    </Form.Group>
                  </Col>

                  <Col lg={3} md={3} xs={3}>
                    <Form.Group className="mb-6" controlId="">
                      <Form.Control type="number" placeholder="" ref={this.codigo4Ref} name="codigo4" onChange={this.onChangeValuesBanderasAvisoPrivacidadBiometricos} value={this.state.datosModalesBiometricos.codigo4} />
                    </Form.Group>
                  </Col>
                </Row>
              </Col>
            </Row>

            <br />

            <Row>
              <Col lg={6} md={6} xs={6}>
                <Form.Label>{"Si no recibiste el código dar clic "}<span onClick={this.obtenCodigoDeVerificacion} style={{ fontWeight: "bold", color: "orange", cursor: "pointer" }}>Reenviar código</span> </Form.Label>
              </Col>
            </Row>

          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary"
              onClick={this.validaVerificacion}>
              Aceptar
            </Button>

          </Modal.Footer>
        </Modal>

        <Modal
          show={this.state.showModalConsentimientoINE}
          onHide={() => this.setState({ showModalConsentimientoINE: false })}
          centered
          size="lg"
          fullscreen="sm-down"
        >
          <Modal.Header style={{ backgroundColor: "white", textAlign: 'center' }} closeButton>
            <Modal.Title style={{ textAlign: 'center' }}>Consentimiento de validación de datos ante el INE​</Modal.Title>
          </Modal.Header>
          <Modal.Body style={{ textAlign: 'center' }}>
            <Row>
              <Col lg={12} md={12} xs={12}>
                <div
                  style={{
                    height: '300px',
                    overflowY: 'scroll',
                    border: '1px solid #ccc',
                    padding: '10px',
                    marginTop: '10px',
                    textAlign: 'left'
                  }}
                >

                  <p>
                    {this.state.consentimientoINE}
                  </p>
                </div>
              </Col>
            </Row>
            <br /><br />
            <Row>
              <Col lg={12} md={12} xs={12}>
                <Row>
                  <Col lg={1} md={1} xs={1}>
                    <Form>
                      <Form.Check
                        type="checkbox"
                        name="aceptoVerificacionCrediticia"
                        checked={this.state.datosModalesBiometricos.aceptoVerificacionCrediticia}
                        onChange={this.onChangeValuesBanderasAvisoPrivacidadBiometricos}
                      />
                    </Form>
                  </Col>
                  <Col lg={10} md={10} xs={10}>
                    He leído y acepto los Términos y Condiciones y cláusula de medios electrónicos tales como NIP
                  </Col>
                  <Col lg={1} md={1} xs={1}>&nbsp;</Col>
                </Row>
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" disabled={!this.state.datosModalesBiometricos.aceptoVerificacionCrediticia}
              onClick={() => { this.setState({ showModalConsentimientoINE: false, showModalVerificacionCrediticia2: true }) }}>
              Aceptar
            </Button>

          </Modal.Footer>
        </Modal>


        <Modal
          show={this.state.showModalVerificacionCrediticia}
          onHide={() => this.setState({ showModalVerificacionCrediticia: false })}
          centered
          size="lg"
          fullscreen="sm-down"
        >
          <Modal.Header style={{ backgroundColor: "white", textAlign: 'center' }} closeButton>
            <Modal.Title style={{ textAlign: 'center' }}>Verificación crediticia</Modal.Title>
          </Modal.Header>
          <Modal.Body style={{ textAlign: 'center' }}>
            <Row>
              <Col lg={12} md={12} xs={12}>
                <div
                  style={{
                    height: '300px',
                    overflowY: 'scroll',
                    border: '1px solid #ccc',
                    padding: '10px',
                    marginTop: '10px',
                    textAlign: 'left'
                  }}
                >

                  <p>
                    {this.state.terminosVerificacionCrediticia}
                  </p>
                </div>
              </Col>
            </Row>
            <br /><br />
            <Row>
              <Col lg={12} md={12} xs={12}>
                <Row>
                  <Col lg={1} md={1} xs={1}>
                    <Form>
                      <Form.Check
                        type="checkbox"
                        name="aceptoVerificacionCrediticia"
                        checked={this.state.datosModalesBiometricos.aceptoVerificacionCrediticia}
                        onChange={this.onChangeValuesBanderasAvisoPrivacidadBiometricos}
                      />
                    </Form>
                  </Col>
                  <Col lg={10} md={10} xs={10}>
                    He leído y acepto los Términos y Condiciones y cláusula de medios electrónicos tales como NIP
                  </Col>
                  <Col lg={1} md={1} xs={1}>&nbsp;</Col>
                </Row>
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" disabled={!this.state.datosModalesBiometricos.aceptoVerificacionCrediticia}
              onClick={() => { this.setState({ showModalVerificacionCrediticia: false, showModalConsentimientoINE: true }) }}>
              Aceptar
            </Button>

          </Modal.Footer>
        </Modal>




        <Modal
          show={this.state.showModalBiometricos}
          onHide={() => this.setState({ showModalBiometricos: false })}
          centered
          size="lg"
          fullscreen="sm-down" // Use fullscreen on small devices
        >
          <Modal.Header style={{ backgroundColor: "#ccccc3", textAlign: 'center' }} closeButton>
            <Modal.Title style={{ textAlign: 'center' }}>
              <Row >
                <Col xs={12} md={12} lg={12}><span>Para CréditoExpress tu</span></Col>
              </Row>
              <Row>
                <Col xs={12} md={12} lg={12}><span> privacidad es lo más importante</span></Col>
              </Row>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body style={{ textAlign: 'center' }}>
            <Row>
              <Col lg={12} md={12} xs={12}>
                <div
                  style={{
                    height: '300px',
                    overflowY: 'scroll',
                    border: '1px solid #ccc',
                    padding: '10px',
                    marginTop: '10px',
                    textAlign: 'left' // Align text to the left for better readability
                  }}
                >
                  <h5>Aviso de privacidad de DXN</h5>
                  <p>
                    {this.state.avisoPrivacidadDXN}
                  </p>
                </div>
                <br />
                <Row>
                  <Col lg={1} md={1} xs={1}> <img style={{height:"75%",width:"75%"}} src={IconoPersonSig} /></Col>
                  <Col lg={10} md={10} xs={10}>
                  Tu información personal será utilizada para verificar tu identidad y evaluar el crédito solicitado.
                  </Col>
                  <Col lg={1} md={1} xs={1}>&nbsp;</Col>
                </Row>
                <br></br>
                <Row>
                  <Col lg={1} md={1} xs={1}> <img style={{height:"75%",width:"75%"}} src={IconoPersonaBloqueada} /> </Col>
                  <Col lg={10} md={10} xs={10}>
                  CréditoExpress no compartirá tus datos con ninguna otra institución.
                        </Col>
                  <Col lg={1} md={1} xs={1}>&nbsp;</Col>
                </Row>


                <br></br>

                <Row>
                  <Col lg={1} md={1} xs={1}>
                    <Form>
                      <Form.Check
                        style={{border:"2px solid #000", padding:"5px", borderRadius:"5px"}}
                        type="checkbox"
                        name="aceptoAviso1"
                        checked={this.state.datosModalesBiometricos.aceptoAviso1}
                        onChange={this.onChangeValuesBanderasAvisoPrivacidadBiometricos}
                      />
                    </Form>
                  </Col>
                  <Col lg={10} md={10} xs={10} style={{textDecoration:"underline",fontWeight:"bold"}}>
                    He leído y acepto aviso de privacidad
                  </Col>
                  <Col lg={1} md={1} xs={1}>&nbsp;</Col>
                </Row>
                <br></br>
                <Row>
                  <Col lg={1} md={1} xs={1}>
                    <Form.Check // prettier-ignore
                    style={{border:"2px solid #000", padding:"5px", borderRadius:"5px"}}
                      type="checkbox"
                      name="aceptoTerminos1"
                      checked={this.state.datosModalesBiometricos.aceptoTerminos1}
                      onChange={this.onChangeValuesBanderasAvisoPrivacidadBiometricos}

                    />
                  </Col>
                  <Col lg={10} md={10} xs={10} style={{textDecoration:"underline",fontWeight:"bold"}}>
                    He leído y acepto términos y condiciones
                  </Col>
                  <Col lg={1} md={1} xs={1}>&nbsp;</Col>
                </Row>
              </Col>
              <Col lg={6} md={6} xs={6}>

                {/*
                      <Row>
                        <Col lg={1} md={1} xs={1}> <PersonIcon /></Col>
                        <Col lg={10} md={10} xs={10}>
                          Tu información personal se usará solo para verificar tu identidad y para evaluar el préstamo
                        </Col>
                        <Col lg={1} md={1} xs={1}>&nbsp;</Col>
                      </Row>
                      <br></br>
                      <Row>
                        <Col lg={1} md={1} xs={1}> <LockIcon /></Col>
                        <Col lg={10} md={10} xs={10}>
                          DXN no compartirá tus datos con empresas ajenas
                        </Col>
                        <Col lg={1} md={1} xs={1}>&nbsp;</Col>
                      </Row>


                      <br></br>

                      <Row>
                        <Col lg={1} md={1} xs={1}>
                          <Form>
                            <Form.Check
                              type="checkbox"
                              name="aceptoAviso1"
                              checked={this.state.datosModalesBiometricos.aceptoAviso1}
                              onChange={this.onChangeValuesBanderasAvisoPrivacidadBiometricos}
                            />
                          </Form>
                        </Col>
                        <Col lg={10} md={10} xs={10}>
                          He leído y acepto aviso de privacidad
                        </Col>
                        <Col lg={1} md={1} xs={1}>&nbsp;</Col>
                      </Row>
                      <br></br>
                      <Row>
                        <Col lg={1} md={1} xs={1}>
                          <Form.Check // prettier-ignore
                            type="checkbox"
                            name="aceptoTerminos1"
                            checked={this.state.datosModalesBiometricos.aceptoTerminos1}
                            onChange={this.onChangeValuesBanderasAvisoPrivacidadBiometricos}

                          />
                        </Col>
                        <Col lg={10} md={10} xs={10}>
                          He leído y acepto términos y condiciones
                        </Col>
                        <Col lg={1} md={1} xs={1}>&nbsp;</Col>
                      </Row>*/}

              </Col>
            </Row>
          </Modal.Body>




          <Modal.Footer>
            <Button variant="secondary" disabled={!this.state.datosModalesBiometricos.aceptoAviso1 || !this.state.datosModalesBiometricos.aceptoTerminos1}
              onClick={() => { this.setState({ showModalBiometricos: false,/*showModalVerificacionCrediticia2:true*/ showModalVerificacionCrediticia: true }) }}>
              Aceptar
            </Button>

          </Modal.Footer>
        </Modal>

        <Modal show={this.state.showModalReservar} onHide={() => { this.setState({ showModalReservar: false }) }} centered size="lg">
          <Modal.Header style={{ backgroundColor: "white", textAlign: 'center' }} closeButton>
            <Modal.Title style={{ textAlign: 'center' }}>Ingresa los datos solicitados</Modal.Title>
          </Modal.Header>
          <Modal.Body >
            <Row>
              <span style={{ fontWeight: "bold" }}>Ingresa cuenta Clabe :</span>
              <Form.Control type="text" placeholder="" name="clabeTarjeta" onChange={this.onChangeValuesClabeReservar} value={this.state.cuentaClabeReservar} />
            </Row>
            <br />
            {
              this.state.convenioTieneToken ?
                <Row>
                  <span style={{ fontWeight: "bold" }}>Ingresa Token :</span>
                  <Form.Control type="text" placeholder="" name="tokenReservar" onChange={this.onChangeValuesTokenReservar} value={this.state.tokenReservar} />
                </Row>
                : ''
            }

          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => { this.reservaCredito() }}>
              OK
            </Button>

            <Button variant="secondary" onClick={() => { this.setState({ showModalReservar: false }) }}>
              Cerrar
            </Button>

          </Modal.Footer>
        </Modal>


        <Modal show={this.state.showModalSaldo} onHide={() => {
          this.setState({ showModalSaldo: false })
          this.consultaZellCreditoRefinanciamiento()
        }} centered size="lg">
          <Modal.Body style={{ textAlign: 'center' }}>

            <Row>
              <Col lg={1} md={1} xs={1}>&nbsp;</Col>
              <Col lg={10} md={10} xs={10}>
                <img src={iconX} />
              </Col>
              <Col lg={1} md={1} xs={1}>&nbsp;</Col>
            </Row>

            <Row>
              <Col lg={1} md={1} xs={1}>&nbsp;</Col>
              <Col lg={10} md={10} xs={10}>
                <h2 style={{ fontSize: '1.875em', color: '#595959', wordWrap: 'break-word' }}>Saldo Vencido</h2>
              </Col>
              <Col lg={1} md={1} xs={1}>&nbsp;</Col>
            </Row>
            <Row>
              <Col lg={1} md={1} xs={1}>&nbsp;</Col>
              <Col lg={10} md={10} xs={10}>
                <span>Cliente:</span><span>{this.state.modalSaldoVencido.nombreCliente}</span>
              </Col>
              <Col lg={1} md={1} xs={1}>&nbsp;</Col>
            </Row>
            <br></br>
            <Row>
              <Col lg={1} md={1} xs={1}>&nbsp;</Col>
              <Col lg={10} md={10} xs={10}>
                <Table bordered style={{ textAlign: 'center' }} >
                  <thead>
                    <tr>
                      <th>Credito&nbsp;&nbsp;</th>
                      <th>Saldo</th>
                      <th>Referencia</th>
                    </tr>
                  </thead>
                  <tbody style={{ textAlign: 'center' }}>

                    {this.state.modalSaldoVencido}

                    {/*<tr>
                      <td colSpan={1}>{this.state.modalSaldoVencido.creditoID}</td>
                      <td colSpan={1}>{"$" + this.state.modalSaldoVencido.saldoPendiente}</td>
                      <td colSpan={1}>{this.state.modalSaldoVencido.referencia}</td>

                    </tr>*/}

                    { /* <tr>
                      <td colSpan={1}>Total</td>
                      <td colSpan={2}>{"$" + this.state.modalSaldoVencido.total}</td>

                    </tr>*/}

                  </tbody>
                </Table>
              </Col>
              <Col lg={1} md={1} xs={1}>&nbsp;</Col>

            </Row>
            <br></br>
            <Row>
              <Col lg={1} md={1} xs={1}>&nbsp;</Col>
              <Col lg={10} md={10} xs={10}>
                <span style={{ fontWeight: 'bolder' }}>Realiza tu pago en:</span>
              </Col>
              <Col lg={1} md={1} xs={1}>&nbsp;</Col>
            </Row>

            <Row>
              <Col lg={1} md={1} xs={1}>&nbsp;</Col>
              <Col lg={10} md={10} xs={10}>
                <span style={{ fontWeight: 'bolder' }}>Banco:</span> <span style={{ fontWeight: 'bolder', color: 'blue' }}>BANCOMER</span>
              </Col>
              <Col lg={1} md={1} xs={1}>&nbsp;</Col>
            </Row>

            <Row>
              <Col lg={1} md={1} xs={1}>&nbsp;</Col>
              <Col lg={10} md={10} xs={10}>
                <span style={{ fontWeight: 'bolder' }}>Beneficiario:</span> <span style={{ fontWeight: 'bolder', color: 'blue' }}>DXN EXPRESS, S.A. DE C.V. SOFOM E.N.R.</span>
              </Col>
              <Col lg={1} md={1} xs={1}>&nbsp;</Col>
            </Row>

            <br></br>

            <Row>
              <Col lg={1} md={1} xs={1}>&nbsp;</Col>
              <Col lg={10} md={10} xs={10}>
                <span style={{ fontWeight: 'bolder' }}>Pago en ventanilla o practicaja:</span>
              </Col>
              <Col lg={1} md={1} xs={1}>&nbsp;</Col>
            </Row>

            <Row>
              <Col lg={1} md={1} xs={1}>&nbsp;</Col>
              <Col lg={10} md={10} xs={10}>
                <span style={{ fontWeight: 'bolder' }}>Convenio CIE:886831</span>
              </Col>
              <Col lg={1} md={1} xs={1}>&nbsp;</Col>
            </Row>


            <Row>
              <Col lg={1} md={1} xs={1}>&nbsp;</Col>
              <Col lg={10} md={10} xs={10}>
                <span style={{ fontWeight: 'bolder' }}>Transferencia electrónica:</span>
              </Col>
              <Col lg={1} md={1} xs={1}>&nbsp;</Col>
            </Row>


            <Row>
              <Col lg={1} md={1} xs={1}>&nbsp;</Col>
              <Col lg={10} md={10} xs={10}>
                <span style={{ fontWeight: 'bolder' }}>Número de cuenta: 0165877474</span>
              </Col>
              <Col lg={1} md={1} xs={1}>&nbsp;</Col>
            </Row>



            <Row>
              <Col lg={1} md={1} xs={1}>&nbsp;</Col>
              <Col lg={10} md={10} xs={10}>
                <span style={{ fontWeight: 'bolder' }}>CLABE: 012180001658774747</span>
              </Col>
              <Col lg={1} md={1} xs={1}>&nbsp;</Col>
            </Row>


          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => {
              this.setState({ showModalSaldo: false })
              this.consultaZellCreditoRefinanciamiento()
            }}>
              Cerrar
            </Button>
          </Modal.Footer>
        </Modal>


        <Modal show={this.state.showModalRefiFlash} onHide={() => { this.setState({ showModalRefiFlash: false }) }} centered size="lg">
          <Modal.Body style={{ textAlign: 'center' }}>

            <Row>
              <Col lg={1} md={1} xs={1}>&nbsp;</Col>
              <Col lg={10} md={10} xs={10}>
                <img src={iconX} />
              </Col>
              <Col lg={1} md={1} xs={1}>&nbsp;</Col>
            </Row>


            <Row>
              <Col lg={1} md={1} xs={1}>&nbsp;</Col>
              <Col lg={10} md={10} xs={10}>
                <h2 style={{ fontSize: '1.875em', color: '#595959', wordWrap: 'break-word' }}>Refi Flash</h2>
              </Col>
              <Col lg={1} md={1} xs={1}>&nbsp;</Col>
            </Row>


            <Row>
              <Col lg={1} md={1} xs={1}>&nbsp;</Col>
              <Col lg={10} md={10} xs={10}>
                <span style={{ fontWeight: 'bolder', color: 'red' }}>
                  Se generará una solicitud con los datos del último crédito de tu cliente, si &nbsp;
                </span>
                <span style={{ fontWeight: 'bolder', color: 'red' }}>
                  cambió algún dato como la CLABE o el Domicilio deberás ingresar el  &nbsp;
                </span>
                <span style={{ fontWeight: 'bolder', color: 'red' }}>
                  refinanciamiento normal.
                </span>
              </Col>
              <Col lg={1} md={1} xs={1}>&nbsp;</Col>
            </Row>

            <br></br>
            <Row>
              <Col lg={1} md={1} xs={1}>&nbsp;</Col>
              <Col lg={10} md={10} xs={10}>
                <span style={{ fontWeight: 'bolder' }}>Créditos a refinanciar</span>
              </Col>
              <Col lg={1} md={1} xs={1}>&nbsp;</Col>
            </Row>

            <br></br>
            <Row>
              <Col lg={1} md={1} xs={1}>&nbsp;</Col>
              <Col lg={10} md={10} xs={10}>
                <Table bordered hover style={{ textAlign: 'center' }} >
                  <thead style={{ backgroundColor: 'blue' }}>
                    <tr>
                      <th>Credito&nbsp;&nbsp;</th>
                      <th>Monto</th>
                      <th>Descuento</th>
                    </tr>
                  </thead>
                  <tbody style={{ textAlign: 'center' }}>
                    <tr>
                      <td colSpan={1}>{"$ " + this.state.modalRefiFlash.credito}</td>
                      <td colSpan={1}>{"$ " + this.state.modalRefiFlash.monto} </td>
                      <td colSpan={1}>{"$ " + this.state.modalRefiFlash.descuento}</td>

                    </tr>
                  </tbody>
                </Table>
              </Col>
              <Col lg={1} md={1} xs={1}>&nbsp;</Col>
            </Row>

            <br></br>
            <Row>
              <Col lg={1} md={1} xs={1}>&nbsp;</Col>
              <Col lg={10} md={10} xs={10}>
                <span style={{ fontWeight: 'bolder' }}>Elige tu opción de refinanciamiento</span>
              </Col>
              <Col lg={1} md={1} xs={1}>&nbsp;</Col>
            </Row>


            <br></br>
            <Row>
              <Col lg={1} md={1} xs={1}>&nbsp;</Col>
              <Col lg={10} md={10} xs={10}>
                <Table bordered hover style={{ textAlign: 'center' }} >
                  <thead style={{ backgroundColor: 'blue' }}>
                    <tr>
                      <th></th>
                      <th>Recibe</th>
                      <th>Plazo</th>
                      <th>Monto</th>
                      <th>Descuento</th>
                    </tr>
                  </thead>
                  <tbody style={{ textAlign: 'center' }}>
                    {this.state.modalRefiFlash.opcionesRef}
                  </tbody>
                </Table>
              </Col>
              <Col lg={1} md={1} xs={1}>&nbsp;</Col>
            </Row>





          </Modal.Body>
          <Modal.Footer>
            <Button variant="primary" disabled={this.state.modalRefiFlash.opcionSeleccionada == null} onClick={() => {
              this.setState({ showModalRefiFlash: false })
              this.aceptaRefinanciamiento()
            }}>
              <ThumbUpOutlinedIcon />¡Acepto!
            </Button>
            <Button variant="secondary" onClick={() => { this.setState({ showModalRefiFlash: false }) }}>
              <ThumbDownOffAltOutlinedIcon /> No, gracias
            </Button>

          </Modal.Footer>
        </Modal>

      </div>
    )
  }
}

