import React, { Component } from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import CreaNuevaSolicitudPestana from "../Pestañas/CreaNuevaSolicitudPestana";
import SolicitudForm from "./SolicitudForm";
import DomicilioForm from "./DomicilioForm";
import OcupacionForm from "./OcupacionForm";
import ReferenciasForm from "./ReferenciasForm";
import DocumentosGeneralesForm from "./DocumentosGeneralesForm";

export default class DatosGeneralesSolicitud extends Component {

    constructor() {
        super();
        this.clickAvanzar=this.clickAvanzar.bind(this)
        this.state = {
            componenteActivo:'solicitud'
        }
    }

    clickAvanzar(componenteActivo){
        this.setState({componenteActivo:componenteActivo})
    }

    UNSAFE_componentWillMount() {

    }

    render() {
        return (
            <div>
                <CreaNuevaSolicitudPestana componenteActivo={this.state.componenteActivo} clickAvanzar={this.clickAvanzar} pantalla = {"datosGenerales"}/>
                <br></br>
                {
                    this.state.componenteActivo == "solicitud" ?
                    <SolicitudForm clickAvanzar={this.clickAvanzar} cambiaComponenteActivo = {this.props.cambiaComponenteActivo} />
                    :''
                }
                {
                    this.state.componenteActivo == "datosSol" ?
                    <DomicilioForm clickAvanzar={this.clickAvanzar} cambiaComponenteActivo = {this.props.cambiaComponenteActivo}/>
                    :''
                }
                {
                    this.state.componenteActivo == "ocupacion" ?
                    <OcupacionForm clickAvanzar={this.clickAvanzar} cambiaComponenteActivo = {this.props.cambiaComponenteActivo}/>
                    :''
                }
                {
                    this.state.componenteActivo == "referencias" ?
                    <ReferenciasForm clickAvanzar={this.clickAvanzar} cambiaComponenteActivo = {this.props.cambiaComponenteActivo}/>
                    :''
                }
                {
                    this.state.componenteActivo == "documentos" ?
                    <DocumentosGeneralesForm clickAvanzar={this.clickAvanzar} cambiaComponenteActivo = {this.props.cambiaComponenteActivo}/>
                    :''
                }
            </div>
        )
    }
}
