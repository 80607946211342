import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import '../node_modules/font-awesome/css/font-awesome.min.css'; 
import { BrowserRouter as Router, 
  Routes,
  Route, } from "react-router-dom";
  import Login from './componentes/login/Login';
  import Dashboard from './componentes/Dashboard/Dashboard';
import PreventBackNavigation from './PreventBackNavigation';
import reportWebVitals from './reportWebVitals';

const rootElement = document.getElementById("root");
ReactDOM.render(
  <Router>
     <PreventBackNavigation />
  <Routes>
      <Route path="/" element={<Login />} />
      <Route path="/login" element={<Login />} />
      <Route path="/dashboard" element={<Dashboard />} />

     
    </Routes>
    
  </Router>,
  rootElement
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
