// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.App {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
  }
  
  header {
    background-color: #333;
    color: #fff;
    padding: 16px;
    display: flex;
    align-items: center;
  }
  
  .toggle-button {
    background: none;
    border: none;
    cursor: pointer;
    color: #fff;
    font-size: 65px;
  }
  
  .sidebar {
    background-color: #f2f2f2;
    width: 250px;
    transition: transform 0.3s ease;
    transform: translateX(-100%);
  }
  
  .sidebar.open {
    transform: translateX(0);
  }
  
  ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  
  li {
    padding: 16px;
    cursor: pointer;
    border-bottom: 1px solid #ccc;
  }
  
  .content {
    flex-grow: 1;
    padding: 16px;
  }
  
  @media screen and (max-width: 768px) {
    .sidebar {
      position: fixed;
      top: 0;
      left: 0;
      bottom: 0;
      transform: translateX(-100%);
      z-index: 999;
    }
  
    .sidebar.open {
      transform: translateX(0);
    }
  
    .toggle-button {
      display: block;
    }
  
    .content {
      margin-left: 250px;
    }
  }
  `, "",{"version":3,"sources":["webpack://./src/componentes/SideBar2.0/side.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,iBAAiB;EACnB;;EAEA;IACE,sBAAsB;IACtB,WAAW;IACX,aAAa;IACb,aAAa;IACb,mBAAmB;EACrB;;EAEA;IACE,gBAAgB;IAChB,YAAY;IACZ,eAAe;IACf,WAAW;IACX,eAAe;EACjB;;EAEA;IACE,yBAAyB;IACzB,YAAY;IACZ,+BAA+B;IAC/B,4BAA4B;EAC9B;;EAEA;IACE,wBAAwB;EAC1B;;EAEA;IACE,gBAAgB;IAChB,UAAU;IACV,SAAS;EACX;;EAEA;IACE,aAAa;IACb,eAAe;IACf,6BAA6B;EAC/B;;EAEA;IACE,YAAY;IACZ,aAAa;EACf;;EAEA;IACE;MACE,eAAe;MACf,MAAM;MACN,OAAO;MACP,SAAS;MACT,4BAA4B;MAC5B,YAAY;IACd;;IAEA;MACE,wBAAwB;IAC1B;;IAEA;MACE,cAAc;IAChB;;IAEA;MACE,kBAAkB;IACpB;EACF","sourcesContent":[".App {\n    display: flex;\n    flex-direction: column;\n    min-height: 100vh;\n  }\n  \n  header {\n    background-color: #333;\n    color: #fff;\n    padding: 16px;\n    display: flex;\n    align-items: center;\n  }\n  \n  .toggle-button {\n    background: none;\n    border: none;\n    cursor: pointer;\n    color: #fff;\n    font-size: 65px;\n  }\n  \n  .sidebar {\n    background-color: #f2f2f2;\n    width: 250px;\n    transition: transform 0.3s ease;\n    transform: translateX(-100%);\n  }\n  \n  .sidebar.open {\n    transform: translateX(0);\n  }\n  \n  ul {\n    list-style: none;\n    padding: 0;\n    margin: 0;\n  }\n  \n  li {\n    padding: 16px;\n    cursor: pointer;\n    border-bottom: 1px solid #ccc;\n  }\n  \n  .content {\n    flex-grow: 1;\n    padding: 16px;\n  }\n  \n  @media screen and (max-width: 768px) {\n    .sidebar {\n      position: fixed;\n      top: 0;\n      left: 0;\n      bottom: 0;\n      transform: translateX(-100%);\n      z-index: 999;\n    }\n  \n    .sidebar.open {\n      transform: translateX(0);\n    }\n  \n    .toggle-button {\n      display: block;\n    }\n  \n    .content {\n      margin-left: 250px;\n    }\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
