import React, { Component } from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Accordion from 'react-bootstrap/Accordion';

export default class DomicilioForm extends Component {

    constructor() {
        super();
        this.state = {
        }
    }

    UNSAFE_componentWillMount() {

    }

    render() {
        return (
            <div>
                <Row>
                    <Col lg={6} md={6} xs={12}><h4>Datos Generales de tu Solicitud</h4></Col>
                </Row>
                <hr />
                <Row>
                    <Col lg={6} md={6} xs={12}>Fecha de Alta solicitud: <b>05-05-2023</b></Col>
                    <Col lg={6} md={6} xs={12}>Asesor: <b>Asesor Mas Nomina Prueba</b></Col>
                </Row>
                <br />
                <hr class="solid" />

                <Accordion defaultActiveKey="0">
                    <Accordion.Item eventKey="0">
                        <Accordion.Header><i className="fa fa-user" style={{ color: "#000000", textDecoration: 'none' }} data-popup="tooltip" title="Editar" ></i>&nbsp; Domicilio del cliente</Accordion.Header>
                        <Accordion.Body>
                            <Row>
                                <Col lg={3} md={3} xs={12}>
                                    <Form.Group className="mb-6" controlId="">
                                        <Form.Label>Código postal: <span style={{ color: 'red' }}>*</span></Form.Label>
                                        <Form.Control type="number" placeholder="" />

                                    </Form.Group>
                                </Col>
                                <Col lg={3} md={3} xs={12}>
                                    <Form.Group className="mb-6" controlId="">
                                            <Form.Label>Entidad: <span style={{ color: 'red' }}>*</span></Form.Label>
                                            <Form.Select >
                                                <option>Seleccione...</option>
                                                <option value="1">One</option>
                                                <option value="2">Two</option>
                                                <option value="3">Three</option>
                                            </Form.Select>
                                        </Form.Group>
                                </Col>
                                <Col lg={3} md={3} xs={12}>
                                    <Form.Group className="mb-6" controlId="">
                                        <Form.Label>Municipio/Alcaldía: <span style={{ color: 'red' }}>*</span></Form.Label>
                                        <Form.Select >
                                            <option>Seleccione...</option>
                                            <option value="1">One</option>
                                            <option value="2">Two</option>
                                            <option value="3">Three</option>
                                        </Form.Select>
                                    </Form.Group>
                                </Col>
                                <Col lg={3} md={3} xs={12}>
                                    <Form.Group className="mb-6" controlId="">
                                        <Form.Label>Ciudad: <span style={{ color: 'red' }}>*</span></Form.Label>
                                        <Form.Control type="email" placeholder="" />
                                    </Form.Group>
                                </Col>
                            </Row>
                            <br></br>
                            <Row>
                                <Col lg={3} md={3} xs={12}>
                                    <Form.Group className="mb-6" controlId="">
                                        <Form.Label>Colonia: <span style={{ color: 'red' }}>*</span></Form.Label>
                                        <Form.Select >
                                            <option>Seleccione...</option>
                                            <option value="1">One</option>
                                            <option value="2">Two</option>
                                            <option value="3">Three</option>
                                        </Form.Select>
                                    </Form.Group>
                                </Col>
                                <Col lg={3} md={3} xs={12}>
                                    <Form.Group className="mb-6" controlId="">
                                        <Form.Label>Calle: <span style={{ color: 'red' }}>*</span></Form.Label>
                                        <Form.Control type="text" placeholder="" />
                                    </Form.Group>
                                </Col>
                                <Col lg={3} md={3} xs={12}>
                                    <Form.Group className="mb-6" controlId="">
                                        <Form.Label>No. exterior: <span style={{ color: 'red' }}>*</span></Form.Label>
                                        <Form.Control type="text" placeholder="" />
                                    </Form.Group>
                                </Col>
                                <Col lg={3} md={3} xs={12}>
                                    <Form.Group className="mb-6" controlId="">
                                        <Form.Label>No. interior: </Form.Label>
                                        <Form.Control type="text" placeholder="" />
                                    </Form.Group>
                                </Col>
                            </Row>
                        </Accordion.Body>
                    </Accordion.Item>

                </Accordion>
                <br></br>
                <Row>
                    <Col lg={3} md={3} xs={12}>
                        <Button style={{ "width": '100%', backgroundColor: '#CBC7C6', borderColor: '#CBC7C6' }} onClick={() => { this.props.cambiaComponenteActivo("consulta") }} type="button"> Regresar al ménu </Button>
                    </Col>
                    <Col lg={1} md={1}>&nbsp;</Col>
                    <Col lg={3} md={3} xs={12}>
                        <Button style={{ "width": '100%', backgroundColor: '#2E64FE', borderColor: '#2E64FE' }} onClick={() => { this.props.clickAvanzar("solicitud") }} type="button"> Regresar </Button>
                    </Col>
                    <Col lg={1} md={1}>&nbsp;</Col>
                    <Col lg={3} md={3} xs={12}>
                        <Button style={{ "width": '100%', backgroundColor: '#01DF01', borderColor: '#01DF01' }} onClick={() => { this.props.clickAvanzar("ocupacion") }} type="button"> Avanzar </Button>
                    </Col>
                </Row>
             
            </div>
        )
    }
}
