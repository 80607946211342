import React, { Component } from "react";
import Table from 'react-bootstrap/Table';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
//import Modal from '../modal/Modal'

export default class TablaFiltrosAgente extends Component {
    constructor() {
        super();
        this.siguientePagina = this.siguientePagina.bind(this)
        this.anteriorPagina = this.anteriorPagina.bind(this)
        this.cambiaPaginaPorBoton = this.cambiaPaginaPorBoton.bind(this)
        this.construyeModalesEdicion = this.construyeModalesEdicion.bind(this)

        this.state = {
            filasProps: [],
            filasFiltro: [],
            columnas: [],
            columnasExport: [],
            filas: [],
            paginas: [],
            totalRegistros: 0,
            mostrandoRegistrosTope: 10,
            mostrandoRegistroDesde: 1,
            mostrandoRegistroHasta: 1,
            siguienteEsActivado: false,
            anteriorEsActivado: false,
            totalPaginas: 1,
            paginaActual: 1,
            jsonRespuesta: {},
            edicion: false,
            modales: []
        };
    }

    UNSAFE_componentWillMount() {
        let json = {"columnas":[{"key":"id","value":"Identificador"},{"key":"nombre","value":"Nombre"},{"key":"identifiacion","value":"Identificación"},{"key":"contacto","value":"Contacto"},{"key":"asesor","value":"Asesor"},{"key":"fecha_solicitud","value":"Fecha de Solicitud"}],"filas":[{"fila":[{"type":"AutoIncrement","value":1},{"type":"String","value":"Prueba-1.png"},{"type":"String","value":"imagen"},{"type":"String","value":"5511223344"},{"type":"String","value":"ASESOR MAS NOMINA"},{"type":"String","value":"2023-05-23 23:09:20"}]},{"fila":[{"type":"AutoIncrement","value":2},{"type":"String","value":"Prueba-2.png"},{"type":"String","value":"imagen"},{"type":"String","value":"5511223344"},{"type":"String","value":"ASESOR MAS NOMINA"},{"type":"String","value":"2023-05-23 24:47:08"}]}]};
        let jsonFilas = [{"fila":[{"type":"AutoIncrement","value":1},{"type":"String","value":"Prueba-1.png"},{"type":"String","value":"imagen"},{"type":"String","value":"5511223344"},{"type":"String","value":"ASESOR MAS NOMINA"},{"type":"String","value":"2023-05-23 23:09:20"}]},{"fila":[{"type":"AutoIncrement","value":2},{"type":"String","value":"Prueba-2.png"},{"type":"String","value":"imagen"},{"type":"String","value":"5511223344"},{"type":"String","value":"ASESOR MAS NOMINA"},{"type":"String","value":"2023-05-23 24:47:08"}]}];
        let jsonColumnas = [{"key":"id","value":"Identificador"},{"key":"nombre","value":"Nombre"},{"key":"identifiacion","value":"Identificación"},{"key":"contacto","value":"Contacto"},{"key":"asesor","value":"Asesor"},{"key":"fecha_solicitud","value":"Fecha de Solicitud"}];

        this.setState({ filasProps: jsonFilas, jsonRespuesta: json })

        if (this.props.nonModal == undefined) {
            this.construyePaginador(jsonFilas.length, jsonFilas, 1)
            this.construyeColumnas(jsonColumnas)
            this.construyeModalesEdicion("", jsonFilas)
        } else {
            this.construyePaginador(jsonFilas.length, jsonFilas, 1)
            this.construyeColumnas(jsonColumnas)
        }
    }


    construyeColumnas(columnas) {
        console.log("entrando a cons")
        let stackcolumnas = []
        let columnasExport = []
        for (var i = 0; i < columnas.length; i++) {
            columnasExport.push(columnas[i].value)
            stackcolumnas.push(
                <th style={{ backgroundColor: '#F76300' , color: 'white' }} >                    
                    <Row>
                        <Col lg={12} md={12} xs={12}>
                            {columnas[i].value}
                        </Col>
                    </Row>
                </th>
            )
            if (i == columnas.length - 1) {
                stackcolumnas.push(
                    <th style={{ backgroundColor: '#F76300', color: 'white'  }}  >                    
                        <Row>
                            <Col lg={12} md={12} xs={12}>
                                Acciones
                            </Col>
                        </Row>
                    </th>
                )
            }
        }
        this.setState({ columnas: stackcolumnas, columnasExport: columnasExport })
    }

    construyeModalesEdicion(nombreModal, filas) {
        let modales = []

        for (var i = 0; i < filas.length; i++) {
            let contenidoFila = filas[i].fila
            modales.push(

                {/*<Modal guardaElementoNuevo={this.props.guardaElementoNuevo} mostrarMensajeModal={this.props.mostrarMensajeModal} idUsuario={this.props.idUsuario} tipoUsuario={this.props.tipoUsuario} modalNombre={this.props.modalNombreEdicion + i} contenido={contenidoFila} cancelarModal={this.props.cancelarModal} />*/}

            )
        }
        //  console.log("estas son las modales ",modales)
        this.setState({ modales: modales })

    }

    construyeFilas(filas, inicioConteo, finConteo) {
        inicioConteo = inicioConteo - 1
        finConteo = finConteo - 1
        let stackFilas = []
        let stackContenidoFila = []
        console.log(filas)
        console.log("inicioConteo ", inicioConteo)
        console.log("finConteo ", finConteo)
        for (var i = inicioConteo; i < finConteo; i++) {
            if (filas[i] != undefined) {
                let idEdicion = 0;
                let contenidoFila = filas[i].fila
                for (var j = 0; j < contenidoFila.length; j++) {

                    if (contenidoFila[j].type == 'AutoIncrement') {
                        idEdicion = contenidoFila[j].value
                        console.log("idEdicion ", idEdicion)
                        stackContenidoFila.push(
                            <td>
                                <Row>
                                    <Col lg={12} md={12} xs={12}>
                                        {contenidoFila[j].value}
                                    </Col>
                                </Row>
                            </td>
                        )
                    }
                    if (contenidoFila[j].type == 'String' || contenidoFila[j].type == 'Date' || contenidoFila[j].type == 'Time' || contenidoFila[j].type == 'int' || contenidoFila[j].type == 'Int') {
                        stackContenidoFila.push(
                            <td>
                                <Row>
                                    <Col lg={12} md={12} xs={12}>
                                        {contenidoFila[j].value}
                                    </Col>
                                </Row>
                            </td>
                        )
                    }


                    if (contenidoFila[j].type == 'Boolean') {
                        if (contenidoFila[j].value == 1) {
                            stackContenidoFila.push(
                                <td style={{ textAlign: "left" }}>
                                    <Row>
                                    <Col lg={12} md={12} xs={12}>
                                            <div className="custom-control custom-switch custom-control-warning mb-2">
                                                <input type="checkbox" class="custom-control-input" id="swc_warning" checked={true} onChange={(e) => console.log("")} ></input>
                                                <label class="custom-control-label" for="swc_warning"></label>
                                            </div>
                                        </Col>
                                    </Row>
                                </td>
                            )
                        } else {
                            stackContenidoFila.push(
                                <td style={{ textAlign: "left" }}>
                                    <Row>
                                        <Col lg={12} md={12} xs={12}>
                                            <div className="custom-control custom-switch custom-control-warning mb-2">
                                                <input type="checkbox" class="custom-control-input" id="swc_warning" checked={false} onChange={(e) => console.log("")}  ></input>
                                                <label class="custom-control-label" for="swc_warning"></label>
                                            </div>
                                        </Col>
                                    </Row>
                                </td>
                            )
                        }

                    }

                    if (contenidoFila.length - 1 == j) {
                        if (this.props.nonModal == undefined) {
                            stackContenidoFila.push(
                                <td style={{ textAlign: "left" }}>
                                    <Row>
                                        <Col lg={12} md={12} xs={12}>
                                            <a href="#" style={{ textDecoration: 'none', color: '#8F9EB3' }} ><i className="fa fa-pencil" data-toggle="modal"
                                            data-target={"#" + this.props.modalNombreEdicion + i} style={{ color: "#e6570a", textDecoration: 'none' }} data-popup="tooltip" title="Editar" ></i></a>
                                        </Col>
                                    </Row>
                                </td>
                            )
                        } else {
                            //idEdicion
                            console.log("idEdicion befor ", idEdicion)

                            stackContenidoFila.push(
                                <td style={{ textAlign: "left" }}>
                                    <Row>
                                        <Col lg={12} md={12} xs={12}>
                                            <a href="#" style={{ textDecoration: 'none', color: '#8F9EB3' }} >
                                                <i onClick={(e) => { this.props.cambiaComponenteActivo("nuevaSol") }} className="fa fa-pencil" style={{ color: "#e6570a", textDecoration: 'none' }} title="Editar" ></i>
                                            </a>
                                        </Col>
                                    </Row>
                                </td>
                            )
                        }

                    }
                }
                stackFilas.push(<tr className="odd">{stackContenidoFila}</tr>)
                stackContenidoFila = []

            }
        }
        this.setState({ filas: stackFilas })
    }

    construyePaginador(totalFilas, filas, paginaActual) {
        console.log("total de filas ", totalFilas, filas)
        if (totalFilas > 0) {

            let divisionPaginador = totalFilas / this.state.mostrandoRegistrosTope;
            console.log("division del paginador ", divisionPaginador)
            if (divisionPaginador < 1) {
                this.setState({ mostrandoRegistroHasta: totalFilas })
                this.construyeFilas(filas, 1, this.state.mostrandoRegistrosTope + 1)
                this.construyePaginasDePaginador(1, 1)
            } else {

                this.setState({ mostrandoRegistroHasta: this.state.mostrandoRegistrosTope, siguienteEsActivado: true })
                if (divisionPaginador % 1 == 0) {
                    this.construyePaginasDePaginador(divisionPaginador, 1)
                    this.construyeFilas(filas, 1, this.state.mostrandoRegistrosTope + 1)

                } else {
                    let stringDivisor = divisionPaginador + ''
                    divisionPaginador = parseInt(stringDivisor.split('.')[0])
                    this.construyePaginasDePaginador(divisionPaginador + 1, 1)
                    this.construyeFilas(filas, 1, this.state.mostrandoRegistrosTope + 1)
                }

            }
        } else {
            this.modalVacio.click()
            this.setState({ mostrandoRegistroHasta: totalFilas })
            this.construyeFilas(filas, 1, this.state.mostrandoRegistrosTope + 1)
            this.construyePaginasDePaginador(1, 1)
        }
        this.setState({ totalRegistros: totalFilas })

    }

    cambiaPaginaPorBoton(pagina, e) {
        return event => {
            console.log(this, pagina)
            let conteo = (pagina) * this.state.mostrandoRegistrosTope
            let inicio = (conteo - this.state.mostrandoRegistrosTope) + 1
            let fin = conteo + 1

            this.construyeFilas(this.state.filasProps, inicio, fin)
            this.construyePaginasDePaginador(this.state.totalPaginas, pagina)
            this.setState({ mostrandoRegistroDesde: inicio, mostrandoRegistroHasta: fin })
        }

    }


    construyePaginasDePaginador(totalPaginas, paginaActual) {
        let paginas = []
        for (var i = 1; i <= totalPaginas; i++) {
            if (i == paginaActual) {
                paginas.push(
                    <a className="paginate_button current" onClick={this.cambiaPaginaPorBoton(i)} >{i}</a>
                )
            } else {
                paginas.push(
                    <a className="paginate_button " onClick={this.cambiaPaginaPorBoton(i)}  >{i}</a>
                )
            }
        }
        this.setState({ paginas: paginas, paginaActual: paginaActual, totalPaginas: totalPaginas })
    }


    siguientePagina() {

        let conteo = (this.state.paginaActual + 1) * this.state.mostrandoRegistrosTope
        let inicio = (conteo - this.state.mostrandoRegistrosTope) + 1
        let fin = conteo + 1

        this.construyeFilas(this.state.filasProps, inicio, fin)
        this.construyePaginasDePaginador(this.state.totalPaginas, this.state.paginaActual + 1)
        this.setState({ mostrandoRegistroDesde: inicio, mostrandoRegistroHasta: fin })

    }

    anteriorPagina() {
        let conteo = (this.state.paginaActual - 1) * this.state.mostrandoRegistrosTope
        let inicio = (conteo - this.state.mostrandoRegistrosTope) + 1
        let fin = conteo + 1
        this.construyeFilas(this.state.filasProps, inicio, fin)
        this.construyePaginasDePaginador(this.state.totalPaginas, this.state.paginaActual - 1)
        this.setState({ mostrandoRegistroDesde: inicio, mostrandoRegistroHasta: fin })
    }

    render() {
        return (
            <div>
                <button type="button" style={{ display: 'none' }} ref={(ref) => this.modalVacio = ref} data-toggle="modal" data-target="#modal-Vacio"></button>
                <div id="modal-Vacio" className="modal fade" tabindex="-1">
                    <div className="modal-dialog ">
                        <div className="modal-content text-white" style={{ backgroundColor: "#FFFFF" }}>
                            <div className="modal-header text-white text-center" style={{ backgroundColor: "#FFFFF" }}>
                            </div>
                            <div className="modal-body">
                                <div className="card-body">
                                    <h6 className="col-12 text-center text-dark font-weight-semibold">No se encontraron resultados con la búsqueda establecida. </h6>
                                </div>
                            </div>
                            <div class="modal-footer d-flex justify-content-center">
                                <div className="col-12">
                                    <div className="row">
                                        <button type="button" style={{ width: '100%', backgroundColor: '#8189D4' }} class="btn text-white" data-dismiss="modal"  >Continuar </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {this.state.modales.length != 0 ?
                    this.state.modales : ''}

                <div className="card" style={{ backgroundColor: '#FFFFFF', border: 'none',overflowX: 'auto', overflowY: 'hidden', height: 'auto', display: 'flex' }}>
                    <div className="card-body">
                        <br />
                        <div className="dataTables_wrapper no-footer">
                            <div className="datatable-scroll-wrap">
                            <Table striped>
                                <thead style={{ backgroundColor: '#FFFFFF' }}>
                                    <tr>
                                        {
                                            this.state.columnas
                                        }
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.state.filas}
                                </tbody>
                            </Table>
                            </div>
                        </div>
                    </div>
                </div>

            </div>

        )

    }
}