import React, { Component } from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import DatosGeneralesSolicitud from "../forms/DatosGeneralesSolicitud";
import Tabla from "../table/TablaGenerica";
export default class ConsultaSolicitudesMasNomina extends Component {

    constructor() {
        super();
      //  this.clickAvanzar = this.clickAvanzar.bind(this)
        this.muestraFormulario1 = this.muestraFormulario1.bind(this)
        this.cambiaComponenteActivo= this.cambiaComponenteActivo.bind(this)
        this.state = {
            componenteActivo: 'consulta'
        }
    }


    cambiaComponenteActivo(componenteActivo){
        console.log("Entro aquí");
        this.setState({componenteActivo:componenteActivo})
    }

    muestraFormulario1(idEdicion) {
        if(idEdicion == undefined){
            this.setState({ muestraTabla: false, muestraFormularioBandera: true ,idEmpleado:null,consultaGeneral:false})// clicl al aregar nuevo
        }else{
            this.setState({ muestraTabla: false, muestraFormularioBandera: true, idEmpleado:idEdicion,consultaGeneral:false })// clicl al aregar nuevo
        }
    }

    UNSAFE_componentWillMount() {

    }

    render() {
        return (
            <div className="page-content" style={{ backgroundColor: 'white', marginTop: '25px', height: '100%', width: '100%' }}>
                <div className="content-wrapper">
                    <div className="content-inner">
                            {
                                this.state.componenteActivo == "consulta" ?
                                <div>
                                <Row>
                                    <Col lg={3} md={3} xs={12}><h4>Consulta solicitudes Más nómina</h4></Col>
                                    <Col lg={6} md={3} xs={12}>&nbsp;</Col>
                                    <Col lg={3} md={3} xs={12}>
                                        <Button style={{ "width": '100%', backgroundColor: '#FF4000', borderColor: '#FF4000' }} onClick={() => {this.cambiaComponenteActivo("nuevaSol") }} type="button"> + Crear una Nueva Solicitud</Button>
                                    </Col>

                                </Row>
                                <br></br>
                                {/* */}
                                <Row>
                                    <Col lg={3} md={3} xs={12}>
                                        <Form.Group className="mb-6" controlId="">
                                            <Form.Label>Sucursal :</Form.Label>
                                            <Form.Select >
                                                <option>Seleccione...</option>
                                                <option value="1">One</option>
                                                <option value="2">Two</option>
                                                <option value="3">Three</option>
                                            </Form.Select>
                                        </Form.Group>
                                    </Col>

                                    <Col lg={3} md={3} xs={12}>
                                        <Form.Group className="mb-6" controlId="">
                                            <Form.Label>Estatus:</Form.Label>
                                            <Form.Select >
                                                <option>Seleccione...</option>
                                                <option value="1">One</option>
                                                <option value="2">Two</option>
                                                <option value="3">Three</option>
                                            </Form.Select>
                                        </Form.Group>
                                    </Col>

                                    <Col lg={6} md={6} xs={12}>
                                        <Row>
                                            <Col lg={5} md={4} xs={5}>
                                                <Form.Label>Fecha solicitud: <span style={{ color: 'red' }}>*</span></Form.Label>
                                                <Form.Control type="date" placeholder="" />
                                            </Col>
                                            <Col lg={2} md={4} xs={2}>
                                                <Form.Control type="text" placeholder="" style={{ visibility: 'hidden' }} />
                                                <Form.Label>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;a</Form.Label>
                                            </Col>
                                            <Col lg={5} md={4} xs={5}>
                                                <Form.Label>&nbsp;</Form.Label>

                                                <Form.Control type="date" placeholder="" />
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>


                                <br />
                                <Row>
                                    <Col lg={3} md={3} xs={12}>
                                        <Button style={{ "width": '100%', backgroundColor: '#01DF01', borderColor: '#01DF01' }} onClick={() => { }} type="button"> Consultr</Button>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col lg={12} md={12} xs={12}>
                                        <Tabla
                                            url={""}
                                            tituloTabla={'Administrar Agentes APS'} tabla="api_vagentes"
                                            muestraFormulario={this.muestraFormulario1} modalNombre={""} colorAgregar="#ED6C26" nonModal={true} 
                                            cambiaComponenteActivo={this.cambiaComponenteActivo}/>
                                    </Col>
                                </Row>
                                </div>:''
                            }
                            {
                                this.state.componenteActivo == 'nuevaSol' ? 
                                <div>
                                    <DatosGeneralesSolicitud cambiaComponenteActivo={this.cambiaComponenteActivo}/>
                                </div>:''
                            }
                    </div>
                </div>
            </div>
        )
    }
}