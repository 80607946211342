import React, { Component } from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import Container from 'react-bootstrap/Container';
import Card from 'react-bootstrap/Card';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import conexiones from '../urls/conexiones.json'
import Alert from 'react-bootstrap/Alert';
import SideNavBar from "../sideBar/SideBar";
const logo = require("../imagenes/logo_dxn.png")


export default class Login extends Component {

    constructor() {
        super();
        this.onChangeValues = this.onChangeValues.bind(this)
        this.iniciaSesion = this.iniciaSesion.bind(this)
        this.state = {
            usuario: '',
            contrasena: '',
            inicioFallido: false,
            mensajeError: '',
            inicioSesionExitoso:false,
            idUsuario:null,
            nombreUsuario:'',
            idSucursal:null,
            isSuperUsuario:false,
            idPuesto:null,
            esMesaControl:false,
            urlINE:false

        }
    }


    UNSAFE_componentWillMount() {

    }


    onChangeValues = e => {
        if (e.target.name == "usuario") {
            this.setState({ usuario: e.target.value })
        }
        if (e.target.name == "contrasena") {
            this.setState({ contrasena: e.target.value })
        }
    }

    iniciaSesion() {
        let json = {
            "username": this.state.usuario,
            "password": this.state.contrasena
        }
        const requestReferidoOptions = {
            method: "POST",
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(json)
        }
        fetch(conexiones.urlLogin, requestReferidoOptions)
            .then(response => response.json())
            .then(data => {

                console.log(data)
                if (data.length > 0) {
                    if(data[0].is_active == true){
                      this.setState({idUsuario:data[0].id,nombreUsuario:data[0].first_name + " " + data[0].last_name,
                      idSucursal:data[0].sucursal,
                      isSuperUsuario:data[0].is_superuser,
                      inicioSesionExitoso:true,
                      idPuesto:data[0].puesto,
                      esMesaControl:data[0].mesacontrol == 1 ? true:false,
                      urlINE:data[0].url_ine
                    })
                    }else{
                      this.setState({ mensajeError: "Tu usuario ya no esta activo", inicioFallido: true })
                    }
                } else {
                    this.setState({ mensajeError: "Contraseña o usuario incorrectos", inicioFallido: true })
                }

            }).catch(error => {
                this.setState({ mensajeError: error.toString(), inicioFallido: true })

            })

    }

    render() {
        return (
            <div>
                {
                  this.state.inicioSesionExitoso == false ? 
                <Container style={{ opacity: '100%' }}>
                    <Row className="justify-content-md-center">
                      <Col xs={12} lg={5}>
                        <Card style={{ marginTop: '50px', borderRadius: '15px' }}>
                          <Card.Body>
                            <Card.Title>
                              <Form.Group>
                                <Form.Control style={{ borderColor: 'white' }} type="image" src={logo} />
                              </Form.Group>
                            </Card.Title>
                  
                         
                            <br></br>
                  
                            <Row className="mb-2" style={{ justifyContent: 'center', alignItems: 'center' }}>
                              <Col xs={12}>
                                <Card.Subtitle style={{  textAlign:'center' }} className="text-muted">Inicia sesión con tu usuario y contraseña </Card.Subtitle>
                              </Col>
                            </Row>
                  
                            <Form>
                              <Form.Group className="mb-3" controlId="formBasicUser">
                                <Form.Control type="text" placeholder="Usuario" name="usuario" onChange={this.onChangeValues} value={this.state.usuario} />
                              </Form.Group>
                  
                              <Form.Group className="mb-3" controlId="formBasicPassword">
                                <Form.Control type="password" placeholder="Contraseña" name="contrasena" onChange={this.onChangeValues} value={this.state.contrasena} />
                              </Form.Group>
                  
                              <Form.Group className="mb-3" controlId="formBasicCheckbox">
                                <Row className="justify-content-end">
                                  <Col xs={8} sm={4} lg={5}> 
                                    <Card.Link href="#" style={{ color: '#FF4000', textAlign: 'right' }}>¿Olvidaste tu contraseña?</Card.Link>
                                  </Col>
                                </Row>
                              </Form.Group>
                  
                              <Form.Group className="mb-3" controlId="formBasicPassword">
                                <Button style={{ width: '100%', backgroundColor: '#848484', borderColor: '#848484' }} onClick={this.iniciaSesion} type="button">Ingresar</Button>
                              </Form.Group>
                            </Form>
                          </Card.Body>
                        </Card>
                  
                        {this.state.inicioFallido && (
                          <Alert variant="danger" onClose={() => { this.setState({ inicioFallido: false }) }} dismissible>
                            {this.state.mensajeError}
                          </Alert>
                        )}
                      </Col>
                    </Row>
                  </Container>:
                  <SideNavBar 
                   esMesaControl={this.state.esMesaControl} 
                   nombreUsuario={this.state.nombreUsuario} 
                   idPuesto={this.state.idPuesto} 
                   idUsuario={this.state.idUsuario}
                   idSucursal={this.state.idSucursal} 
                   isSuperUsuario={this.state.isSuperUsuario}
                   urlINE={this.state.urlINE}
                   />
                }
               
            </div>
        )
    }

}