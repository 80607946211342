import React, { Component } from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import Container from 'react-bootstrap/Container';
import Card from 'react-bootstrap/Card';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Nav from 'react-bootstrap/Nav';
import CreaNuevaSolicitudPestana from "../Pestañas/CreaNuevaSolicitudPestana";
import CreaNuevaSolicitudForm from "../forms/CreaNuevaSolicitudFrom";
import DatosSolicitante from "../forms/DatosSolicitanteForm";
import OcupacionSolicitanteForm from "../forms/OcupacionSolicitanteForm";
import ReferenciasSolicitanteForm from "../forms/ReferenciasSolicitanteForm";
import DocumentosSolicitanteForm from "../forms/DocumentosSolicitanteForm";
import VideoIMSS from "../forms/VideoIMSS";
import VideoIMSSios from "../forms/videoIMSSios"

import CompraDeudaForm from "../forms/CompraDeudaForm";
import Button from 'react-bootstrap/Button';
import ArchiveOutlinedIcon from '@mui/icons-material/ArchiveOutlined';
import DeleteSweepIcon from '@mui/icons-material/DeleteSweep';
import conexiones from '../urls/conexiones.json'
import botonBasura from '../imagenes/botonBasura.png'
import botonListadoSol from '../imagenes/botonListado.png'
import EscaneoIne from "../forms/EscaneoIne";

export default class CreaNuevaSolicitud extends Component {

    constructor() {
        super();
        this.clickAvanzar = this.clickAvanzar.bind(this)
        this.guardaIdSolicitud = this.guardaIdSolicitud.bind(this)
        this.modificaCompraDeuda = this.modificaCompraDeuda.bind(this)
        this.guardaYGeneraJsonsSecciones = this.guardaYGeneraJsonsSecciones.bind(this)
        this.guardaDatosNuevaSolicitud = this.guardaDatosNuevaSolicitud.bind(this)
        this.generaJsonsParaNuevaSolicitud = this.generaJsonsParaNuevaSolicitud.bind(this)
        this.resetFormularios = this.resetFormularios.bind(this)
        this.setBanderaExistenDatosPersonales = this.setBanderaExistenDatosPersonales.bind(this)
        this.guardarForomulariosConInfoDeZELL = this.guardarForomulariosConInfoDeZELL.bind(this)
        this.setBanderaExistenDatosDomicilio = this.setBanderaExistenDatosDomicilio.bind(this)
        this.setBanderaExistenDatosContacto = this.setBanderaExistenDatosContacto.bind(this)
        this.setBanderaExistenDatosBancarios = this.setBanderaExistenDatosBancarios.bind(this)    
        this.setBanderaExistenDatosOcupacion=this.setBanderaExistenDatosOcupacion.bind(this)
        this.setBanderaExistenDatosReferencias=this.setBanderaExistenDatosReferencias.bind(this)
        this.setNuevaSolicitudServicio=this.setNuevaSolicitudServicio.bind(this)
        this.setDatosPersonalesServicio=this.setDatosPersonalesServicio.bind(this)
        this.setDatosDomicilioServicio=this.setDatosDomicilioServicio.bind(this)
        this.setDatosContactoServicio=this.setDatosContactoServicio.bind(this)
        this.setDatosBancariosServicio=this.setDatosBancariosServicio.bind(this)
        this.setDatosOcupacionServicio=this.setDatosOcupacionServicio.bind(this)
        this.setDatosReferenciasServicio=this.setDatosReferenciasServicio.bind(this)
        this.getReferenciasDesdeContenedor=this.getReferenciasDesdeContenedor.bind(this)
        this.modificaBanderaIMSS=this.modificaBanderaIMSS.bind(this)
        this.regresarConsultaSol=this.regresarConsultaSol.bind(this)
        this.state = {
            componenteActivo: 'solicitud',
            idSolicitud: null,
            compraDeuda: false,
            activar_IMSS:false,
            datosSolicitanteOcupacion: {
                tipoEmpleado: "2",
                numeroEmpleado: null,
                ingresoMensual: null,
                profesion: null,
                puesto: null,
                cargoPublicoCampo: "No",
                cargoPublicoFamiliarCampo: "No",
                puestoPublico: null,
                periodoEjercicioPublico: null,
                nombreFamiliar: null,
                parentescoFamiliar: null,

                puestoFamiliar: null,
                periodoEjercicioFamiliar: null
            },
            datosSolicitante: {
                primerNombre: null,
                segundoNombre: null,
                primerApellido: null,
                segundoApellido: null,
                genero: null,
                edoCivil: null,
                fecNacimiento: null,
                paisNaci: null,
                entidadNaci: null,
                nacionalidad: null,
                identificacion: "1",
                codigoPostal: null,
                tipoVivienda: null,
                entidad: null,
                municipio: null,
                ciudad: null,
                colonia: null,
                calle: null,
                exterior: null,
                interiror: null,
                email: null,
                numeroTelefono: null,
                medio: "1",
                clabeTarjeta: null,
                bancaria: null,
                otraColonia: null
            },
            arrayReferencias: [],
            nuevaSolicitud: {
                curp: null,
                rfc: null,
                sector: null,
                producto: null,
                folio_imss:null,
                fechaIngreso: null,
                actuaPor: "1",
                tipoProducto: null,
                periodo: null,
                plazo: null,
                capPago: null,
                destinoCredito: null,
                montoSolicitado: null,
                descuento: null,
                tasaAnual: null,
                sucursal: null,
                montoDeudorSol: null,
                depositoAlClienteSol: null
            },
            jsonResultadosZell: {},
            existenResultadosEnZell: false,
            existenDatosPersonales: false,
            existenDatosDomicilio:false,
            existenDatosContacto:false,
            existenDatosBancarios:false,
            existenDatosOcupacion:false,
            existenDatosReferencias:false,
//la que viene desde el servicio getSolicitud
            nuevaSolicitudServicio:null,
            datosPersonalesServicio:null,
            datosDomicilioServicio:null,
            datosContactoServicio:null,
            datosBancariosServicio:null,
            datosOcupacionServicio:null,
            datosReferenciasServicio:null,
    
        }
    }


    clickAvanzar(componenteActivo) {
        this.setState({ componenteActivo: componenteActivo })
    }


    UNSAFE_componentWillMount() {
        console.log("entrando  ", this.props)
        if (this.props.idSolicitud != null) {
            this.setState({ idSolicitud: this.props.idSolicitud })
        }

        if(this.props.idSucursal != null){
            let nuevaSolicitud=this.state.nuevaSolicitud
            nuevaSolicitud.sucursal=this.props.idSolicitud
            this.setState({ nuevaSolicitud:nuevaSolicitud  })
        }
    }



    setNuevaSolicitudServicio(nuevaSolicitudServicio){
        this.setState({nuevaSolicitudServicio:nuevaSolicitudServicio})
    }

    setDatosPersonalesServicio(datosPersonalesServicio){
        this.setState({datosPersonalesServicio:datosPersonalesServicio})
    }

    setDatosDomicilioServicio(datosDomicilioServicio){
        this.setState({datosDomicilioServicio:datosDomicilioServicio})

    }

    setDatosContactoServicio(datosContactoServicio){
        this.setState({datosContactoServicio:datosContactoServicio})

    }

    setDatosBancariosServicio(datosBancariosServicio){
        this.setState({datosBancariosServicio:datosBancariosServicio})

    }

    setDatosOcupacionServicio(datosOcupacionServicio){
        this.setState({datosOcupacionServicio:datosOcupacionServicio})

    }
    setDatosReferenciasServicio(datosReferenciasServicio){
        this.setState({datosReferenciasServicio:datosReferenciasServicio,arrayReferencias:datosReferenciasServicio})

    }



    modificaCompraDeuda(bandera) {
        this.setState({ compraDeuda: bandera })
    }

    async guardaIdSolicitud(idSolicitud) {
        this.setState({ idSolicitud: idSolicitud })
        if (this.state.existenResultadosEnZell == true) {
            console.log("guardarForomulariosConInfoDeZELL ", this.state.datosSolicitante)


            let json =
            {
                "solicitud": idSolicitud,
                "primer_nombre": this.state.datosSolicitante.primerNombre,
                "segundo_nombre": this.state.datosSolicitante.segundoNombre,
                "primer_apellido": this.state.datosSolicitante.primerApellido,
                "segundo_apellido": this.state.datosSolicitante.segundoApellido,
                "fecha_nacimiento": this.state.datosSolicitante.fecNacimiento,
                "genero": this.state.datosSolicitante.genero,
                "edocivil": this.state.datosSolicitante.edoCivil,
                "pais_nacimiento": this.state.datosSolicitante.paisNaci,
                "entidad_nacimiento": this.state.datosSolicitante.entidadNaci,
                "nacionalidad": this.state.datosSolicitante.nacionalidad,
                "identificacion": this.state.datosSolicitante.identificacion
            }
            console.log("el json ", json)
            const requestReferidoOptions = {
                method: "POST",
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(json)
            }
            fetch(conexiones.urlServicios + "wedo_datos_solicitante/0", requestReferidoOptions)
                .then(response => response.json())
                .then(data => {

                    console.log("respuesta del servicio al crear  datos del solicitante ", data)
                    if (data["id"] != undefined) {
                        this.setDatosPersonalesServicio(data)
                        this.setBanderaExistenDatosPersonales(true)
                        // this.setState({ idDatosPersonales: data["id"], colorModal: this.state.colorSuccesModal, tituloModal: 'Info guardada', mensajeError: "!Se han guardado tus datos personales con exito¡", showModal: true })

                    } else {
                        this.setState({ colorModal: this.state.colorErrorModal, tituloModal: 'Ocurrio un error!', mensajeError: this.formatoLog(data), showModal: true })
                    }


                }).catch(error => {
                    this.setState({ colorModal: this.state.colorErrorModal, tituloModal: 'Ocurrio un error!', mensajeError: error.toString(), showModal: true })

                })

            let jsonDom =
            {
                "solicitud": idSolicitud,
                "codigo_postal": this.state.datosSolicitante.codigoPostal,
                "calle": this.state.datosSolicitante.calle,
                "no_exterior": this.state.datosSolicitante.exterior,
                "no_interior": this.state.datosSolicitante.interiror,
                "tipo_vivienda": this.state.datosSolicitante.tipoVivienda,
                "entidad": this.state.datosSolicitante.entidad,
                "municipio": this.state.datosSolicitante.municipio,
                "ciudad": this.state.datosSolicitante.ciudad,
                "colonia": this.state.datosSolicitante.colonia,
                "otra_colonia": this.state.datosSolicitante.otraColonia


            }
            //  console.log("el json ", json)
            const requestReferidoOptionsDom = {
                method: "POST",
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(jsonDom)
            }
            fetch(conexiones.urlServicios + "wedo_domicilio/0", requestReferidoOptionsDom)
                .then(response => response.json())
                .then(data => {

                    console.log("respuesta del servicio al crear  datos del domicilio  ", data)
                    if (data["id"] != undefined) {
                        this.setDatosDomicilioServicio(data)
                      this.setBanderaExistenDatosDomicilio(true)
                        // this.setState({ idDatosDomiciliares: data["id"], colorModal: this.state.colorSuccesModal, tituloModal: 'Info guardada', mensajeError: "!Se han guardado tus datos domiciliares con exito¡", showModal: true })
                    } else {
                        this.setState({ colorModal: this.state.colorErrorModal, tituloModal: 'Ocurrio un error!', mensajeError: this.formatoLog(data), showModal: true })
                    }


                }).catch(error => {
                    this.setState({ colorModal: this.state.colorErrorModal, tituloModal: 'Ocurrio un error!', mensajeError: error.toString(), showModal: true })

                })


            let jsonContacto =
            {
                "solicitud": idSolicitud,
                "nombre": this.state.datosSolicitante.primerNombre + " " + this.state.datosSolicitante.primerApellido,
                "email": this.state.datosSolicitante.email,
                "celular": this.state.datosSolicitante.numeroTelefono,
                "solicitud": idSolicitud
            }

            //console.log("el json ", json)
            const requestReferidoOptionsContacto = {
                method: "POST",
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(jsonContacto)
            }
            fetch(conexiones.urlServicios + "wedo_contacto/0", requestReferidoOptionsContacto)
                .then(response => response.json())
                .then(data => {

                    console.log("respuesta del servicio al crear  datos del domicilio  ", data)
                    if (data["id"] != undefined) {
                        this.setDatosContactoServicio(data)
                       this.setBanderaExistenDatosContacto(true)
                        // this.setState({ idDatosContacto: data["id"], colorModal: this.state.colorSuccesModal, tituloModal: 'Info guardada', mensajeError: "!Se han guardado tus datos de contacto con exito¡", showModal: true })
                    } else {
                        this.setState({ colorModal: this.state.colorErrorModal, tituloModal: 'Ocurrio un error!', mensajeError: this.formatoLog(data), showModal: true })
                    }


                }).catch(error => {
                    this.setState({ colorModal: this.state.colorErrorModal, tituloModal: 'Ocurrio un error!', mensajeError: error.toString(), showModal: true })

                })

            let jsonBancarios =
            {
                "solicitud": idSolicitud,
                "banco": this.state.datosSolicitante.bancaria,
                "clabe": this.state.datosSolicitante.clabeTarjeta,
                "medio":1// this.state.datosSolicitante.medio
            }

            console.log("el json ", json)
            const requestReferidoOptionsBancarios = {
                method: "POST",
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(jsonBancarios)
            }
            fetch(conexiones.urlServicios + "wedo_datos_bancarios/0", requestReferidoOptionsBancarios)
                .then(response => response.json())
                .then(data => {

                    console.log("respuesta del servicio al crear  datos del domicilio  ", data)
                    if (data["id"] != undefined) {
                        this.setDatosBancariosServicio(data)
                        this.setBanderaExistenDatosBancarios(true)
                        this.setState({ idDatoBancarios: data["id"], colorModal: this.state.colorSuccesModal, tituloModal: 'Info guardada', mensajeError: "!Se han guardado tus datos bancarios con exito¡", showModal: true })
                    } else {
                        this.setState({ colorModal: this.state.colorErrorModal, tituloModal: 'Ocurrio un error!', mensajeError: this.formatoLog(data), showModal: true })
                    }


                }).catch(error => {
                    this.setState({ colorModal: this.state.colorErrorModal, tituloModal: 'Ocurrio un error!', mensajeError: error.toString(), showModal: true })

                })




            /*ocupacion  */

            let jsonOcupacion =
            {
                "solicitud":idSolicitud,
                "numero_empleado": this.state.datosSolicitanteOcupacion.numeroEmpleado,
                "ingreso_mensual": null,
                "puesto": this.state.datosSolicitanteOcupacion.puesto,
                "profesion": this.state.datosSolicitanteOcupacion.profesion,
                "cargo_publico": 2,
                "puesto_cp": null,
                "periodo_ejercicio_cp": null,
                "cargo_publico_familiar": 2,
                "nombre_familiar": null,
                "parentesco": null,
                "puesto_cpf":null,
                "periodo_ejercicio_cpf": null,
                "tipo_empleado": 2
            }
            console.log("el json ", json)
            const requestReferidoOptionsOcupacion = {
                method: "POST",
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(jsonOcupacion)
            }
            fetch(conexiones.urlServicios + "wedo_ocupacion/0", requestReferidoOptionsOcupacion)
                .then(response => response.json())
                .then(data => {

                    console.log("respuesta del servicio al crea una solicitud ", data)
                    if (data["id"] != undefined) {
                        this.setDatosOcupacionServicio(data)
                        this.setBanderaExistenDatosOcupacion(true)

                        //   this.setState({ idDatosOcupacion: data["id"], colorModal: this.state.colorSuccesModal, tituloModal: 'Info guardada', mensajeError: "!Se han guardado tus datos de ocupación con exito¡", showModal: true })

                    } else {
                        this.setState({ colorModal: this.state.colorErrorModal, tituloModal: 'Ocurrio un error!', mensajeError: this.formatoLog(data), showModal: true })
                    }


                }).catch(error => {
                    this.setState({ colorModal: this.state.colorErrorModal, tituloModal: 'Ocurrio un error!', mensajeError: error.toString(), showModal: true })

                })


                if (this.state.arrayReferencias.length > 0) {
                    for (let i = 0; i < this.state.arrayReferencias.length; i++) {
                        let referencia = this.state.arrayReferencias[i]
                        let data = await this.creaReferenciaNueva(referencia, (i + 2) + "",idSolicitud)
                    }
                    this.setDatosReferenciasServicio(this.state.arrayReferencias)
                    this.setBanderaExistenDatosReferencias(true)
                }




            console.log("guardarForomulariosConInfoDeZELL ", this.state.datosSolicitanteOcupacion)
            console.log("guardarForomulariosConInfoDeZELL ", this.state.arrayReferencias)
        }
    }


    creaReferenciaNueva = async (refe, numeroReferencia, idSolicitud) => {
        let json =
        {
            "solicitud": idSolicitud,
            "primer_nombre": refe.primerNombre,
            "segundo_nombre": refe.segundoNombre,
            "primer_apellido": refe.primerApellido,
            "segundo_apellido": refe.segundoApellido,
            "telefono": refe.telefono,
            "celular": refe.celular
        }

        console.log("el json ", json)
        const requestReferidoOptions = {
            method: "POST",
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(json)
        }

        try {
            const fetchResponse = await fetch(conexiones.urlServicios + "wedo_referencias/0", requestReferidoOptions)
            const data = await fetchResponse.json();
            return data
        } catch (e) {
            return e
        }



    }

    guardaYGeneraJsonsSecciones(jsonResultadosZell, nuevaSolicitud) {
        let  datosFecha=jsonResultadosZell.fecha_ingreso
        let  datosFechav=datosFecha.split("/")
        let año=datosFechav[2]
        let mes=datosFechav[1]
        let dia=datosFechav[0]
        nuevaSolicitud.fechaIngreso=año+"-"+mes+"-"+dia//jsonResultadosZell.fecha_ingreso
        let separacionFechaNacimientoZell = jsonResultadosZell.BirthDate.split("/")
        let fechaNacimiento = separacionFechaNacimientoZell[2] + "-" + separacionFechaNacimientoZell[0] + "-" + separacionFechaNacimientoZell[1]
        let datosSolicitante = {
            primerNombre: jsonResultadosZell.FirstName,
            segundoNombre: jsonResultadosZell.SecondName,
            primerApellido: jsonResultadosZell.FLastName,
            segundoApellido: jsonResultadosZell.SLastName,
            genero: jsonResultadosZell.OpSex,
            edoCivil: jsonResultadosZell.OpMaritalStatus,
            fecNacimiento: fechaNacimiento,
            paisNaci: jsonResultadosZell.CountryId,
            entidadNaci: jsonResultadosZell.StateId,
            nacionalidad: jsonResultadosZell.OpNationality,
            identificacion: jsonResultadosZell.OpIdType,
            codigoPostal: jsonResultadosZell.ZipAd,
            tipoVivienda: jsonResultadosZell.OpPropTypeAd,
            entidad: jsonResultadosZell.StateIdAd,
            municipio: jsonResultadosZell.TownshipAd,
            ciudad: jsonResultadosZell.CityAd,
            colonia: jsonResultadosZell.NeighborhoodAd,
            calle: jsonResultadosZell.StreetAd,
            exterior: jsonResultadosZell.ExtNumberAd,
            interiror: jsonResultadosZell.IntNumberAd,
            email: jsonResultadosZell.EMail,
            numeroTelefono: jsonResultadosZell.Mobile,
            medio: jsonResultadosZell.OpDisposition,
            clabeTarjeta: jsonResultadosZell.AccountNumber,
            bancaria: jsonResultadosZell.Bank,
            otraColonia: jsonResultadosZell.vFirstName
        }

        let datosSolicitanteOcupacion = {
            tipoEmpleado: "2",
            numeroEmpleado: jsonResultadosZell.Afiliation,
            ingresoMensual: null,
            profesion: jsonResultadosZell.BossJo,
            puesto: jsonResultadosZell.PositionJo,
            cargoPublicoCampo: "No",
            cargoPublicoFamiliarCampo: "No",
            puestoPublico: null,
            periodoEjercicioPublico: null,
            nombreFamiliar: null,
            parentescoFamiliar: null,
            puestoFamiliar: null,
            periodoEjercicioFamiliar: null
        }

        let nombre1 = "FirstNameRe1"
        let sgundoNombre1 = "SecondNameRe1"
        let primerApellido1 = "FLastNameRe1"
        let segundoApellido1 = "SLastNameRe1"
        let telefono1 = "PhoneNumberRe1"

        let nombre2 = "FirstNameRe2"
        let sgundoNombre2 = "SecondNameRe2"
        let primerApellido2 = "FLastNameRe2"
        let segundoApellido2 = "SLastNameRe2"
        let telefono2 = "PhoneNumberRe2"

        let nombre3 = "FirstNameRe3"
        let sgundoNombre3 = "SecondNameRe3"
        let primerApellido3 = "FLastNameRe3"
        let segundoApellido3 = "SLastNameRe3"
        let telefono3 = "PhoneNumberRe3"

        let nombre4 = "FirstNameRe4"
        let sgundoNombre4 = "SecondNameRe4"
        let primerApellido4 = "FLastNameRe4"
        let segundoApellido4 = "SLastNameRe4"
        let telefono4 = "PhoneNumberRe4"


        let arrayEtquetas = []
        arrayEtquetas.push({
            etiquetaNombre: "FirstNameRe1",
            etiquetaSegundoNombre: "SecondNameRe1",
            etiquetaPrimerApellido: "FLastNameRe1",
            etiquetaSegundoAppelido: "SLastNameRe1",
            etiquetaTelefono: "PhoneNumberRe1"
        })

        arrayEtquetas.push({
            etiquetaNombre: "FirstNameRe2",
            etiquetaSegundoNombre: "SecondNameRe2",
            etiquetaPrimerApellido: "FLastNameRe2",
            etiquetaSegundoAppelido: "SLastNameRe2",
            etiquetaTelefono: "PhoneNumberRe2"
        })

        arrayEtquetas.push({
            etiquetaNombre: "FirstNameRe3",
            etiquetaSegundoNombre: "SecondNameRe3",
            etiquetaPrimerApellido: "FLastNameRe3",
            etiquetaSegundoAppelido: "SLastNameRe3",
            etiquetaTelefono: "PhoneNumberRe3"
        })

        arrayEtquetas.push({
            etiquetaNombre: "FirstNameRe4",
            etiquetaSegundoNombre: "SecondNameRe4",
            etiquetaPrimerApellido: "FLastNameRe4",
            etiquetaSegundoAppelido: "SLastNameRe4",
            etiquetaTelefono: "PhoneNumberRe4"
        })

        let arrayReferencias = []
        for (var clave in jsonResultadosZell) {
            if (clave == (nombre1)) {
                console.log("clave encontrada  ", clave, jsonResultadosZell[clave])
                nombre1 = jsonResultadosZell[clave]
            }
            if (clave == (sgundoNombre1)) {
                console.log("clave encontrada  ", clave, jsonResultadosZell[clave])
                sgundoNombre1 = jsonResultadosZell[clave]
            }
            if (clave == (primerApellido1)) {
                console.log("clave encontrada  ", clave, jsonResultadosZell[clave])
                primerApellido1 = jsonResultadosZell[clave]
            }
            if (clave == (segundoApellido1)) {
                console.log("clave encontrada  ", clave, jsonResultadosZell[clave])
                segundoApellido1 = jsonResultadosZell[clave]
            }
            if (clave == (telefono1)) {
                console.log("clave encontrada  ", clave, jsonResultadosZell[clave])
                telefono1 = jsonResultadosZell[clave]
            }

            /////////////

            if (clave == (nombre2)) {
                console.log("clave encontrada  ", clave, jsonResultadosZell[clave])
                nombre2 = jsonResultadosZell[clave]
            }
            if (clave == (sgundoNombre2)) {
                console.log("clave encontrada  ", clave, jsonResultadosZell[clave])
                sgundoNombre2 = jsonResultadosZell[clave]
            }
            if (clave == (primerApellido2)) {
                console.log("clave encontrada  ", clave, jsonResultadosZell[clave])
                primerApellido2 = jsonResultadosZell[clave]

            }
            if (clave == (segundoApellido2)) {
                console.log("clave encontrada  ", clave, jsonResultadosZell[clave])
                segundoApellido2 = jsonResultadosZell[clave]
            }
            if (clave == (telefono2)) {
                console.log("clave encontrada  ", clave, jsonResultadosZell[clave])
                telefono2 = jsonResultadosZell[clave]

            }


            //

            if (clave == (nombre3)) {
                console.log("clave encontrada  ", clave, jsonResultadosZell[clave])
                nombre3 = jsonResultadosZell[clave]

            }
            if (clave == (sgundoNombre3)) {
                console.log("clave encontrada  ", clave, jsonResultadosZell[clave])
                sgundoNombre3 = jsonResultadosZell[clave]

            }
            if (clave == (primerApellido3)) {
                console.log("clave encontrada  ", clave, jsonResultadosZell[clave])
                primerApellido3 = jsonResultadosZell[clave]

            }
            if (clave == (segundoApellido3)) {
                console.log("clave encontrada  ", clave, jsonResultadosZell[clave])
                segundoApellido3 = jsonResultadosZell[clave]
            }
            if (clave == (telefono3)) {
                console.log("clave encontrada  ", clave, jsonResultadosZell[clave])
                telefono3 = jsonResultadosZell[clave]

            }


            if (clave == (nombre4)) {
                console.log("clave encontrada  ", clave, jsonResultadosZell[clave])
                nombre4 = jsonResultadosZell[clave]

            }
            if (clave == (sgundoNombre4)) {
                console.log("clave encontrada  ", clave, jsonResultadosZell[clave])
                sgundoNombre4 = jsonResultadosZell[clave]

            }
            if (clave == (primerApellido4)) {
                console.log("clave encontrada  ", clave, jsonResultadosZell[clave])
                primerApellido4 = jsonResultadosZell[clave]

            }
            if (clave == (segundoApellido4)) {
                console.log("clave encontrada  ", clave, jsonResultadosZell[clave])
                segundoApellido4 = jsonResultadosZell[clave]

            }
            if (clave == (telefono4)) {
                console.log("clave encontrada  ", clave, jsonResultadosZell[clave])
                telefono4 = jsonResultadosZell[clave]

            }
        }

        if (nombre1 != null && (primerApellido1 != null || segundoApellido1 != null)) {
            arrayReferencias.push({
                etiquetaNombre: arrayEtquetas[0].etiquetaNombre,
                etiquetaSegundoNombre: arrayEtquetas[0].etiquetaSegundoNombre,
                etiquetaPrimerApellido: arrayEtquetas[0].etiquetaPrimerApellido,
                etiquetaSegundoAppelido: arrayEtquetas[0].etiquetaSegundoAppelido,
                etiquetaTelefono: arrayEtquetas[0].etiquetaTelefono,

                parentesco_id:0,
                primerNombre: nombre1,
                segundoNombre: sgundoNombre1,
                primerApellido: primerApellido1,
                segundoApellido: segundoApellido1 == "SLastNameRe1" ? "" : segundoApellido1,
                telefono: telefono1,
                celular: null,
                id: null
            })
        }

        if (nombre2 != null && (primerApellido2 != null || segundoApellido2 != null)) {
            arrayReferencias.push({
                etiquetaNombre: arrayEtquetas[1].etiquetaNombre,
                etiquetaSegundoNombre: arrayEtquetas[1].etiquetaSegundoNombre,
                etiquetaPrimerApellido: arrayEtquetas[1].etiquetaPrimerApellido,
                etiquetaSegundoAppelido: arrayEtquetas[1].etiquetaSegundoAppelido,
                etiquetaTelefono: arrayEtquetas[1].etiquetaTelefono,
                parentesco_id:0,
                primerNombre: nombre2,
                segundoNombre: sgundoNombre2,
                primerApellido: primerApellido2,
                segundoApellido: segundoApellido2 == "SLastNameRe2" ? "" : segundoApellido2,
                telefono: telefono2,
                celular: null,
                id: null
            })
        }

        if (nombre3 != null && (primerApellido3 != null || segundoApellido3 != null)) {
            arrayReferencias.push({
                etiquetaNombre: arrayEtquetas[2].etiquetaNombre,
                etiquetaSegundoNombre: arrayEtquetas[2].etiquetaSegundoNombre,
                etiquetaPrimerApellido: arrayEtquetas[2].etiquetaPrimerApellido,
                etiquetaSegundoAppelido: arrayEtquetas[2].etiquetaSegundoAppelido,
                etiquetaTelefono: arrayEtquetas[2].etiquetaTelefono,
                parentesco_id:0,
                primerNombre: nombre3,
                segundoNombre: sgundoNombre3,
                primerApellido: primerApellido3,
                segundoApellido: segundoApellido3,
                telefono: telefono3,
                celular: null,
                id: null
            }) 
        }


        if (nombre4 != null && (primerApellido4 != null || segundoApellido4 != null)) {
            arrayReferencias.push({

                etiquetaNombre: arrayEtquetas[3].etiquetaNombre,
                etiquetaSegundoNombre: arrayEtquetas[3].etiquetaSegundoNombre,
                etiquetaPrimerApellido: arrayEtquetas[3].etiquetaPrimerApellido,
                etiquetaSegundoAppelido: arrayEtquetas[3].etiquetaSegundoAppelido,
                etiquetaTelefono: arrayEtquetas[3].etiquetaTelefono,
                parentesco_id:0,
                primerNombre: nombre4,
                segundoNombre: sgundoNombre4,
                primerApellido: primerApellido4,
                segundoApellido: segundoApellido4,
                telefono: telefono4,
                celular: null,
                id: null
            })
        }// validar si en zell se devuelve el parentesco

        this.setState({
            jsonResultadosZell: jsonResultadosZell, existenResultadosEnZell: true,
            nuevaSolicitud: nuevaSolicitud, datosSolicitante: datosSolicitante,
            datosSolicitanteOcupacion: datosSolicitanteOcupacion, arrayReferencias: arrayReferencias 
        })

    }

    guardaDatosNuevaSolicitud(nuevaSolicitud) {
        this.setState({ nuevaSolicitud: nuevaSolicitud })
    }

    generaJsonsParaNuevaSolicitud() {
        let datosSolicitante = {
            primerNombre: null,
            segundoNombre: null,
            primerApellido: null,
            segundoApellido: null,
            genero: null,
            edoCivil: null,
            fecNacimiento: null,
            paisNaci: null,
            entidadNaci: null,
            nacionalidad: null,
            identificacion: "1",
            codigoPostal: null,
            tipoVivienda: null,
            entidad: null,
            municipio: null,
            ciudad: null,
            colonia: null,
            calle: null,
            exterior: null,
            interiror: null,
            email: null,
            numeroTelefono: null,
            medio: null,
            clabeTarjeta: null,
            bancaria: null,
            otraColonia: null
        }
        let datosSolicitanteOcupacion = {
            tipoEmpleado: "2",
            numeroEmpleado: null,
            ingresoMensual: null,
            profesion: null,
            puesto: null,
            cargoPublicoCampo: "No",
            cargoPublicoFamiliarCampo: "No",
            puestoPublico: null,
            periodoEjercicioPublico: null,
            nombreFamiliar: null,
            parentescoFamiliar: null,

            puestoFamiliar: null,
            periodoEjercicioFamiliar: null
        }

        this.setState({ datosSolicitante: datosSolicitante, datosSolicitanteOcupacion: datosSolicitanteOcupacion })
    }

    setBanderaExistenDatosPersonales(existenDatosPersonales) {
        this.setState({ existenDatosPersonales: existenDatosPersonales })
    }
    setBanderaExistenDatosDomicilio(existenDatosDomicilio) {
        this.setState({ existenDatosDomicilio: existenDatosDomicilio })
    }

    setBanderaExistenDatosContacto(existenDatosContacto) {
        this.setState({ existenDatosContacto: existenDatosContacto })
    }

    setBanderaExistenDatosBancarios(existenDatosBancarios) {
        this.setState({ existenDatosBancarios: existenDatosBancarios })
    }

    setBanderaExistenDatosOcupacion(existenDatosOcupacion) {
        console.log("datos copacion si hay ")
        this.setState({ existenDatosOcupacion: existenDatosOcupacion })
    }


    setBanderaExistenDatosReferencias(existenDatosReferencias) {
        this.setState({ existenDatosReferencias: existenDatosReferencias })
    }



    resetFormularios() {
        console.log("entrando a reset forma")
        this.setState({
            componenteActivo: 'xx',
            idSolicitud: null,
            compraDeuda: false,
            datosSolicitanteOcupacion: {
                tipoEmpleado: "2",
                numeroEmpleado: null,
                ingresoMensual: null,
                profesion: null,
                puesto: null,
                cargoPublicoCampo: "No",
                cargoPublicoFamiliarCampo: "No",
                puestoPublico: null,
                periodoEjercicioPublico: null,
                nombreFamiliar: null,
                parentescoFamiliar: null,

                puestoFamiliar: null,
                periodoEjercicioFamiliar: null
            },
            datosSolicitante: {
                primerNombre: null,
                segundoNombre: null,
                primerApellido: null,
                segundoApellido: null,
                genero: null,
                edoCivil: null,
                fecNacimiento: null,
                paisNaci: null,
                entidadNaci: null,
                nacionalidad: null,
                identificacion: "1",
                codigoPostal: null,
                tipoVivienda: null,
                entidad: null,
                municipio: null,
                ciudad: null,
                colonia: null,
                calle: null,
                exterior: null,
                interiror: null,
                email: null,
                numeroTelefono: null,
                medio: null,
                clabeTarjeta: null,
                bancaria: null,
                otraColonia: null
            },
            arrayReferencias: [],
            nuevaSolicitud: {
                curp: null,
                rfc: null,
                sector: null,
                folio_imss:null,
                producto: null,
                fechaIngreso: null,
                actuaPor: "1",
                tipoProducto: null,
                periodo: null,
                plazo: null,
                capPago: null,
                destinoCredito: null,
                montoSolicitado: null,
                descuento: null,
                tasaAnual: null,
                sucursal: null,
                montoDeudorSol: null,
                depositoAlClienteSol: null
            },
            jsonResultadosZell: {},
            existenResultadosEnZell: false,
            existenDatosPersonales: false,
            existenDatosDomicilio:false,
            existenDatosContacto:false,
            existenDatosBancarios:false,
            existenDatosOcupacion:false,
            existenDatosReferencias:false

        })

        setTimeout(() => {
            this.setState({ componenteActivo: "solicitud" })
        }, 1000)
    }

    modificaBanderaIMSS(bandera){
        console.log("enteando al imss ",bandera)
        this.setState({activar_IMSS:bandera})
    }



    guardarForomulariosConInfoDeZELL() {
        //console.log("guardarForomulariosConInfoDeZELL ",this.state.nuevaSolicitud)

    }

    async getReferenciasDesdeContenedor(idSolicitud) {
      let salida=[]
        try {
            const response = await fetch(conexiones.urlServicios + "wedo_referencias/" + idSolicitud);
            if (response.ok) {
                const json = await response.json();
                console.log("referencias ", json)
                if (json.length > 0) {
                    //  this.armaReferencia1(json[0])
                    this.setDatosReferenciasServicio(json)
                    this.setBanderaExistenDatosReferencias(true)
                    salida= json
                }
            }
        } catch (error) {
            
        }

        return salida
    }

    regresarConsultaSol(){
        this.props.regresarABandeja() 
    }


    render() {
        return (
            <div className="page-content" style={{ backgroundColor: 'white', marginTop: '25px', height: '100%', width: '100%' }}>

                <div className="content-wrapper">
                    <Row>
                    <div style={{ textAlign:"right" }}>
                    
                           <img src={botonBasura}style={{  maxWidth: '70px',height:'50px', paddingRight:"8px" }} title="Limpiar Solicitud" onClick={() => { this.resetFormularios() }}  />
                    </div>
                        
                        
                    </Row>


                    <div className="content-inner" style={{ margin: '10px' }}>

                        <CreaNuevaSolicitudPestana compraDeuda={this.state.compraDeuda} idUsuario={this.props.idUsuario} activar_IMSS={this.state.activar_IMSS} resetFormularios={this.resetFormularios} componenteActivo={this.state.componenteActivo} clickAvanzar={this.clickAvanzar} pantalla={"nuevaSolicitud"} />
                        <br></br>
                        {
                            this.props.regresarABandeja != undefined ?
                                <>

                                    <Row>
                                    <Col lg={8} md={8} xs={1}>&nbsp;</Col>
                                    {/*
                                    <Col lg={2} md={2} xs={6}>
                                        <span style={{font:"bold",color:"#FF4000"}}>Sol: {this.state.idSolicitud}<br/>DXN:{this.state.nuevaSolicitudServicio != null ? this.state.nuevaSolicitudServicio.appid:""} </span>
                                    </Col>*/
                                    }
                                    <Col lg={2} md={2} xs={6}>
                                    <div style={{ textAlign:"right" }}>
                                            <img src={botonListadoSol}  style={{ maxWidth: '90%',height:'60px' }} onClick={() => { this.props.regresarABandeja() }}/>
                                    </div>
                                    </Col>
                                    </Row>

                                    <br></br>
                                </>
                                : ''
                        }

                        {
                            this.state.componenteActivo == "solicitud" ?
                                <CreaNuevaSolicitudForm idPuesto={this.props.idPuesto} idUsuario={this.props.idUsuario} guardaYGeneraJsonsSecciones={this.guardaYGeneraJsonsSecciones} 
                                    modificaBanderaIMSS={this.modificaBanderaIMSS}
                                    modificaCompraDeuda={this.modificaCompraDeuda}
                                    idSucursal={this.props.idSucursal}
                                    clickAvanzar={this.clickAvanzar}
                                    guardaIdSolicitud={this.guardaIdSolicitud} nuevaSolicitud={this.state.nuevaSolicitud}
                                    idSolicitud={this.state.idSolicitud} guardaDatosNuevaSolicitud={this.guardaDatosNuevaSolicitud} 
                                    setNuevaSolicitudServicio={this.setNuevaSolicitudServicio}
                                    setDatosPersonalesServicio={this.setDatosPersonalesServicio}
                                    setDatosDomicilioServicio={this.setDatosDomicilioServicio}
                                    setDatosContactoServicio={this.setDatosContactoServicio}
                                    setDatosBancariosServicio={this.setDatosBancariosServicio}
                                    setDatosOcupacionServicio={this.setDatosOcupacionServicio}
                                    setDatosReferenciasServicio={this.setDatosReferenciasServicio}
                                    generaJsonsParaNuevaSolicitud={this.generaJsonsParaNuevaSolicitud}
                                    setBanderaExistenDatosPersonales={this.setBanderaExistenDatosPersonales}
                                    setBanderaExistenDatosDomicilio={this.setBanderaExistenDatosDomicilio}
                                    setBanderaExistenDatosContacto={this.setBanderaExistenDatosContacto}
                                    setBanderaExistenDatosBancarios={this.setBanderaExistenDatosBancarios}
                                    setBanderaExistenDatosOcupacion={this.setBanderaExistenDatosOcupacion}
                                    setBanderaExistenDatosReferencias={this.setBanderaExistenDatosReferencias}
                                    existenResultadosEnZell={this.state.existenResultadosEnZell}
                                    guardarForomulariosConInfoDeZELL={this.guardarForomulariosConInfoDeZELL}
                                    banderaSolicitud={this.props.banderaSolicitud}
                                    banderaDocumentos={this.props.banderaDocumentos}
                                
                                />
                                : ''
                        }
                        {
                            this.state.componenteActivo == "datosSol" ?
                                <DatosSolicitante  
                                existenDatosBancarios={this.state.existenDatosBancarios}
                                existenDatosContacto={this.state.existenDatosContacto}
                                existenDatosDomicilio={this.state.existenDatosDomicilio} 
                                existenDatosPersonales={this.state.existenDatosPersonales}
                                clickAvanzar={this.clickAvanzar} idSolicitud={this.state.idSolicitud} 
                                datosSolicitante={this.state.datosSolicitante} 
                                nuevaSolicitud={this.state.nuevaSolicitud}
                                setBanderaExistenDatosPersonales={this.setBanderaExistenDatosPersonales}
                                setBanderaExistenDatosDomicilio={this.setBanderaExistenDatosDomicilio}
                                setBanderaExistenDatosContacto={this.setBanderaExistenDatosContacto}
                                setBanderaExistenDatosBancarios={this.setBanderaExistenDatosBancarios}
                                setDatosPersonalesServicio={this.setDatosPersonalesServicio}
                                setDatosDomicilioServicio={this.setDatosDomicilioServicio}
                                setDatosContactoServicio={this.setDatosContactoServicio}
                                setDatosBancariosServicio={this.setDatosBancariosServicio}
                                banderaSolicitud={this.props.banderaSolicitud}
                                banderaDocumentos={this.props.banderaDocumentos}
                                />
                                : ''
                        }
                        {
                            this.state.componenteActivo == "ocupacion" ?
                                <OcupacionSolicitanteForm 
                                existenDatosOcupacion={this.state.existenDatosOcupacion}
                                setBanderaExistenDatosOcupacion={this.setBanderaExistenDatosOcupacion}
                                setDatosOcupacionServicio={this.setDatosOcupacionServicio}
                                clickAvanzar={this.clickAvanzar} 
                                idSolicitud={this.state.idSolicitud} datosSolicitanteOcupacion={this.state.datosSolicitanteOcupacion}
                                banderaSolicitud={this.props.banderaSolicitud}
                                banderaDocumentos={this.props.banderaDocumentos}
                                />
                                : ''
                        }
                        {
                            this.state.componenteActivo == "referencias" ?
                                <ReferenciasSolicitanteForm 
                                existenDatosReferencias={this.state.existenDatosReferencias}
                                setBanderaExistenDatosReferencias={this.setBanderaExistenDatosReferencias}
                                setDatosReferenciasServicio={this.setDatosReferenciasServicio}
                                clickAvanzar={this.clickAvanzar} 
                                compraDeuda={this.state.compraDeuda} 
                                idSolicitud={this.state.idSolicitud} arrayReferencias={this.state.arrayReferencias} 
                                banderaSolicitud={this.props.banderaSolicitud}
                                banderaDocumentos={this.props.banderaDocumentos}
                                />
                                : ''
                        }


                        {
                            this.state.componenteActivo == "compraDeuda" ?
                                <CompraDeudaForm clickAvanzar={this.clickAvanzar} idSolicitud={this.state.idSolicitud} banderaSolicitud={this.props.banderaSolicitud}
                                banderaDocumentos={this.props.banderaDocumentos}   nuevaSolicitudServicio={this.state.nuevaSolicitudServicio}/>
                                : ''
                        }

                        {     
                            this.state.componenteActivo == "documentos" ?
                                <DocumentosSolicitanteForm clickAvanzar={this.clickAvanzar} 
                                banderaSolicitud={this.props.banderaSolicitud}
                                    banderaDocumentos={this.props.banderaDocumentos}
                                    idSolicitud={this.state.idSolicitud}
                                    existenDatosBancarios={this.state.existenDatosBancarios}
                                    existenDatosContacto={this.state.existenDatosContacto}
                                    existenDatosDomicilio={this.state.existenDatosDomicilio} 
                                    existenDatosPersonales={this.state.existenDatosPersonales}
                                    existenDatosOcupacion={this.state.existenDatosOcupacion}
                                    existenDatosReferencias={this.state.existenDatosReferencias}
                                    nuevaSolicitudServicio={this.state.nuevaSolicitudServicio}
                                    
                                    datosPersonalesServicio={this.state.datosPersonalesServicio}
                                    datosDomicilioServicio={this.state.datosDomicilioServicio}
                                    datosContactoServicio={this.state.datosContactoServicio}
                                    datosBancariosServicio={this.state.datosBancariosServicio}
                                    datosOcupacionServicio={this.state.datosOcupacionServicio}
                                    datosReferenciasServicio={this.state.datosReferenciasServicio}
                                    activar_IMSS={this.state.activar_IMSS} 
                                    regresarABandeja={this.regresarConsultaSol}

                                    nuevaSolicitud={this.state.nuevaSolicitud}
                                    datosSolicitante={this.state.datosSolicitante}
                                    datosSolicitanteOcupacion={this.state.datosSolicitanteOcupacion}
                                    arrayReferencias={this.state.arrayReferencias}
                                    jsonResultadosZell={this.state.jsonResultadosZell}
                                    getReferenciasDesdeContenedor={this.getReferenciasDesdeContenedor}
                                    esMesaControl={this.props.esMesaControl}
                                    urlINE={this.props.urlINE}
                                />
                                : ''
                        }

                        
                        {
                           /* this.state.componenteActivo == "videoIMSS" ?
                                <VideoIMSS
                                idSolicitud={this.state.idSolicitud}
                                    existenDatosBancarios={this.state.existenDatosBancarios}
                                    existenDatosContacto={this.state.existenDatosContacto}
                                    existenDatosDomicilio={this.state.existenDatosDomicilio} 
                                    existenDatosPersonales={this.state.existenDatosPersonales}
                                    existenDatosOcupacion={this.state.existenDatosOcupacion}
                                    existenDatosReferencias={this.state.existenDatosReferencias}
                                    nuevaSolicitudServicio={this.state.nuevaSolicitudServicio}
                                    
                                    datosPersonalesServicio={this.state.datosPersonalesServicio}
                                    datosDomicilioServicio={this.state.datosDomicilioServicio}
                                    datosContactoServicio={this.state.datosContactoServicio}
                                    datosBancariosServicio={this.state.datosBancariosServicio}
                                    datosOcupacionServicio={this.state.datosOcupacionServicio}
                                    datosReferenciasServicio={this.state.datosReferenciasServicio}

                                    nuevaSolicitud={this.state.nuevaSolicitud}
                                    datosSolicitante={this.state.datosSolicitante}
                                    datosSolicitanteOcupacion={this.state.datosSolicitanteOcupacion}
                                    arrayReferencias={this.state.arrayReferencias}
                                    jsonResultadosZell={this.state.jsonResultadosZell}
                                />
                                : ''*/
                        }

                        {
                            this.state.componenteActivo == "videoIMSSioos" /*&& this.props.idUsuario== 323*/ ?
                                <VideoIMSSios
                                clickAvanzar={this.clickAvanzar} 
                                idSolicitud={this.state.idSolicitud}
                                    existenDatosBancarios={this.state.existenDatosBancarios}
                                    existenDatosContacto={this.state.existenDatosContacto}
                                    existenDatosDomicilio={this.state.existenDatosDomicilio} 
                                    existenDatosPersonales={this.state.existenDatosPersonales}
                                    existenDatosOcupacion={this.state.existenDatosOcupacion}
                                    existenDatosReferencias={this.state.existenDatosReferencias}
                                    nuevaSolicitudServicio={this.state.nuevaSolicitudServicio}
                                    
                                    datosPersonalesServicio={this.state.datosPersonalesServicio}
                                    datosDomicilioServicio={this.state.datosDomicilioServicio}
                                    datosContactoServicio={this.state.datosContactoServicio}
                                    datosBancariosServicio={this.state.datosBancariosServicio}
                                    datosOcupacionServicio={this.state.datosOcupacionServicio}
                                    datosReferenciasServicio={this.state.datosReferenciasServicio}

                                    nuevaSolicitud={this.state.nuevaSolicitud}
                                    datosSolicitante={this.state.datosSolicitante}
                                    datosSolicitanteOcupacion={this.state.datosSolicitanteOcupacion}
                                    arrayReferencias={this.state.arrayReferencias}
                                    jsonResultadosZell={this.state.jsonResultadosZell}
                                />
                                : ''
                        }
                        {
                           /* this.state.componenteActivo == "EscaneoIne" && this.props.idUsuario== 323 ?
                            <EscaneoIne/>:""*/
                        }

                    </div>
                </div>
            </div>
        )
    }
}

