import React, { Component } from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import Accordion from 'react-bootstrap/Accordion';
import Table from 'react-bootstrap/Table';
import VisibilityIcon from '@mui/icons-material/Visibility';
import conexiones from '../urls/conexiones.json'

export default class Guias extends Component {

    constructor() {
        super();
        this.state = {
            datosEducacion: [],
            datosGobierno: [],
            datosSalud: []
        }
    }


    UNSAFE_componentWillMount() {
        this.getDatosGuias(15,"datosEducacion")
        this.getDatosGuias(14,"datosGobierno")
        this.getDatosGuias(13,"datosSalud")
    }

    async getDatosGuias(idSector,estado) {
        try {
            const response = await fetch(conexiones.urlServicios + "wedo_guias/" + idSector);
            if (response.ok) {
                const json = await response.json();
                let filas = []
                for (var i = 0; i < json.length; i++) {
                    filas.push(<tr>
                        <td>{json[i].id}</td>
                        <td>{json[i].producto_desc}</td>
                        <td>{json[i].sucursal_desc}</td>
                        <td>{json[i].ultima_modificacion}</td>
                        <td><a target="_blank"
                            style={{ "color": "#FF6B00", "textDecoration": "none", "fontWeight": "bold", "transition": "color 0.3s" }}

                            href={json[i].documento_ruta.length > 0 ? json[i].documento_ruta:null}
                            class="enlace-personalizado"><VisibilityIcon /></a></td>
                    </tr>)
                }

                let salida =
                    <Table striped bordered hover>
                        <thead>
                            <tr>
                                <th>NO.</th>
                                <th>Nombre</th>
                                <th>Sucursal</th>
                                <th>Ultima modificación</th>
                                <th>Opciones</th>
                            </tr>
                        </thead>
                        <tbody>
                            {filas}
                        </tbody>
                    </Table>

                this.setState({ [estado]: salida })
            }
        } catch (error) {

        }
    }


    render() {
        return (
            <div style={{ marginTop: "20px" }} className="container-fluid">
                <div className="row">
                    <div className="col-lg-12 col-md-12 col-xs-12">
                        <Accordion  defaultActiveKey={0}>
                            <Accordion.Item  eventKey={0} >
                                <Accordion.Header>Educación</Accordion.Header>
                                <Accordion.Body>
                                    {this.state.datosEducacion}
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey={1} >
                                <Accordion.Header>Gobierno</Accordion.Header>
                                <Accordion.Body>
                                    {this.state.datosGobierno}
                                </Accordion.Body>
                            </Accordion.Item>

                            <Accordion.Item eventKey={2}>
                                <Accordion.Header>Salud</Accordion.Header>
                                <Accordion.Body>
                                    {this.state.datosSalud}
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>
                    </div>
                </div>
            </div>
        )
    }


}